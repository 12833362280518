<template>
  <div class="mx-12 my-6">
    <div id="title-pre-inscriptions" class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
      Turmas
    </div>
    <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
    <div class="grid grid-cols-4 justify-between items-end gap-4 mt-2">
      <div>
        <Multiselect
          id="filterArea"
          selectPlaceholder="Selecionar"
          label-text="filtrar por área"
          class="mr-5"
          closeOnSelect
          show-label
          :multipleOption="false"
          :options="user_areas"
          :value="areas_equipment"
          :dispatch="setArea"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="code-team"
          show-label
          label-text="Filtrar pelo código"
          input-placeholder="Pesquisar pelo código"
          input-type="text"
          dispatch="Teams/filterByTeamCode"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="equipment-team"
          show-label
          label-text="Filtrar pelo equipamento"
          input-placeholder="Pesquisar pelo equipamento"
          input-type="text"
          dispatch="Teams/filterByTeamEquipment"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="course-team"
          show-label
          label-text="Filtrar pelo curso"
          input-placeholder="Pesquisar pelo curso"
          input-type="text"
          dispatch="Teams/filterByTeamCourse"
        />
      </div>
      <div>
        <Multiselect
          close-on-select
          show-label
          select-placeholder="Filtrar pelo Instrutor(a)"
          label-text="Instrutor"
          :multiple-option="false"
          :value="teacher"
          :options="teachers"
          :dispatch="setTeacher"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="birth-year-relative"
          show-label
          label-text="Data de Início"
          input-type="date"
          :value="start"
          dispatch="Teams/filterTeamsByPeriodStart"
        />
      </div>
      <div>
        <BaseInput
          active
          input-name="birth-year-relative"
          show-label
          label-text="Data de Fim"
          input-type="date"
          :value="closing"
          dispatch="Teams/filterTeamsByPeriodClosing"
        />
      </div>
    </div>
    <div class="grid grid-rols-3 my-5">
      <div class="mt-5">
        <TeamsCards :data="teams" />
      </div>
      <div id="team-pagination" class="mx-12 my-6 pb-3" v-if="teams.length > 0">
        <BasePagination :total="totalTeams" :total-per-page="totalPerPage" dispatch="Teams/setOffset" />
      </div>
    </div>

    <ModalTeam :open="modalTeamOpen" />
  </div>
</template>

<script>
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import TeamsCards from "./list/TeamsCards.vue";
import ModalTeam from "./ModalTeam.vue";
import BasePagination from "../components/BasePagination.vue";
import { mapState, mapActions } from "vuex";

export default {
  name: "teams",
  components: {
    BaseInput,
    Multiselect,
    BasePagination,
    TeamsCards,
    ModalTeam,
  },
  data: () => ({
    type_request: null,
    url: window.location.href,
  }),
  created() {
    this.$store.dispatch("Seasons/loadSeasons");
    this.$store.dispatch("Areas/loadAreas");
    // this.$store.dispatch("Teams/clearFilter");

    let type_request = null;
    type_request = !this.url.includes("/turmas/finalizadas");
    this.$store.dispatch("Teams/filterByInProgress", type_request);
    this.$store.dispatch("Teams/loadTeams");
  },
  computed: {
    ...mapState({
      filteredSeason: (state) => state.Teams.filter.season,
      teams: (state) => state.Teams.all,
      filteredPhase: (state) => state.Teams.filter.phase,
      filteredDiscipline: (state) => state.Teams.filter.discipline,
      modalTeamOpen: (state) => state.Team.modalTeamOpen,
      seasons: (state) => state.Seasons.seasons,
      phase: (state) => state.Season.phase,
      offset: (state) => state.Teams.offset,
      totalPerPage: (state) => state.Teams.totalPerPage,
      totalTeams: (state) => state.Teams.teamsTotal,
      city: (state) => state.City.city,
      areas: (state) => state.Areas.areas,
      areas_equipment: (state) => state.Teams.filter.areas_equipment,
      start: (state) => state.Teams.filter.start,
      closing: (state) => state.Teams.filter.closing,
      code: (state) => state.Teams.filter.code,
      equipment: (state) => state.Teams.filter.equipment,
      teachers: (state) => state.Team.teachers,
      course: (state) => state.Teams.filter.course,
      teacher: (state) => state.Teams.filter.teacher,
      user_areas: (state) => (state.user_areas.areas ? state.user_areas.areas : null),
    }),
  },

  methods: {
    getRole() {
      const role = this.$store.state.Session.role.name;
      return role;
    },
    ...mapActions({
      clearFilter: "Teams/clearFilter",
      filterSeason: "Teams/filterTeamsBySeason",
      filterPhase: "Teams/filterTeamsByPhase",
      filterArea: "Teams/filterTeamsByArea",
      filterDiscipline: "Teams/filterTeamsByDiscipline",
      setArea: "Teams/filterTeamsByArea",
      setTeacher: "Teams/filterByTeamTeacher",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Teams/loadTeams");
      this.backToTop();
    },
    $route(to) {
      const type_request = to.name === "teams_in_progress" ? true : false;
      this.$store.dispatch("Teams/filterByInProgress", type_request);
      this.$store.dispatch("Teams/loadTeams");
    },
    areas_equipment: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    code: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    equipment: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    course: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    teacher: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    start: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    closing: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
  },
};
</script>
