<template>
  <div v-if="open && season">
    <div id="tabs-button" class="mt-3 ml-2">
      <FilterComponent>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Filtrar por Status"
            clear-on-select
            select-placeholder="Status"
            :multiple-option="false"
            :value="status_student_filtered"
            :options="status_student"
            :dispatch="setFilterStatusNotReinsertedMainPage"
          />
        </div>
        <div class="col-span-3">
          <Multiselect
            :close-on-select="true"
            label-text="Filtrar por Nome Civil ou Social do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptionsForGeneralMenus"
            :dispatch="setFilterInscriptionNotReinsertedMainPage"
            load-dispatch="SchoolReinsertions/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCityNotReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Selecionar Área"
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_school_reinsertions"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipmentNotReinsertedMainPage"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="articulator"
            input-type="text"
            :value="articulator_filtered"
            show-label
            label-text="Articulador(a) Responsável"
            dispatch="SchoolReinsertions/setFilterArticulatorNotReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Encaminhamentos Institucionais"
            show-label
            clear-on-select
            select-placeholder="Encaminhamentos Institucionais"
            :multiple-option="false"
            :value="institutional_referral_filtered"
            :options="institutional_referrals"
            :dispatch="setFilterInstitutionalReferralNotReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Status dos Acompanhamentos"
            show-label
            clear-on-select
            select-placeholder="Status dos Acompanhamentos"
            :multiple-option="false"
            :value="accompaniment_status_filtered"
            :options="accompaniment_statuses"
            :dispatch="setFilterAccompanimentStatusNotReinsertedMainPage"
          />
        </div>
      </FilterComponent>
      <div class="col-span-2 mt-3 mb-2" v-if="permitExportInscriptionSpreadsheet">
        <a href="javascript:void(0)" @click="exportToExcel('inscriptions')">
          <img
            class="inline-block"
            src="../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha de Não Reinseridos"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
        <BaseModal v-if="modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet">
          <template v-slot:header>
            <div class="grid grid-cols-3">
              <div class="justify-self-start col-span-2">
                <Label
                  text="Exportar Planilha de Não Reinseridos"
                  :icon="null"
                  :icon-type="null"
                  type="text-2xl"
                  weight="bold"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="justify-self-end">
                <AppButtonIcon
                  dispatch="SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal"
                  forClose
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="grid grid-rows-2">
              <div
                class="grid justify-center items-center"
                v-if="school_reinsertions_inscriptions_spreadsheet.length > 0"
              >
                <Label
                  text="Planilha gerada com sucesso!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid justify-center items-center" v-else>
                <Label
                  text="Nenhum registro encontrado!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid grid-cols-2" v-if="school_reinsertions_inscriptions_spreadsheet.length > 0">
                <div class="justify-self-start">
                  <AppButtonEmpty
                    text="descartar"
                    typeButton="danger"
                    dispatch="SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal"
                  />
                </div>

                <div class="justify-self-end">
                  <download-excel
                    type="xls"
                    :meta="json_meta"
                    :fields="json_fields_inscriptions"
                    :data="json_data_school_reinsertion_inscriptions"
                    :before-finish="closeExportSchoolReinsertionsInscriptionSpreadsheetModal"
                    worksheet="Inscritos"
                    name="inscritos_nao_reinseridos.xls"
                    :escapeCsv="false"
                  >
                    <AppButtonEmpty text="Download da planilha" typeButton="success" />
                  </download-excel>
                </div>
              </div>
            </div>
          </template>
        </BaseModal>
        <div v-if="!hasSpreadsheetSchoolReinsertionsInscriptionsResponse">
          <PreLoading />
        </div>
        <a
          href="javascript:void(0)"
          v-if="permitExportSchoolReinsertionSpreadsheet"
          @click="exportToExcel('school_reinsertions')"
        >
          <img
            class="inline-block ml-4"
            src="../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha de Atividades"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
        <BaseModal v-if="modalToggleExportSchoolReinsertionsEvolutionSpreadsheet">
          <template v-slot:header>
            <div class="grid grid-cols-3">
              <div class="justify-self-start col-span-2">
                <Label
                  text="Exportar Planilha de Atividades"
                  :icon="null"
                  :icon-type="null"
                  type="text-2xl"
                  weight="bold"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="justify-self-end">
                <AppButtonIcon
                  dispatch="SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                  forClose
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="grid grid-rows-2">
              <div
                class="grid justify-center items-center"
                v-if="school_reinsertions_evolution_spreadsheet.length > 0"
              >
                <Label
                  text="Planilha gerada com sucesso!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid justify-center items-center" v-else>
                <Label
                  text="Nenhum registro encontrado!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid grid-cols-2" v-if="school_reinsertions_evolution_spreadsheet.length > 0">
                <div class="justify-self-start">
                  <AppButtonEmpty
                    text="descartar"
                    typeButton="danger"
                    dispatch="SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                  />
                </div>

                <div class="justify-self-end">
                  <download-excel
                    type="xls"
                    :meta="json_meta"
                    :fields="json_fields"
                    :data="json_data_school_reinsertion_evolution"
                    :before-finish="closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                    worksheet="Atividades"
                    name="atividades_nao_reinseridos.xls"
                    :escapeCsv="false"
                  >
                    <AppButtonEmpty text="Download da planilha" typeButton="success" />
                  </download-excel>
                </div>
              </div>
            </div>
          </template>
        </BaseModal>
        <div v-if="!hasSpreadsheetSchoolReinsertionsEvolutionResponse">
          <PreLoading />
        </div>
        <a
          href="javascript:void(0)"
          v-if="permitExportVacationRequestSpreadsheet"
          @click="exportToExcel('vacancy_requests')"
        >
          <img
            class="inline-block ml-4"
            src="../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha de Solicitações de Vagas"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
        <BaseModal v-if="modalToggleExportVacancyRequestsSpreadsheet">
          <template v-slot:header>
            <div class="grid grid-cols-3">
              <div class="justify-self-start col-span-2">
                <Label
                  text="Exportar Planilha de Solicitações de Vagas"
                  :icon="null"
                  :icon-type="null"
                  type="text-2xl"
                  weight="bold"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="justify-self-end">
                <AppButtonIcon
                  dispatch="VacancyRequests/closeExportVacancyRequestsSpreadsheetModal"
                  forClose
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="grid grid-rows-2">
              <div class="grid justify-center items-center" v-if="vacancy_requests_spreadsheet.length > 0">
                <Label
                  text="Planilha gerada com sucesso!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid justify-center items-center" v-else>
                <Label
                  text="Nenhum registro encontrado!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid grid-cols-2">
                <div class="justify-self-start" v-if="vacancy_requests_spreadsheet.length > 0">
                  <AppButtonEmpty
                    text="descartar"
                    typeButton="danger"
                    dispatch="VacancyRequests/closeExportVacancyRequestsSpreadsheetModal"
                  />
                </div>

                <div class="justify-self-end" v-if="vacancy_requests_spreadsheet.length > 0">
                  <download-excel
                    type="xls"
                    :meta="json_meta"
                    :fields="json_fields_vacancy_requests"
                    :data="json_data_vacancy_request"
                    :before-finish="closeExportVacancyRequestsSpreadsheetModal"
                    worksheet="Solicitacoes_vagas"
                    name="solicitacoes_vagas.xls"
                    :escapeCsv="false"
                  >
                    <AppButtonEmpty text="Download da planilha" typeButton="success" />
                  </download-excel>
                </div>
              </div>
            </div>
          </template>
        </BaseModal>
        <div v-if="!hasSpreadsheetVacancyRequestsResponse">
          <PreLoading />
        </div>
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-inscriptions"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="SchoolReinsertions/loadInscriptions"
          :payload="payload_load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocials">
      <div class="mx-12">
        <SchoolReinsertionNotReinsertedsCards :data="inscriptions" />
      </div>
      <div id="school-reinsertion-pagination" class="mx-12 my-6 pb-3" v-if="inscriptions.length > 0">
        <BasePagination
          :total="inscriptionsTotal"
          :total-per-page="totalPerPage"
          dispatch="SchoolReinsertions/setOffsetNotReinserteds"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SchoolReinsertionNotReinsertedsCards from "../../list/SchoolReinsertionNotReinsertedsCards.vue";
import BasePagination from "../../../components/BasePagination.vue";
import AppButtonColorful from "../../../components/AppButtonColorful.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Multiselect from "../../../components/forms/Multiselect.vue";
import BaseText from "../../../components/BaseText.vue";
import BaseModal from "../../../components/BaseModal.vue";
import Label from "../../../components/Label.vue";
import PreLoading from "../../../components/PreLoading.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    AppButtonColorful,
    SchoolReinsertionNotReinsertedsCards,
    Multiselect,
    BaseInput,
    BaseText,
    BaseModal,
    Label,
    PreLoading,
    AppButtonEmpty,
    AppButtonIcon,
  },
  data() {
    return {
      currentPage: 1,
      status_student: [
        {
          id: 0,
          name: "Desligado(a)",
        },
        {
          id: 3,
          name: "Ativo(a)",
        },
        {
          id: 5,
          name: "Concludente",
        },
      ],
      payload_load: {
        type: "not_reinserteds",
        data: "load_data",
      },
      permitExportInscriptionSpreadsheet: false,
      permitExportVacationRequestSpreadsheet: false,
      permitExportSchoolReinsertionSpreadsheet: false,
      json_fields: {
        "Nome Social": "student_social_name",
        Nome: "student_name",
        "Tipo da Atividade": "activity_type",
        "Tipo do Resultado": "result_type",
        "Data da Atividade": "reinsertion_date",
        "Data de Entrega da Carta": "letter_delivery_date",
        "Articulador(a) Responsável": "articulator",
        "Razão para Não Reinserção": "reasons_for_non_reinsertion",
        Encaminhamentos: "forwardings",
        Observações: "observations",
        Escola: "facility",
        Turno: "shift",
        "Ano/Série": "serie",
        "Modalidade de Ensino": "teaching_modality",
        "Data da Matrícula": "enrollment_date",
      },
      json_fields_inscriptions: {
        Status: "status",
        Área: "area",
        Equipamento: "equipment",
        "Última presença": "last_presence",
        "Nome Social": "student_social_name",
        Nome: "student_name",
        "Última série concluída": "completed_series",
        "Última escola em que estudou": "last_school_studied",
        "Ano da última matrícula": "year_of_last_enrollment",
        "Data da Matrícula": "enrollment_date",
        "Nome da Escola": "facility",
        "Modalidade de Ensino": "teaching_modality",
      },
      json_fields_vacancy_requests: {
        "Nome do Jovem": "student_name",
        "Tipo da Vaga Solicitada": "vacancy_type",
        "Solicitação já foi atendida?": "accepted_request",
        "Articulador(a) Responsável": "articulator",
        "CPF da Mãe ou Responsável do Jovem": "cpf_responsible_young_person",
        "Endereço da Mãe ou Responsável do Jovem": "address_responsible_young_person",
        "Contato Atualizado do Jovem": "updated_contact_young_person",
        "Escola de Interesse": "interest_school",
        "Turno de Interesse": "interest_shift",
        "Série de Interesse": "interest_serie",
        "Rede a que pertence a Escola": "school_network",
        "Descrição do Caso": "case_description",
        "Intervenções já Realizadas": "interventions_performed",
        "Tipo do Encaminhamento": "forwarding_type",
        "Nome Completo do Responsável pela Criança": "full_name_responsible_child",
        "Nome Completo da Criança": "child_full_name",
        "CPF da Mãe ou Responsável pela Criança": "cpf_responsible_child",
        "Data de Nascimento da Criança": "child_birthdate",
        "Endereço do Responsável pela Criança": "address_responsible_child",
        "Contato do Responsável pela Criança": "contact_responsible_child",
        "Creche de Interesse que já foi articulada": "interest_kindergarten",
        Devolutiva: "feedback",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setUserAreasFiltered: "SchoolReinsertions/setUserAreasFiltered",
      setFilterStateNotReinsertedMainPage: "SchoolReinsertions/setFilterStateNotReinsertedMainPage",
      setFilterCityNotReinsertedMainPage: "SchoolReinsertions/setFilterCityNotReinsertedMainPage",
      setFilterStatusNotReinsertedMainPage: "SchoolReinsertions/setFilterStatusNotReinsertedMainPage",
      setFilterInscriptionNotReinsertedMainPage:
        "SchoolReinsertions/setFilterInscriptionNotReinsertedMainPage",
      setFilterEquipmentNotReinsertedMainPage: "SchoolReinsertions/setFilterEquipmentNotReinsertedMainPage",
      setFilterArticulatorNotReinsertedMainPage:
        "SchoolReinsertions/setFilterArticulatorNotReinsertedMainPage",
      setFilterInstitutionalReferralNotReinsertedMainPage:
        "SchoolReinsertions/setFilterInstitutionalReferralNotReinsertedMainPage",
      setFilterAccompanimentStatusNotReinsertedMainPage:
        "SchoolReinsertions/setFilterAccompanimentStatusNotReinsertedMainPage",
      closeExportSchoolReinsertionsEvolutionSpreadsheetModal:
        "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
      closeExportSchoolReinsertionsInscriptionSpreadsheetModal:
        "SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal",
      closeExportVacancyRequestsSpreadsheetModal:
        "VacancyRequests/closeExportVacancyRequestsSpreadsheetModal",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered
        ? "Filtrar por Equipamento"
        : "Selecione a área para listar os equipamentos";
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    permitExportInscriptionSpreadsheetFunc() {
      this.canAcces("export_inscriptions_school_reinsertions_spreadsheet").then((response) => {
        this.permitExportInscriptionSpreadsheet = response;
      });
    },
    permitExportVacationRequestSpreadsheetFunc() {
      this.canAcces("vacancy_request_filter_by_inscription_spreadsheet").then((response) => {
        this.permitExportVacationRequestSpreadsheet = response;
      });
    },
    permitExportSchoolReinsertionSpreadsheetFunc() {
      this.canAcces("school_reinsertion_filter_by_inscription_spreadsheet").then((response) => {
        this.permitExportSchoolReinsertionSpreadsheet = response;
      });
    },
    async clearFields() {
      await this.$store.dispatch("SchoolReinsertions/clearQueryNotReinserteds");
    },
    async exportToExcel(type) {
      if (type === "school_reinsertions") {
        await this.$store.dispatch(
          "SchoolReinsertions/loadSchoolReinsertionsByInscription",
          "loadAllDataSpreadsheet"
        );
      } else if (type === "inscriptions") {
        await this.$store.dispatch("SchoolReinsertions/loadInscriptions", {
          type: "not_reinserteds",
          data: "spreadsheet",
        });
      } else if (type === "vacancy_requests") {
        await this.$store.dispatch(
          "VacancyRequests/loadVacancyRequestsByInscription",
          "loadAllDataSpreadsheet"
        );
      }
    },
  },
  created() {
    this.permitExportInscriptionSpreadsheetFunc();
    this.permitExportVacationRequestSpreadsheetFunc();
    this.permitExportSchoolReinsertionSpreadsheetFunc();
    this.$store.dispatch("SchoolReinsertions/loadArticulators");
    this.$store.dispatch("SchoolReinsertions/loadInscriptions", {
      type: "not_reinserteds",
      data: "load_data",
    });
    this.$store.dispatch("SchoolReinsertions/loadInscriptionsForGeneralMenus");
  },

  computed: {
    ...mapState({
      season: (state) => state.SchoolReinsertions.season,
      articulators: (state) => state.SchoolReinsertions.articulators,
      inscriptions: (state) => state.SchoolReinsertions.inscriptions,
      user_areas: (state) => state.SchoolReinsertions.user_areas,
      user_areas_filtered: (state) => state.SchoolReinsertions.user_areas_filtered,
      status_student_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.status,
      user_equipments_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.equipment,
      equipments_school_reinsertions: (state) => state.SchoolReinsertions.equipments_school_reinsertions,
      articulator_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.articulator,
      institutional_referrals: (state) => state.SchoolReinsertions.institutional_referrals,
      institutional_referral_filtered: (state) =>
        state.SchoolReinsertions.params.not_reinserted_main_page.institutional_referral,
      accompaniment_statuses: (state) => state.SchoolReinsertions.accompaniment_statuses,
      accompaniment_status_filtered: (state) =>
        state.SchoolReinsertions.params.not_reinserted_main_page.accompaniment_status,
      offset: (state) => state.SchoolReinsertions.offset,
      offsetNotReinserteds: (state) => state.SchoolReinsertions.offsetNotReinserteds,
      totalPerPage: (state) => state.SchoolReinsertions.totalPerPage,
      inscriptionsTotal: (state) => state.SchoolReinsertions.inscriptionsTotal,
      school_reinsertions: (state) => state.SchoolReinsertions.school_reinsertions,
      school_reinsertions_evolution_spreadsheet: (state) =>
        state.SchoolReinsertions.school_reinsertions_evolution_spreadsheet,
      hasSpreadsheetSchoolReinsertionsEvolutionResponse: (state) =>
        state.SchoolReinsertions.hasSpreadsheetSchoolReinsertionsEvolutionResponse,
      modalToggleExportSchoolReinsertionsEvolutionSpreadsheet: (state) =>
        state.SchoolReinsertions.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet,
      json_data_school_reinsertion_evolution: (state) =>
        state.SchoolReinsertions.json_data_school_reinsertion_evolution,
      inscriptionsForGeneralMenus: (state) => state.SchoolReinsertions.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.inscription,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
      city: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.city,
      state: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.state,

      school_reinsertions_inscriptions_spreadsheet: (state) =>
        state.SchoolReinsertions.school_reinsertions_inscriptions_spreadsheet,
      hasSpreadsheetSchoolReinsertionsInscriptionsResponse: (state) =>
        state.SchoolReinsertions.hasSpreadsheetSchoolReinsertionsInscriptionsResponse,
      modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet: (state) =>
        state.SchoolReinsertions.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet,
      json_data_school_reinsertion_inscriptions: (state) =>
        state.SchoolReinsertions.json_data_school_reinsertion_inscriptions,

      vacancy_requests: (state) => state.VacancyRequests.vacancy_requests,
      vacancy_requests_spreadsheet: (state) => state.VacancyRequests.vacancy_requests_spreadsheet,
      hasSpreadsheetVacancyRequestsResponse: (state) =>
        state.VacancyRequests.hasSpreadsheetVacancyRequestsResponse,
      json_data_vacancy_request: (state) => state.VacancyRequests.json_data_vacancy_request,
      modalToggleExportVacancyRequestsSpreadsheet: (state) =>
        state.VacancyRequests.modalToggleExportVacancyRequestsSpreadsheet,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("SchoolReinsertions/loadEquipments");
    },
    offsetNotReinserteds: function () {
      this.$store.dispatch("SchoolReinsertions/loadInscriptions", {
        type: "not_reinserteds",
        data: "load_data",
      });
      this.backToTop();
    },
  },
};
</script>
