import {
  getForwardingById,
  getForwardingsPaginate,
  getForwardingStatuses,
  getForwardingReasonsOfUnrealized,
  getForwardingsSpreadsheet,
  createForwarding,
  updateForwarding,
  destroyForwarding,
} from "../../service/ForwardingsApi";
import { getForwardingPolicies } from "../../service/ForwardingPoliciesApi";
import { getFacilitiesByForwardingPolicy } from "../../service/FacilitiesApi";
import { getSeasonsForForwardings } from "../../service/SeasonApi";
import { getInscriptionBySeasonAndArea, getInscriptionForGeneralMenus } from "../../service/InscriptionApi";
import { getPsychosocialAccompanimentByInscription } from "../../service/PsychosocialAccompanimentsApi";
import Vue from "vue";
import moment from "moment";
import JsonExcel from "vue-json-excel";
import { util_function } from "@/util_functions";
Vue.component("downloadExcel", JsonExcel);

const state = {
  forwarding: {
    id: null,
    forwarding_policy_id: null,
    inscription_id: null,
    psychosocial_accompaniment_id: null,
    city_id: null,
    facility_id: null,
    forwarding_description: "",
    forwarding_date: moment().format("YYYY-MM-DD"),
    forwarding_status: null,
    forwarding_expiration_date: moment().add(15, "days").format("YYYY-MM-DD"),
    forwarding_user_id: null,
    forwarding_return_date: null,
    forwarding_return_reason_of_unrealized: null,
    forwarding_return_comments: null,
    forwarding_return_user_id: null,
  },
  seasons: [],
  forwardings: [],
  facilities: [],
  forwardings_by_inscription: [],
  forwardings_spreadsheet: [],
  forwarding_policies: [],
  forwarding_statuses: [],
  forwarding_return_reasons_of_unrealized: [],
  inscriptions: [],
  psychosocial_accompaniments: [],
  filterForwardings: [],
  user_areas: [],
  inscriptionsForGeneralMenus: [],

  hasSpreadsheetResponse: true,
  json_data: null,

  forwarding_policy: null,
  season: null,
  inscription: null,
  psychosocial_accompaniment: null,
  forwarding_original_expiration_date: null,
  forwarding_original_return_comments: null,
  forwarding_status: null,
  forwarding_return_reason_of_unrealized: null,
  forwarding_user: null,
  forwarding_return_user: null,
  user_areas_filtered: null,
  facility: null,
  city: null,
  state: null,

  differenceBetweenDates: 15,
  forwardingId: 0,

  modalToggleForwardings: false,
  modalToggleReturnForwardings: false,
  modalConfirmDeleteForwarding: false,
  modalViewForwarding: false,
  modalToggleViewHistoricForwarding: false,
  modalToggleForwardingPostponeExpirationDate: false,
  modalToggleExportForwardingSpreadsheet: false,

  forwardingTotal: 0,
  totalPerPage: 10,
  offset: 0,
  params: {
    inscription: null,
    userName: null,
    forwarding_initial_date: null,
    forwarding_final_date: null,
    forwarding_expiration_initial_date: null,
    forwarding_expiration_final_date: null,
    forwarding_policy: null,
    forwarding_status: null,
    equipment: null,
    season: null,
    area: null,
    city: null,
    state: null,
  },
  loading: {
    load_forwardings: false,
  },
};

const mutations = {
  // --> MUTATIONS - DATA COMING FROM API <--
  setForwardingsMutation: (state, forwardings) => {
    state.forwardings = forwardings;
    state.filterForwardings = forwardings;
  },
  setForwardingsByInscriptionMutation: (state, forwardings_by_inscription) => {
    state.forwardings_by_inscription = forwardings_by_inscription;
  },
  setForwardingsSpreadsheetMutation: (state, forwardings_spreadsheet) => {
    state.forwardings_spreadsheet = forwardings_spreadsheet;
  },
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
  },
  setInscriptionsMutation: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },
  setForwardingPoliciesMutation: (state, forwarding_policies) => {
    state.forwarding_policies = forwarding_policies;
  },
  setForwardingStatusesMutation: (state, forwarding_statuses) => {
    state.forwarding_statuses = forwarding_statuses;
  },
  setForwardingReasonsOfUnrealizedMutation: (state, forwarding_return_reasons_of_unrealized) => {
    state.forwarding_return_reasons_of_unrealized = forwarding_return_reasons_of_unrealized;
  },
  setFacilitiesMutation: (state, facilities) => {
    state.facilities = facilities;
  },
  setPsychosocialAccompanimentsMutation: (state, psychosocial_accompaniments) => {
    state.psychosocial_accompaniments = psychosocial_accompaniments;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },

  // --> MUTATIONS - FIELDS TO THE TABLE <--
  setIdMutation: (state, id) => {
    state.forwarding.id = id;
  },
  setForwardingFacilityMutation: (state, facility) => {
    if (facility) {
      state.forwarding.facility_id = facility.id;
    }
    state.facility = facility;
  },
  setForwardingDescriptionMutation: (state, forwarding_description) => {
    state.forwarding.forwarding_description = forwarding_description;
  },
  setForwardingStatusMutation: (state, forwarding_status) => {
    state.forwarding.forwarding_status = forwarding_status.id;
    state.forwarding_status = forwarding_status;
  },
  setForwardingReasonOfUnrealizedMutation: (state, forwarding_return_reason_of_unrealized) => {
    state.forwarding_return_reason_of_unrealized = forwarding_return_reason_of_unrealized;
    if (forwarding_return_reason_of_unrealized != null) {
      state.forwarding.forwarding_return_reason_of_unrealized = forwarding_return_reason_of_unrealized.id;
    } else {
      state.forwarding.forwarding_return_reason_of_unrealized = null;
    }
  },
  setForwardingDateMutation: (state, forwarding_date) => {
    state.forwarding.forwarding_date = forwarding_date;
  },
  setForwardingExpirationDateMutation: (state, forwarding_expiration_date) => {
    state.forwarding.forwarding_expiration_date = forwarding_expiration_date;
  },
  setForwardingOriginalExpirationDateMutation: (state, forwarding_original_expiration_date) => {
    state.forwarding_original_expiration_date = forwarding_original_expiration_date;
  },
  setDifferenceBetweenDatesMutation: (state, differenceBetweenDates) => {
    state.differenceBetweenDates = differenceBetweenDates;
  },
  setForwardingUserMutation: (state, forwarding_user) => {
    state.forwarding_user = forwarding_user;
    if (forwarding_user != null) {
      if (forwarding_user.id) {
        state.forwarding.forwarding_user_id = forwarding_user.id;
      } else {
        state.forwarding.forwarding_user_id = forwarding_user.userId;
      }
    }
  },
  setForwardingReturnUserMutation: (state, forwarding_return_user) => {
    state.forwarding_return_user = forwarding_return_user;
    if (forwarding_return_user != null) {
      if (forwarding_return_user.id) {
        state.forwarding.forwarding_return_user_id = forwarding_return_user.id;
      } else {
        state.forwarding.forwarding_return_user_id = forwarding_return_user.userId;
      }
    }
  },
  setForwardingReturnDateMutation: (state, forwarding_return_date) => {
    state.forwarding.forwarding_return_date = forwarding_return_date;
  },
  setForwardingReturnCommentsMutation: (state, forwarding_return_comments) => {
    state.forwarding.forwarding_return_comments = forwarding_return_comments;
  },
  setForwardingOriginalReturnCommentsMutation: (state, forwarding_original_return_comments) => {
    state.forwarding_original_return_comments = forwarding_original_return_comments;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.forwarding.city_id = city ? city.id : null;
  },
  setForwardingPolicyMutation: (state, forwarding_policy) => {
    state.forwarding.forwarding_policy_id = forwarding_policy.id;
    state.forwarding_policy = forwarding_policy;
  },
  setInscriptionMutation: (state, inscription) => {
    if (inscription != null) {
      state.forwarding.inscription_id = inscription.id;
      state.inscription = inscription;
    } else {
      state.inscription = inscription;
    }
  },
  setPsychosocialAccompanimentMutation: (state, psychosocial_accompaniment) => {
    state.forwarding.psychosocial_accompaniment_id = psychosocial_accompaniment.id;
    state.psychosocial_accompaniment = psychosocial_accompaniment;
  },

  // --> MUTATIONS - PAGINATION <--
  setTotalPerPage: (state, totalPerPage) => {
    state.totalPerPage = totalPerPage;
  },
  setOffset: (state, offset) => {
    state.offset = offset;
  },
  setForwardingTotal: (state, forwardingTotal) => {
    state.forwardingTotal = forwardingTotal;
  },

  // --> MUTATIONS - FILTERS <--
  filterListByInscription: (state, input) => {
    state.params.inscription = input;
  },
  filterListByUserName: (state, input) => {
    state.params.userName = input.value;
  },
  filterListByForwardingPolicy: (state, input) => {
    state.params.forwarding_policy = input;
  },
  filterListByForwardingStatus: (state, input) => {
    state.params.forwarding_status = input;
  },
  filterListBySeason: (state, season) => {
    state.params.season = season;
  },
  filterListByArea: (state, area) => {
    state.params.area = area;
  },
  filterListByCity: (state, city) => {
    state.params.city = city;
  },
  filterListByState: (state, value) => {
    state.params.state = value;
  },
  filterListByEquipment: (state, input) => {
    state.params.equipment = input.value;
  },
  filterListByForwardingInitialDate: (state, input) => {
    state.params.forwarding_initial_date = input.value;
  },
  filterListByForwardingFinalDate: (state, input) => {
    state.params.forwarding_final_date = input.value;
  },
  filterListByForwardingExpirationInitialDate: (state, input) => {
    state.params.forwarding_expiration_initial_date = input.value;
  },
  filterListByForwardingExpirationFinalDate: (state, input) => {
    state.params.forwarding_expiration_final_date = input.value;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },

  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalForwardings: (state) => {
    state.modalToggleForwardings = !state.modalToggleForwardings;
  },
  toggleModalReturnForwardings: (state) => {
    state.modalToggleReturnForwardings = !state.modalToggleReturnForwardings;
  },
  toggleModalDeleteForwarding: (state) => {
    state.modalConfirmDeleteForwarding = !state.modalConfirmDeleteForwarding;
  },
  toggleModalViewForwarding: (state) => {
    state.modalViewForwarding = !state.modalViewForwarding;
  },
  toggleModalViewHistoricForwarding: (state) => {
    state.modalToggleViewHistoricForwarding = !state.modalToggleViewHistoricForwarding;
  },
  toggleModalForwardingPostponeExpirationDate: (state) => {
    state.modalToggleForwardingPostponeExpirationDate = !state.modalToggleForwardingPostponeExpirationDate;
  },
  toggleModalExportForwardingSpreadsheet: (state) => {
    state.modalToggleExportForwardingSpreadsheet = !state.modalToggleExportForwardingSpreadsheet;
  },

  // --> MUTATIONS - DELETION <--
  forwardingForDeletion: (state, payload) => {
    state.forwardingId = payload.id;
  },

  // --> MUTATIONS - SPREADSHEET <--
  setJsonDataMutation: (state, json_data) => {
    state.json_data = json_data;
  },
  hasSpreadsheetResponseMutation: (state, hasSpreadsheetResponse) => {
    state.hasSpreadsheetResponse = hasSpreadsheetResponse;
  },
  setForwardingsToExportMutation: (state, forwardings_to_export) => {
    state.json_data = null;
    const arrayData = new Array();
    forwardings_to_export.forEach((element, index, array) => {
      let student_name = array[index].inscription.student ? array[index].inscription.student.name : "";
      let student_social_name = array[index].inscription.student
        ? array[index].inscription.student.social_name
        : "";
      let season_name = array[index].inscription.season ? array[index].inscription.season.name : "";
      let situation_risk = array[index].situation_risk ? array[index].situation_risk : "";
      let student_area = array[index].inscription.area ? array[index].inscription.area.name : "";
      let student_equipment = array[index].inscription.equipment
        ? array[index].inscription.equipment.name
        : "";
      let forwarding_date = moment(array[index].forwarding_date).format("DD/MM/YYYY");
      let forwarding_expiration_date = moment(array[index].forwarding_expiration_date).format("DD/MM/YYYY");
      let forwarding_policy = array[index].forwarding_policy ? array[index].forwarding_policy.name : "";
      let forwarding_status = array[index].forwarding_status ? array[index].forwarding_status.name : "";
      let forwarding_description = array[index].forwarding_description
        ? array[index].forwarding_description
        : "";
      let forwarding_reason_unrealized = array[index].forwarding_reason_unrealized
        ? array[index].forwarding_reason_unrealized.name
        : "";
      let equipment = array[index].inscription.equipment ? array[index].inscription.equipment.name : "";
      let forwarding_user = array[index].forwarding_user ? array[index].forwarding_user.name : "";
      let forwarding_return_user = array[index].forwarding_return_user
        ? array[index].forwarding_return_user.name
        : "";

      const arrayItem = new Array();
      arrayItem["season_name"] = season_name;
      arrayItem["student_social_name"] = student_social_name;
      arrayItem["student_name"] = student_name;
      arrayItem["student_area"] = student_area;
      arrayItem["equipment"] = equipment;
      arrayItem["situation_risk"] = situation_risk;
      arrayItem["student_equipment"] = student_equipment;
      arrayItem["forwarding_description"] = forwarding_description;
      arrayItem["forwarding_date"] = forwarding_date;
      arrayItem["forwarding_expiration_date"] = forwarding_expiration_date;
      arrayItem["forwarding_policy"] = forwarding_policy;
      arrayItem["forwarding_status"] = forwarding_status;
      arrayItem["forwarding_reason_unrealized"] = forwarding_reason_unrealized;
      arrayItem["forwarding_user"] = forwarding_user;
      arrayItem["forwarding_return_user"] = forwarding_return_user;
      arrayData.push(arrayItem);
    });
    state.json_data = arrayData;
    state.hasSpreadsheetResponse = true;
    state.modalToggleExportForwardingSpreadsheet = true;
  },
  setLoadingLoadForwardings: (state, load_forwardings) => {
    state.loading.load_forwardings = load_forwardings;
  },

  // --> MUTATIONS - CLEARS <--
  clearQuery: (state, payload) => {
    if (payload && payload.name === "forwardings") {
      state.forwardings = [];
      state.filterForwardings = [];
    }

    if (payload && payload.name === "psychosocial") {
      state.forwardings_by_inscription = [];
    }

    state.params.inscription = null;
    state.params.userName = null;
    state.params.forwarding_initial_date = null;
    state.params.forwarding_final_date = null;
    state.params.forwarding_expiration_initial_date = null;
    state.params.forwarding_expiration_final_date = null;
    state.params.forwarding_policy = null;
    state.params.forwarding_status = null;
    state.params.equipment = null;
    state.params.season = null;
    state.params.area = null;
    state.params.city = null;
    state.params.state = null;
  },
  clearFields: (state) => {
    state.forwarding_policy = null;
    state.inscription = null;
    state.psychosocial_accompaniment = null;
    state.season = null;
    state.user_areas_filtered = null;
    state.facility = null;
    state.city = null;
    state.state = null;
    state.forwarding.id = null;
    state.forwarding.city_id = null;
    state.forwarding.forwarding_equipment = "";
    state.forwarding.psychosocial_accompaniment_id = null;
    state.forwarding.forwarding_description = "";
    state.forwarding.forwarding_status = 0;
    state.differenceBetweenDates = 15;
    state.forwarding.forwarding_date = moment().format("YYYY-MM-DD");
    state.forwarding.forwarding_expiration_date = moment().add(15, "days").format("YYYY-MM-DD");
    state.forwarding.forwarding_return_date = null;
    state.forwarding_return_reason_of_unrealized = null;
    state.forwarding.forwarding_return_reason_of_unrealized = null;
    state.forwarding.forwarding_return_comments = null;
    state.forwarding.forwarding_return_user_id = null;
  },
};

function getForwardingParams(rootGetters) {
  let inscription_id = null;

  const url = window.location.href;

  url.includes("psicossocial/pagina_principal")
    ? (inscription_id = sessionStorage.inscription_id)
    : (inscription_id = null);

  const params = {
    name: util_function.formatNameForFilter(state.params.inscription ? state.params.inscription.name : null),
    userName: state.params.userName,
    inscription_id: inscription_id,
    forwarding_policy: state.params.forwarding_policy ? state.params.forwarding_policy.id : null,
    forwarding_status: state.params.forwarding_status ? state.params.forwarding_status.id : null,
    forwarding_area: state.params.area ? state.params.area.id : null,
    forwarding_season: state.params.season ? state.params.season.id : null,
    forwarding_initial_date: state.params.forwarding_initial_date,
    forwarding_final_date: state.params.forwarding_final_date,
    forwarding_expiration_initial_date: state.params.forwarding_expiration_initial_date,
    forwarding_expiration_final_date: state.params.forwarding_expiration_final_date,
    equipment: state.params.equipment,
    perPage: state.totalPerPage,
    offset: state.offset,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    by: "students.name",
    order: "ASC",
  };
  return { inscription_id, params };
}

function getUserAreas(rootState, state) {
  let user_areas_ids =
    rootState.user_areas.areas && rootState.user_areas.areas.length > 0
      ? rootState.user_areas.areas.map((item) => item.id)
      : null;

  let area_filter = [];
  if (state.user_areas_filtered) {
    state.user_areas_filtered.forEach((item) => {
      area_filter.push(item.id);
    });
  }
  return { user_areas_ids, area_filter };
}

const actions = {
  // --> ACTIONS & LOADS - DATA COMING FROM API <--
  loadForwardings: ({ commit, rootGetters }, from) => {
    commit("setLoadingLoadForwardings", true);
    let { inscription_id, params } = getForwardingParams(rootGetters);
    getForwardingsPaginate(params)
      .then((response) => {
        if (inscription_id) {
          commit("setForwardingsByInscriptionMutation", response.data.forwardings);
        } else {
          commit("setForwardingsMutation", response.data.forwardings);
        }
        commit("setForwardingTotal", response.data.forwardingTotal);
        if (parseInt(response.data.forwardingTotal, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
        commit("setLoadingLoadForwardings", false);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        commit("setLoadingLoadForwardings", false);
      });
  },
  loadForwardingsSpreadsheet: async ({ commit, rootGetters }) => {
    commit("hasSpreadsheetResponseMutation", false);
    let { params } = getForwardingParams(rootGetters);
    await getForwardingsSpreadsheet(params)
      .then(async (response) => {
        commit("setForwardingsSpreadsheetMutation", response.data.forwardings);
        if (state.forwardings_spreadsheet.length > 0) {
          await commit("setForwardingsToExportMutation", response.data.forwardings);
        }
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadForwardingPolicies: ({ commit }) => {
    getForwardingPolicies()
      .then((response) => {
        commit("setForwardingPoliciesMutation", response.data.forwarding_policies);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadForwardingStatuses: ({ commit }) => {
    getForwardingStatuses()
      .then((response) => {
        commit("setForwardingStatusesMutation", response.data.forwarding_status);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadForwardingReasonsOfUnrealized: ({ commit }) => {
    getForwardingReasonsOfUnrealized()
      .then((response) => {
        commit("setForwardingReasonsOfUnrealizedMutation", response.data.forwardings_reason_unrealized);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadSeasons: ({ commit }) => {
    getSeasonsForForwardings()
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadFacilitiesByForwardingPolicy: ({ commit }) => {
    let params = {
      forwarding_policy_id: state.forwarding.forwarding_policy_id,
      by: "facilities.name",
      order: "ASC",
    };
    getFacilitiesByForwardingPolicy(params)
      .then((response) => {
        commit("setFacilitiesMutation", response.data.facilities);
        commit("setForwardingFacilityMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptions: ({ commit, rootState }) => {
    let { user_areas_ids, area_filter } = getUserAreas(rootState, state);
    let params = {
      season_id: state.params.season ? state.params.season.id : null,
      area_id: area_filter && area_filter.length > 0 ? area_filter : user_areas_ids,
      resource: "forwardings",
    };
    getInscriptionBySeasonAndArea(params)
      .then((response) => {
        commit("setInscriptionsMutation", response.data.inscriptions);
        const formattedInscriptions = response.data.inscriptions.map((inscription) => {
          return {
            ...inscription,
            name: inscription?.social_name
              ? `${inscription?.social_name} - ${inscription?.name}`
              : inscription?.name,
          };
        });
        commit("setInscriptionsMutation", formattedInscriptions);
        commit("setInscriptionMutation", null);
        commit("setPsychosocialAccompanimentsMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptionsForGeneralMenus: ({ commit, rootState }, value) => {
    let { user_areas_ids, area_filter } = getUserAreas(rootState, state);
    const params = {
      participant_name: value,
      area_id: area_filter && area_filter.length > 0 ? area_filter : user_areas_ids,
      season_id: state.params.season ? state.params.season.id : null,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForGeneralMenus(params)
      .then((response) => {
        commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPsychosocialAccompaniments: ({ commit }) => {
    let params = {
      inscription_id: state.inscription.id,
    };
    getPsychosocialAccompanimentByInscription(params)
      .then((response) => {
        commit("setPsychosocialAccompanimentsMutation", response.data.psychosocial_accompaniments);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getForwardingById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.forwarding.id);
        commit("setForwardingFacilityMutation", response.data.forwarding.facility);
        commit("setForwardingPolicyMutation", response.data.forwarding.forwarding_policy);
        commit("setForwardingDescriptionMutation", response.data.forwarding.forwarding_description);
        commit("setForwardingStatusMutation", response.data.forwarding.forwarding_status);
        commit("setInscriptionMutation", response.data.forwarding.inscription);
        commit("setForwardingDateMutation", response.data.forwarding.forwarding_date);
        commit("setForwardingExpirationDateMutation", response.data.forwarding.forwarding_expiration_date);
        commit("setForwardingUserMutation", response.data.forwarding.forwarding_user);
        commit(
          "setForwardingOriginalExpirationDateMutation",
          response.data.forwarding.forwarding_expiration_date
        );
        if (response.data.forwarding.forwarding_return_date) {
          commit("setForwardingReturnDateMutation", response.data.forwarding.forwarding_return_date);
        }
        commit("setForwardingReturnCommentsMutation", response.data.forwarding.forwarding_return_comments);
        commit(
          "setForwardingOriginalReturnCommentsMutation",
          response.data.forwarding.forwarding_return_comments
        );
        if (response.data.forwarding.forwarding_reason_unrealized) {
          commit(
            "setForwardingReasonOfUnrealizedMutation",
            response.data.forwarding.forwarding_reason_unrealized
          );
        }
        if (response.data.forwarding.forwarding_return_user) {
          commit("setForwardingReturnUserMutation", response.data.forwarding.forwarding_return_user);
        }
        if (response.data.forwarding.psychosocial_accompaniment) {
          commit("setPsychosocialAccompanimentMutation", response.data.forwarding.psychosocial_accompaniment);
        }
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },

  // --> ACTIONS - FILTERS <--
  filterListByInscription: ({ commit }, payload) => {
    commit("filterListByInscription", payload);
  },
  filterListByUserName: ({ commit }, payload) => {
    commit("filterListByUserName", payload);
  },
  filterListByForwardingPolicy: ({ commit }, payload) => {
    commit("filterListByForwardingPolicy", payload);
  },
  filterListByForwardingStatus: ({ commit }, payload) => {
    commit("filterListByForwardingStatus", payload);
  },
  filterListByEquipment: ({ commit }, payload) => {
    commit("filterListByEquipment", payload);
  },
  filterListBySeason: ({ commit }, payload) => {
    commit("filterListBySeason", payload);
  },
  filterListByArea: ({ commit }, payload) => {
    commit("filterListByArea", payload);
  },
  filterListByCity: ({ commit }, payload) => {
    commit("filterListByCity", payload);
  },
  filterListByState: ({ commit }, payload) => {
    commit("filterListByState", payload);
  },
  filterListByForwardingInitialDate: ({ commit }, payload) => {
    commit("filterListByForwardingInitialDate", payload);
  },
  filterListByForwardingFinalDate: ({ commit }, payload) => {
    commit("filterListByForwardingFinalDate", payload);
  },
  filterListByForwardingExpirationInitialDate: ({ commit }, payload) => {
    commit("filterListByForwardingExpirationInitialDate", payload);
  },
  filterListByForwardingExpirationFinalDate: ({ commit }, payload) => {
    commit("filterListByForwardingExpirationFinalDate", payload);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    commit("setInscriptionMutation", null);
  },

  // --> ACTIONS - CLEAR <--
  clearQuery: ({ commit }, payload) => {
    commit("clearQuery", payload);
  },

  // --> ACTIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleModalForwardings: ({ commit, dispatch }) => {
    const user = JSON.parse(localStorage.userData);
    dispatch("setForwardingUser", user);
    commit("setUserAreasMutation", user.areas);
    commit("toggleModalForwardings");
  },
  toggleModalDeleteForwarding: ({ commit }, payload) => {
    commit("toggleModalDeleteForwarding");
    if (state.modalConfirmDeleteForwarding) {
      commit("forwardingForDeletion", payload);
    } else {
      commit("forwardingForDeletion", null);
    }
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    const user = JSON.parse(localStorage.userData);
    dispatch("setForwardingUser", user);
    dispatch("loadData", payload);
    commit("toggleModalForwardings");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    await dispatch(
      "ForwardingPostponementHistories/loadForwardingPostponementHistoriesByForwarding",
      payload.id,
      {
        root: true,
      }
    );
    commit("toggleModalViewForwarding");
  },
  closeViewModal: ({ commit, dispatch }) => {
    dispatch("setForwardingUser", null);
    commit("toggleModalViewForwarding");
    commit("clearFields");
  },
  openReturnModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    const user = JSON.parse(localStorage.userData);
    dispatch("setForwardingReturnUser", user);
    commit("setForwardingReturnDateMutation", moment().format("YYYY-MM-DD"));
    commit("toggleModalReturnForwardings");
  },
  closeReturnModal: ({ commit, dispatch }) => {
    dispatch("setForwardingReturnUser", null);
    commit("setForwardingReturnDateMutation", null);
    commit("toggleModalReturnForwardings");
    commit("setForwardingOriginalExpirationDateMutation", null);
    commit("clearFields");
  },
  openModalForwardingPostponeExpirationDate: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    const user = JSON.parse(localStorage.userData);
    dispatch("setForwardingReturnUser", user);
    commit("setForwardingReturnDateMutation", moment().format("YYYY-MM-DD"));
    commit("toggleModalForwardingPostponeExpirationDate");
  },
  closeModalForwardingPostponeExpirationDate: ({ commit, dispatch }) => {
    dispatch("setForwardingReturnUser", null);
    commit("setForwardingReturnDateMutation", null);
    commit("toggleModalForwardingPostponeExpirationDate");
    commit("setForwardingOriginalExpirationDateMutation", null);
    commit("clearFields");
    commit("ForwardingPostponementHistories/setForwardingPostponementCommentsMutation", null, { root: true });
  },
  openViewHistoricModal: async ({ commit, dispatch }, payload) => {
    await dispatch(
      "ForwardingPostponementHistories/loadForwardingPostponementHistoriesByForwarding",
      payload.id,
      {
        root: true,
      }
    );
    commit("toggleModalViewHistoricForwarding");
  },
  closeViewHistoricModal: ({ commit }) => {
    commit("toggleModalViewHistoricForwarding");
  },
  closeModal: ({ commit }) => {
    commit("toggleModalForwardings");
    commit("clearFields");
  },
  openExportSpreadsheetModal: ({ commit }) => {
    commit("toggleModalExportForwardingSpreadsheet");
  },
  closeExportSpreadsheetModal: ({ commit }) => {
    commit("toggleModalExportForwardingSpreadsheet");
  },

  // --> ACTIONS - FIELDS TO THE TABLE <--
  setForwardingFacility: ({ commit }, payload) => {
    commit("setForwardingFacilityMutation", payload);
  },
  setForwardingDescription: ({ commit }, payload) => {
    commit("setForwardingDescriptionMutation", payload.value);
  },
  setForwardingDate: ({ commit }, payload) => {
    commit("setForwardingDateMutation", payload.value);
  },
  setForwardingExpirationDate: ({ commit }, payload) => {
    commit("setForwardingExpirationDateMutation", payload.value);
  },
  setDifferenceBetweenDates: ({ commit }, payload) => {
    commit("setDifferenceBetweenDatesMutation", payload.value);
  },
  setForwardingStatus: ({ commit }, payload) => {
    commit("setForwardingStatusMutation", payload);
  },
  setForwardingReasonOfUnrealized: ({ commit }, payload) => {
    commit("setForwardingReasonOfUnrealizedMutation", payload);
  },
  setForwardingPolicy: ({ commit }, payload) => {
    commit("setForwardingPolicyMutation", payload);
  },
  setInscription: ({ commit, dispatch }, payload) => {
    commit("setInscriptionMutation", payload);
    dispatch("loadPsychosocialAccompaniments");
  },
  setPsychosocialAccompaniment: ({ commit }, payload) => {
    commit("setPsychosocialAccompanimentMutation", payload);
  },
  setForwardingUser: ({ commit }, payload) => {
    commit("setForwardingUserMutation", payload);
  },
  setForwardingReturnUser: ({ commit }, payload) => {
    commit("setForwardingReturnUserMutation", payload);
  },
  setForwardingReturnDate: ({ commit }, payload) => {
    commit("setForwardingReturnDate", payload.value);
  },
  setForwardingReturnReason: ({ commit }, payload) => {
    commit("setForwardingReturnReasonMutation", payload.value);
  },
  setForwardingReturnComments: ({ commit }, payload) => {
    commit("setForwardingReturnCommentsMutation", payload.value);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
    commit("setUserAreasFilteredMutation", null);
    commit("setInscriptionMutation", null);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setForwardings: ({ commit }, payload) => {
    commit("setForwardingsMutation", payload);
  },

  // --> ACTIONS - PAGINATION <--
  setTotalPerPage: ({ commit }, payload) => {
    commit("setTotalPerPage", payload.totalPerPage);
  },
  setOffset: ({ commit }, payload) => {
    commit("setOffset", payload.offset);
  },

  // --> ACTIONS - EXPORT SPREADSHEET <--
  hasSpreadsheetResponse: ({ commit }, payload) => {
    commit("hasSpreadsheetResponseMutation", payload);
  },

  // --> ACTIONS - CRUD <--
  save: ({ commit, dispatch }) => {
    if (state.forwarding.forwarding_expiration_date > state.forwarding.forwarding_date) {
      createForwarding(state.forwarding)
        .then(() => {
          Vue.$toast.success("Encaminhamento cadastrado com sucesso!");
          if (state.modalToggleForwardings) {
            commit("toggleModalForwardings");
          }
          commit("clearFields");
          dispatch("Forwardings/loadForwardings", null, { root: true });
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possivel cadastrar o encaminhamento! " + e.response.data.error);
          console.log(e);
        });
    } else {
      Vue.$toast.error("Data de Expiração não pode ser anterior a Data de cadastro do Encaminhamento!");
    }
  },
  update: ({ dispatch, commit }) => {
    if (state.forwarding.forwarding_expiration_date > state.forwarding.forwarding_date) {
      // --> INSERT RECORD INTO TABLE FOWARDING_POSPONEMENT_HISTORY <--
      if (state.forwarding_original_expiration_date !== state.forwarding.forwarding_expiration_date) {
        dispatch("ForwardingPostponementHistories/setForwardingId", state.forwarding.id, {
          root: true,
        });
        dispatch(
          "ForwardingPostponementHistories/setForwardingExpirationDateOriginal",
          state.forwarding_original_expiration_date,
          { root: true }
        );
        dispatch("ForwardingPostponementHistories/setPostponementUser", state.forwarding_return_user, {
          root: true,
        });
        dispatch("ForwardingPostponementHistories/save", {}, { root: true });
      }

      // --> VALIDATES IF STATUS IS SET TO 'NOT EFFECTIVE' WHEN SETTING MOTIVATION FIELD
      if (state.forwarding_status.name !== "Não Efetivado") {
        dispatch("setForwardingReasonOfUnrealized", null);
      }

      updateForwarding(state.forwarding.id, state.forwarding)
        .then(() => {
          Vue.$toast.success("Encaminhamento atualizado com sucesso!");
          if (state.modalToggleReturnForwardings) {
            dispatch("closeReturnModal");
          }

          if (state.modalToggleForwardingPostponeExpirationDate) {
            dispatch("closeModalForwardingPostponeExpirationDate");
          }
          // dispatch("Forwardings/updateFilterById", state.forwarding.id, { root: true });
          dispatch("Forwardings/loadForwardings", null, { root: true });
          commit("clearFields");
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error + "!");
        });
    } else {
      Vue.$toast.error("Data de Expiração não pode ser anterior a Data de cadastro do Encaminhamento!");
    }
  },
  delete: ({ commit, dispatch }) => {
    destroyForwarding(state.forwardingId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Encaminhamento deletado com sucesso!");
        commit("toggleModalDeleteForwarding");
        dispatch("Forwardings/loadForwardings", from, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
