<template>
  <div class="font-system">
    <div class="mx-12 my-8 text-burnedYellow hover:text-burnedYellow-hover grid grid-flow-col auto-cols-max">
      <router-link to="/psicossocial" class="flex items-center">
        <a @click="goBack('personal_data')" href="javascript:void(0)">
          <AppMaterialIcon iconName="arrow_back" iconType="outlined" iconSize="18" class="mr-1" />
          <BaseText text="Voltar" type-text="custom" weight="semibold" class="hover:underline" />
        </a>
      </router-link>
    </div>
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
        Psicossocial - Atendimento Individual
      </div>
    </div>
    <div
      id="tabs-button"
      class="grid grid-cols-1 justify-between p-4 items-end mx-12 my-6 gap-4"
      v-if="inscription"
    >
      <div>
        <span v-if="inscription?.student">
          <Label
            :text="inscription?.student?.season?.name"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            mode="uppercase"
            class="text-burnedYellow"
          />
        </span>
        |
        <span v-if="inscription">
          <Label
            :text="getEquipmentName()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </span>
        |
        <span v-if="inscription?.student">
          <Label
            :text="getFrequency()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </span>
        |
        <span v-if="inscription?.student">
          <Label
            :text="getLastPresence()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </span>
        <div v-if="inscription?.student">
          <BaseText
            :text="
              inscription?.student?.social_name
                ? `${inscription?.student?.social_name} - (${inscription?.student?.name})`
                : inscription?.student?.name
            "
            typeText="custom"
            weight="medium"
            size="2xl"
            class="mr-1"
          />
          <span> - </span>
          <BaseText
            :text="inscription?.student?.cpf | VMask('###.###.###-##')"
            typeText="custom"
            weight="medium"
            size="2xl"
            class="mr-1"
          />
        </div>
        <div class="w-full" v-if="inscription">
          <BaseText text="Situação -" typeText="custom" weight="normal" size="sm" class="mr-1" />
          <Label
            :text="getSeverity()"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            class="text-burnedYellow"
          />
        </div>
      </div>
    </div>
    <div id="menus_psychosocial_main_page" class="my-10 mx-16 my-6 flex">
      <div>
        <a @click="changeTab('personal_data')" href="javascript:void(0)">
          <BaseText
            text="Dados Pessoais"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorPersonalData"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitShowVulnerability && !togglePsychosocialAccompanimentUnaccompanied">
        <a @click="changeTab('vulnerability')" href="javascript:void(0)">
          <BaseText
            text="Vulnerabilidade"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorVulnerability"
          />
        </a>
      </div>
      <div
        class="border-l mx-10"
        v-if="permitShowVulnerability && !togglePsychosocialAccompanimentUnaccompanied"
      ></div>
      <div v-if="permitShowEvolution">
        <a @click="changeTab('evolution')" href="javascript:void(0)">
          <BaseText
            text="Evolução"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorEvolution"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permitShowForwarding">
        <a @click="changeTab('forwardings')" href="javascript:void(0)">
          <BaseText
            text="Encaminhamentos"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorForwardings"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocial">
      <div class="mx-12">
        <PsychosocialAccompanimentPersonalData :open="togglePsychosocialAccompanimentPersonalData" />
        <PsychosocialAccompanimentVulnerabilty :open="togglePsychosocialAccompanimentVulnerability" />
        <PsychosocialAccompanimentGuidelines :open="togglePsychosocialAccompanimentGuidelines" />
        <PsychosocialAccompanimentEvolution :open="togglePsychosocialAccompanimentEvolution" />
        <PsychosocialAccompanimentForwardings :open="togglePsychosocialAccompanimentForwardings" />
      </div>
    </div>
  </div>
</template>

<script>
import Label from "../../components/Label.vue";
import BaseText from "../../components/BaseText.vue";
import PsychosocialAccompanimentPersonalData from "../../views/psychosocialaccompanimentpages/PsychosocialAccompanimentPersonalData.vue";
import PsychosocialAccompanimentVulnerabilty from "../../views/psychosocialaccompanimentpages/PsychosocialAccompanimentVulnerabilty.vue";
import PsychosocialAccompanimentGuidelines from "../../views/psychosocialaccompanimentpages/PsychosocialAccompanimentGuidelines.vue";
import PsychosocialAccompanimentEvolution from "../../views/psychosocialaccompanimentpages/PsychosocialAccompanimentEvolution.vue";
import PsychosocialAccompanimentForwardings from "../../views/psychosocialaccompanimentpages/PsychosocialAccompanimentForwardings.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppMaterialIcon,
    PsychosocialAccompanimentPersonalData,
    PsychosocialAccompanimentVulnerabilty,
    PsychosocialAccompanimentGuidelines,
    PsychosocialAccompanimentEvolution,
    PsychosocialAccompanimentForwardings,
    BaseText,
    Label,
  },
  data() {
    return {
      permitShowEvolution: false,
      permitShowVulnerability: false,
      permitShowForwarding: false,
    };
  },
  async created() {
    this.backToTop();
    if (sessionStorage.season_information) {
      const season_information = JSON.parse(sessionStorage.season_information);
      await this.$store.dispatch("PsychosocialAccompaniments/setSeason", season_information);
    }
    const inscription_id = sessionStorage.inscription_id;
    await this.$store.dispatch(
      "PsychosocialAccompaniments/loadInscriptionByIdForPsychosocialAccompaniment",
      inscription_id
    );
    this.canAcces("psychosocial_filter_by_inscription").then((response) => {
      this.permitShowEvolution = response;
    });
    this.canAcces("index_by_inscription").then((response) => {
      this.permitShowVulnerability = response;
    });
    this.canAcces("forwarding_filter_paginate").then((response) => {
      this.permitShowForwarding = response;
    });
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByForwardingPolicy: "Forwardings/filterListByForwardingPolicy",
      setSeason: "PsychosocialAccompaniments/setSeason",
    }),
    async clearFields() {
      await this.$store.dispatch("Forwardings/clearQuery");
      await this.$store.dispatch("MatrixRiskStratifications/clearQuery");
      await this.$store.dispatch("PsychosocialAccompaniments/clearQueryEvolutionPage");
    },
    async goBack(value) {
      await this.$store.dispatch("PsychosocialAccompaniments/setTabPsychosocialAccompanimentType", value);
      this.backToTop();
      await this.$store.dispatch("Forwardings/clearQuery");
      await this.$store.dispatch("MatrixRiskStratifications/clearQuery");
      await this.$store.dispatch("PsychosocialAccompaniments/clearQueryEvolutionPage");
      let payload = {
        name: "psychosocial",
      };
      await this.$store.dispatch("Forwardings/clearQuery", payload);
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    getEquipmentName() {
      if (this.inscription) {
        if (this.inscription?.student.equipment) {
          return this.inscription?.student?.equipment?.name;
        } else {
          return "Sem equipamento cadastrado";
        }
      }
    },
    getFrequency() {
      if (this.inscription) {
        if (
          this.inscription?.student?.teams_attributes &&
          this.inscription?.student?.teams_attributes?.length > 0
        ) {
          if (this.inscription?.student?.teams_attributes[0]?.presence_percent) {
            return "Frequência: " + this.inscription?.student?.teams_attributes[0]?.presence_percent + "%";
          } else {
            return "Frequência não registrada";
          }
        } else {
          return "Jovem não alocado(a) em turma";
        }
      }
    },
    getLastPresence() {
      let array_dates = [];
      let string_return = "Última Presença: ";

      if (this.inscription && this.inscription.student && this.inscription.student.teams_attributes) {
        this.inscription.student.teams_attributes.forEach((team) => {
          if (team?.last_presence?.updated_at) {
            array_dates.push(team.last_presence.updated_at);
          }
        });
      }

      if (array_dates.length > 0) {
        const lastPresence = this.$util_function.getLastPresence(array_dates);
        string_return += lastPresence;
      } else {
        string_return += "-";
      }

      return string_return;
    },
    getSeverity() {
      if (this.inscription) {
        if (this.inscription?.situation_risk) {
          return this.inscription?.situation_risk;
        } else {
          return "Nenhuma situação informada";
        }
      }
    },
    changeTab(value) {
      this.$store.dispatch("PsychosocialAccompaniments/setTabPsychosocialAccompanimentType", value);
      this.backToTop();
    },
  },
  computed: {
    ...mapState({
      city: (state) => state.City.city,
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      tabPsychosocialAccompanimentType: (state) =>
        state.PsychosocialAccompaniments.tabPsychosocialAccompanimentType,

      togglePsychosocialAccompanimentPersonalData: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentPersonalData,
      togglePsychosocialAccompanimentVulnerability: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentVulnerability,
      togglePsychosocialAccompanimentGuidelines: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentGuidelines,
      togglePsychosocialAccompanimentEvolution: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentEvolution,
      togglePsychosocialAccompanimentForwardings: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentForwardings,

      togglePsychosocialAccompanimentUnaccompanied: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentUnaccompanied,
    }),
    setColorPersonalData() {
      return this.tabPsychosocialAccompanimentType === "personal_data" ? "burnedYellow" : "ashes-hover";
    },
    setColorVulnerability() {
      return this.tabPsychosocialAccompanimentType === "vulnerability" ? "burnedYellow" : "ashes-hover";
    },
    setColorGuidelines() {
      return this.tabPsychosocialAccompanimentType === "guidelines" ? "burnedYellow" : "ashes-hover";
    },
    setColorEvolution() {
      return this.tabPsychosocialAccompanimentType === "evolution" ? "burnedYellow" : "ashes-hover";
    },
    setColorForwardings() {
      return this.tabPsychosocialAccompanimentType === "forwardings" ? "burnedYellow" : "ashes-hover";
    },
  },
};
</script>
