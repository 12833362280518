import { getArea, updateArea, createArea } from "../../service/AreaApi";
import Vue from "vue";

const state = {
  area: {
    id: null,
    name: "",
    neighborhoods_attributes: [],
    city_id: null,
    city: null,
    state: {
      id: null,
      name: "",
    },
  },
  insideArea: {
    id: null,
    name: "",
    neighborhoods_attributes: [],
    city_id: null,
    city: null,
    state: null,
  },
  emptyArea: {
    id: null,
    name: "",
    neighborhoods_attributes: [],
    city_id: null,
    city: null,
    state: null,
  },
  state: null,
  city: null,
  toggleModal: false,
  loading: {
    save_area: false,
    update_area: false,
  },
  errorMessages: [],
};

const mutations = {
  setName: (state, name) => {
    state.area.name = name.value;
  },
  setState: (state, value) => {
    state.area.state = value;
  },
  setCity: (state, city) => {
    state.area.city = city;
    state.area.city_id = city ? city.id : null;
  },
  setNeighborhood: (state, neighborhood) => {
    state.area.neighborhoods_attributes = neighborhood;
  },
  setArea: (state, area) => {
    state.area = Object.assign(state.area, area);
  },
  setInsideAreaMutation: (state, area) => {
    state.insideArea = Object.assign(state.insideArea, area);
  },
  clearArea: (state) => {
    state.area = Object.assign(state.area, state.emptyArea);
  },
  toggleModal: (state) => {
    state.toggleModal = !state.toggleModal;
  },
  setLoadingSaveArea: (state, save_area) => {
    state.loading.save_area = save_area;
  },
  setLoadingUpdateArea: (state, update_area) => {
    state.loading.update_area = update_area;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

const actions = {
  setName: ({ commit }, payload) => {
    commit("setName", payload);
  },
  setState: ({ commit }, payload) => {
    commit("setState", payload);
  },
  setCity: ({ commit, dispatch }, payload) => {
    commit("setCity", payload);
    dispatch("setNeighborhoods", []);
  },
  setNeighborhoods: ({ commit }, payload) => {
    commit("setNeighborhood", payload);
  },
  closeModal: ({ commit, dispatch }) => {
    commit("clearArea");
    commit("toggleModal");
    dispatch("Neighborhoods/setNeighborhoodsWithoutArea", [], { root: true });
    commit("setErrorMessagesMutation", []);
  },
  createModal: ({ commit }) => {
    commit("clearArea");
    commit("toggleModal");
  },
  editModal: ({ commit, dispatch }, payload) => {
    if (payload) commit("setArea", payload);
    commit("toggleModal");
    dispatch("Neighborhoods/setNeighborhoodsWithoutArea", [], { root: true });
  },
  setArea: ({ commit }, area) => {
    commit("setArea", area);
  },
  loadArea: ({ commit }, id) => {
    getArea(id)
      .then((response) => {
        commit("setArea", response.data.area);
        commit("setInsideAreaMutation", response.data.area);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  saveArea: ({ commit, state, dispatch }) => {
    commit("setLoadingSaveArea", true);
    createArea(state.area)
      .then((response) => {
        commit("setArea", response.data.area);
        Vue.$toast.success(response.data.message);
        dispatch("closeModal");
        dispatch("Areas/loadAreas", state.area, { root: true });
        commit("setLoadingSaveArea", false);
      })
      .catch((e) => {
        const errorDuplicatedArea = "uniq_reference_area_per_city_id";
        if (errorDuplicatedArea.includes("uniq_reference_area_per_city_id")) {
          Vue.$toast.error("Nome de Área informada já existe, por gentileza, informe outro!");
        } else {
          Vue.$toast.error("Campos obrigatórios não foram preenchidos!");
          commit("setErrorMessagesMutation", e.response.data.errors);
        }
        commit("setLoadingSaveArea", false);
      });
  },
  updateArea: ({ commit, state, dispatch }) => {
    commit("setLoadingUpdateArea", true);
    updateArea(state.area.id, state.area)
      .then((response) => {
        commit("setInsideAreaMutation", state.area);
        dispatch("closeModal");
        Vue.$toast.success(response.data.message);
        dispatch("Areas/loadAreas", null, { root: true });
        dispatch("Neighborhoods/loadNeighborhoodsWithoutArea", null, { root: true });
        commit("setLoadingUpdateArea", false);
      })
      .catch((e) => {
        Vue.$toast.error("Campos obrigatórios não foram preenchidos!");
        commit("setErrorMessagesMutation", e.response.data.errors);
        console.log("error", e.response);
        commit("setLoadingUpdateArea", false);
      });
  },
};

const getters = {
  getNeighborhoods: (state) => (neighborhoods) => {
    return state.area.id ? [...state.area.neighborhoods_attributes, ...neighborhoods] : neighborhoods;
  },
  getAreas: (state) => {
    return state.area.map((obj) => {
      return { id: obj.id, name: obj.name };
    });
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
          return error.message;
        } else {
          return null;
        }
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
