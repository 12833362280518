import {
  createProfile,
  deleteProfile,
  getMenus,
  getProfile,
  permits,
  profiles,
  sendPermits,
} from "../../service/AccessControlApi";
import router from "../../router/index";
import Vue from "vue";

const state = {
  menus: [],
  arrPermits: [],
  arrPermTmp: [],
  arrPermProf: [],
  status: false,
  profiles: [],
  profile: null,
  profileInfo: [],
  profArray: [],
  perm: {},
  profileName: "",
  modalProfileOpen: false,
  modalPerrmissionsOpen: false,
  modalConfirmDeleteProfile: false,
  profileId: 0,
  params: {
    description: null,
  },
  loading: {
    get_permits: false,
    send_permits: false,
  },
};

const mutations = {
  clearArrPermTmpMutation: (state) => {
    state.arrPermTmp = [];
  },
  toggleModalProfile: (state) => {
    state.modalProfileOpen = !state.modalProfileOpen;
  },
  toggleModalDeleteConfirmation: (state) => {
    state.modalConfirmDeleteProfile = !state.modalConfirmDeleteProfile;
  },
  setProfileNameMutation: (state, name) => {
    state.profileName = name;
  },
  profileForDeletion: (state, payload) => {
    state.profileId = payload;
  },
  tooglePermissionsMutation: (state) => {
    state.modalPerrmissionsOpen = !state.modalPerrmissionsOpen;
  },
  setSeason: (state, season) => {
    state.season = season;
  },
  setProfile: (state, profile) => {
    state.profile = profile;
  },
  setFilterDescriptionMutation: (state, description) => {
    state.params.description = description;
  },
  getProfileInfo: (state, profile) => {
    state.profileInfo = profile;
  },
  loadingMutation(state) {
    state.status = "Carregando...";
  },
  loadPermitsMutation: (state, permits) => {
    const perm = [];
    permits.forEach((element) => {
      element.permissions.forEach((ele) => {
        perm[ele.action_code] = ele.checked;
      });
    });

    const permitJSON = Object.entries(perm).map((arr) => ({
      permit: arr[0],
      value: arr[1],
    }));
    localStorage.setItem("perms", JSON.stringify(permitJSON));
  },
  loadProfPermitsMutation: (state, payload) => {
    state.arrPermits = payload;
  },
  getArrPermProfMutation: (state) => {
    return state.arrPermProf;
  },
  loadProfilesMutation: (state, profiles) => {
    state.profiles = profiles;
  },
  loadProfArray: (state, profArray) => {
    state.profArray = profArray;
  },
  setPermMutation: (state, permits) => {
    state.arrPermTmp[permits.actionCode] = !state.arrPermTmp[permits.actionCode];
  },
  setLoadingGetPermits: (state, get_permits) => {
    state.loading.get_permits = get_permits;
  },
  setLoadingSendPermits: (state, send_permits) => {
    state.loading.send_permits = send_permits;
  },
  // --> MUTATIONS - CLEARS <--
  clearQuery: (state) => {
    state.params.description = null;
    state.profiles = [];
  },
};

function getFilterParams() {
  return {
    description: state.params.description,
  };
}

const actions = {
  modalProfileOpen: ({ commit }) => {
    commit("toggleModalProfile");
  },
  openCloseDeleteProfileConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteConfirmation");
    if (state.modalConfirmDeleteProfile) {
      commit("profileForDeletion", payload.id);
    } else {
      commit("profileForDeçetion", null);
    }
  },
  deleteProfile: ({ commit, dispatch, state }) => {
    const prof_id = state.profileId;
    deleteProfile(prof_id)
      .then(() => {
        Vue.$toast.success("Perfil deletado com sucesso");
        dispatch("getProfiles");
        commit("toggleModalDeleteConfirmation");
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  setProfileName: ({ commit }, payload) => {
    commit("setProfileNameMutation", payload);
  },
  setFilterDescription: ({ commit }, payload) => {
    commit("setFilterDescriptionMutation", payload.value);
  },
  openModal: ({ commit }) => {
    commit("tooglePermissionsMutation");
  },
  openEditModalPermission: ({ commit, dispatch }, payload) => {
    commit("setProfile", payload);
    commit("tooglePermissionsMutation");
    dispatch("getPermits", payload);
  },
  openModalProfile: ({ commit, dispatch }) => {
    dispatch("Teacher/clearTeacher", null, { root: true });
    commit("toggleModalTeacher");
  },
  setPermits: ({ commit }, payload) => {
    commit("loadingMutation", true);
    commit("setPermMutation", payload);
  },
  showPermits: ({ commit }) => {
    commit("checkPermitsMutation");
  },
  async setUserMenus() {
    const auth = JSON.parse(localStorage.getItem("userData"));
    return getMenus(auth.userId);
  },
  logOutAction() {
    localStorage.clear();
    router.push({ path: "/admin/entrar" });
  },
  canAcces({ commit }, value) {
    commit("loadingMutation");
    if (localStorage.perms) {
      const permJson = JSON.parse(localStorage.perms);
      const found = permJson.find((element) => element.permit === value);
      if (found.value) {
        return found.value;
      }
    }
  },
  getProfilePermits: async ({ commit }) => {
    commit("setLoadingGetPermits", true);
    const auth = JSON.parse(localStorage.getItem("userData"));
    if (auth) {
      permits(auth.abilityProfileId)
        .then((response) => {
          commit("loadPermitsMutation", response.data);
          commit("setLoadingGetPermits", false);
        })
        .catch((e) => {
          console.log("error", e); //TODO: tratar esse error
          commit("setLoadingGetPermits", false);
        });
    }
  },
  getProfileInfo: async ({ commit }) => {
    const auth = JSON.parse(localStorage.getItem("userData"));
    getProfile(auth.abilityProfileId)
      .then((response) => {
        commit("getProfileInfo", response.data);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },
  getPermits: ({ commit }, payload) => {
    if (payload) {
      commit("setLoadingGetPermits", true);
      permits(payload.id)
        .then((response) => {
          commit("loadProfPermitsMutation", response.data);
          response.data.map((element) => {
            element.permissions.map((ele) => {
              state.arrPermTmp[ele.action_code] = ele.checked;
            });
          });
          commit("setLoadingGetPermits", false);
        })
        .catch((e) => {
          console.log("error", e); //TODO: tratar esse error
          commit("setLoadingGetPermits", false);
        });
    }
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  getProfiles({ commit }) {
    const params = getFilterParams();
    profiles(params)
      .then((response) => {
        commit("loadProfilesMutation", response.data.ability_profile);
        const arrayProf = [];
        response.data.ability_profile.map((ele) => {
          arrayProf.push({ id: ele.id, name: ele.description });
        });
        commit("loadProfArray", arrayProf);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  saveProfile: ({ dispatch, commit }) => {
    const prof = state.profileName;
    createProfile(prof)
      .then(() => {
        Vue.$toast.success("Perfil Criado com sucesso");
        dispatch("getProfiles");
        commit("toggleModalProfile");
      })
      .catch((e) => {
        console.log("error", e.response);
        Vue.$toast.error("Erro ao realizar processo!");
      });
  },
  sendPerf({ commit }, payload) {
    commit("setLoadingSendPermits", true);
    const objPermissions = [];
    Object.keys(state.arrPermTmp).forEach((key) => {
      objPermissions.push({ action_code: key, checked: state.arrPermTmp[key] });
    });
    if (payload.id) {
      sendPermits(payload.id, objPermissions)
        .then(() => {
          Vue.$toast.success("Atualizado com sucesso!");
          commit("clearArrPermTmpMutation");
          commit("tooglePermissionsMutation");
          router.go();
          commit("setLoadingSendPermits", false);
        })
        .catch((e) => {
          console.log("error", e.response);
          Vue.$toast.error("Erro ao atualizar!");
          commit("setLoadingSendPermits", false);
        });
    }
  },
};
const getters = {
  async createArrPerm(array) {
    const arrProfPermTmp = [];
    array.forEach(async (element) => {
      element.permissions.forEach(async (ele) => {
        arrProfPermTmp[ele.action_code] = ele.checked;
      });
    });
    localStorage.setItem("profilePermits", arrProfPermTmp.toString());
    this.$store.commit("loadProfPermitsMutation", arrProfPermTmp);
    return arrProfPermTmp;
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        if (field === "" || field === " " || field === undefined || field === null) {
          return error.message;
        } else {
          return null;
        }
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
