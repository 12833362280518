import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "./assets/StyleGlobal.css";
import VTooltip from "v-tooltip";
import { util_function } from "@/util_functions";
// import ScrollToBottom from "@/components/ScrollToBottom.vue";

import { VueMaskFilter } from "v-mask";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, { timeout: 5000 });
Vue.use(VTooltip);

Vue.filter("VMask", VueMaskFilter);
// Vue.component("ScrollToBottom", ScrollToBottom);

Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.prototype.$util_function = util_function;

// Vue.config.errorHandler = function (err, vm, info) {
//   console.error("Captured in Vue.config.errorHandler:", err);
//   console.error("Instance where error occurred:", vm);
//   console.error("Info about error:", info);
//
//   const componentName = vm.$options.name || vm.$options._componentTag;
//   const propsData = vm.$options.propsData;
//
//   console.error("Component Name:", componentName);
//   console.error("Props Data:", propsData);
//
//   console.error("Stack Trace:", err.stack);
// };

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
