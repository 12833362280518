import { getAxios, baseURL } from "./Api";

export async function getStudentsByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/alunos`);
}

export async function getCivilStates() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/estados_civis");
}

export async function getCompletedSeries() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/series");
}

export async function getShirtSizes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/tamanhos_blusa");
}

export async function getPantsSizes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/tamanhos_calca");
}

export async function getShoeSizes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/tamanhos_calcado");
}

export async function getAccountTypes() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/tipo_conta");
}

export async function getAccountFormats() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/formatos_conta");
}

export async function getBankNames() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/nome_banco");
}

export async function getEducationalStatus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/status_escolares");
}

export async function getQualificationStatus() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/status_qualificacao");
}

export async function getSchoolReinsertions() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/status_reinsercoes_escolares");
}

export async function getInstitutionalReferrals() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/tipos_encaminhamento_institucional");
}

export async function getMonthPregnants() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/alunos/meses_gravidez");
}
