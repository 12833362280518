import { render, staticRenderFns } from "./TransferStudentCardsTeamSource.vue?vue&type=template&id=a363ae0c&scoped=true"
import script from "./TransferStudentCardsTeamSource.vue?vue&type=script&lang=js"
export * from "./TransferStudentCardsTeamSource.vue?vue&type=script&lang=js"
import style0 from "./TransferStudentCardsTeamSource.vue?vue&type=style&index=0&id=a363ae0c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a363ae0c",
  null
  
)

export default component.exports