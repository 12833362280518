<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="spontaneous_productive_insertion.participant_status"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="spontaneous_productive_insertion.city.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getArea()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            spontaneous_productive_insertion.participant_social_name
              ? `${spontaneous_productive_insertion.participant_social_name} - ${spontaneous_productive_insertion.participant_name}`
              : spontaneous_productive_insertion.participant_name
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(spontaneous_productive_insertion.insertion_date)"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            spontaneous_productive_insertion.insertion_type
              ? spontaneous_productive_insertion.insertion_type.name
              : '-'
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            spontaneous_productive_insertion.contracting_type
              ? spontaneous_productive_insertion.contracting_type.name
              : '-'
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="spontaneous_productive_insertion"
          dispatch="SpontaneousProductiveInsertions/openEditModalAddSpontaneousProductiveInsertion"
          title="Atualizar Inserção"
        />
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="SpontaneousProductiveInsertions/openViewModal"
          :payload="spontaneous_productive_insertion"
          title="Visualizar Inserção"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="spontaneous_productive_insertion"
          dispatch="SpontaneousProductiveInsertions/toggleModalDeleteSpontaneousProductiveInsertion"
          title="Deletar Inserção"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    spontaneous_productive_insertion: {
      type: Object,
    },
  },
  created() {
    this.spontaneousProductiveInsertionAccess();
    this.spontaneousProductiveInsertionDelete();
    this.spontaneousProductiveInsertionUpdate();
    this.spontaneousProductiveInsertionShow();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    spontaneousProductiveInsertionAccess() {
      this.canAcces("spontaneous_productive_insertion_index").then((response) => {
        this.permit = response;
      });
    },
    spontaneousProductiveInsertionDelete() {
      this.canAcces("spontaneous_productive_insertion_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    spontaneousProductiveInsertionShow() {
      this.canAcces("spontaneous_productive_insertion_show").then((response) => {
        this.permitShow = response;
      });
    },
    spontaneousProductiveInsertionUpdate() {
      this.canAcces("spontaneous_productive_insertion_update").then((response) => {
        this.permitUpdate = response;
      });
    },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getArea() {
      if (
        this.spontaneous_productive_insertion.neighborhood &&
        this.spontaneous_productive_insertion.neighborhood.area
      ) {
        return this.spontaneous_productive_insertion.neighborhood.area.name;
      }

      return "Não informado";
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 10% 10% 10% 23% 9% 14% 17% 5%;
}
</style>
