import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getSeasonsForMenus } from "@/service/SeasonApi";
import { getTeamsForAdvantages } from "@/service/TeamApi";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";
import { getInscriptionsForAdvantages, getInscriptionsForExportAdvantages } from "@/service/AdvantageApi";
import { util_function } from "@/util_functions";
import { getInscriptionForGeneralMenus } from "@/service/InscriptionApi";

Vue.component("downloadExcel", JsonExcel);

const state = {
  season: null,
  user: null,

  seasons: [],
  user_areas: [],
  user_equipments: [],
  equipments: [],
  teams: [],
  inscriptions: [],
  inscriptionsForGeneralMenus: [],
  inscriptions_spreadsheet: [],

  inscriptionsTotal: 0,
  inscriptionsTotalPerPage: 10,
  inscriptionsOffset: 0,

  modalToggleExportInscriptionsSpreadsheet: false,

  hasSpreadsheetInscriptionsResponse: true,
  json_data_inscription: null,

  menuAdvantage: null,
  tabScholarshipAdvantageView: "scholarship_payment_sheet",
  tabTransportationVoucherAdvantageView: "transportation_voucher_payment_sheet",
  toggleScholarshipPaymentSheets: true,
  toggleScholarshipPaymentControls: false,
  toggleTransportationVoucherPaymentSheets: true,
  toggleTransportationVoucherPaymentControls: false,
  beneficiaries_inscriptions_status_options: [
    {
      id: 0,
      name: "Aguardando",
    },
    {
      id: 1,
      name: "Pago",
    },
    {
      id: 2,
      name: "Erro",
    },
    {
      id: 3,
      name: "Não Solicitado",
    },
    {
      id: 4,
      name: "Conta Pendente",
    },
  ],
  loading_variables: {
    load_teams: false,
    load_inscriptions: false,
  },
  params: {
    team: {
      city: null,
      area: null,
      equipment: null,
      code: null,
    },
    payment_control: {
      city: null,
      area: null,
      equipment: null,
      team: null,
      inscription: null,
      payment_sheet_type: null,
      installment: null,
      remittance: null,
      beneficiary_payment_status: null,
      reference: null,
      pending_scholarship: null,
      value: null,
    },
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    localStorage.setItem("season", JSON.stringify(season));
  },
  setUserMutation: (state, user) => {
    state.user = user;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsMutation: (state, equipments) => {
    state.equipments = equipments;
  },
  loadTeams: (state, teams) => {
    state.teams = teams;
  },
  loadInscriptions: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },

  // MUTATIONS - TOGGLES
  toggleScholarshipPaymentSheets: (state, value) => {
    state.toggleScholarshipPaymentSheets = value;
  },
  toggleScholarshipPaymentControls: (state, value) => {
    state.toggleScholarshipPaymentControls = value;
  },
  toggleTransportationVoucherPaymentSheets: (state, value) => {
    state.toggleTransportationVoucherPaymentSheets = value;
  },
  toggleTransportationVoucherPaymentControls: (state, value) => {
    state.toggleTransportationVoucherPaymentControls = value;
  },

  // MUTATIONS - TOGGLES AND MODALS [LOADINGS]
  toggleLoadingVariablesLoadTeams: (state) => {
    state.loading_variables.load_teams = !state.loading_variables.load_teams;
  },
  toggleLoadingVariablesLoadInscriptions: (state) => {
    state.loading_variables.load_inscriptions = !state.loading_variables.load_inscriptions;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportInscriptionsSpreadsheet: (state) => {
    state.modalToggleExportInscriptionsSpreadsheet = !state.modalToggleExportInscriptionsSpreadsheet;
  },

  // --> MUTATIONS - FILTER TEAMS <--
  setFilterTeamCodeMutation: (state, code) => {
    state.params.team.code = code;
  },
  setFilterTeamCityMutation: (state, city) => {
    state.params.team.city = city;
  },
  setFilterTeamAreaMutation: (state, area) => {
    state.params.team.area = area;
  },
  setFilterTeamEquipmentMutation: (state, equipment) => {
    state.params.team.equipment = equipment;
  },

  // --> MUTATIONS - FILTER PAYMENT CONTROL <--
  setFilterPaymentControlCityMutation: (state, city) => {
    state.params.payment_control.city = city;
  },
  setFilterPaymentControlAreaMutation: (state, area) => {
    state.params.payment_control.area = area;
  },
  setFilterPaymentControlEquipmentMutation: (state, equipment) => {
    state.params.payment_control.equipment = equipment;
  },
  setFilterPaymentControlTeamMutation: (state, team) => {
    state.params.payment_control.team = team;
  },
  setFilterPaymentControlInscriptionMutation: (state, inscription) => {
    state.params.payment_control.inscription = inscription;
  },
  setFilterPaymentControlInstallmentMutation: (state, installment) => {
    state.params.payment_control.installment = installment;
  },
  setFilterPaymentControlRemittanceMutation: (state, remittance) => {
    state.params.payment_control.remittance = remittance;
  },
  setFilterPaymentControlStatusMutation: (state, beneficiary_payment_status) => {
    state.params.payment_control.beneficiary_payment_status = beneficiary_payment_status;
  },
  setFilterPaymentControlReferenceMutation: (state, reference) => {
    state.params.payment_control.reference = reference;
  },
  setFilterPaymentControlPendingScholarshipMutation: (state, pending_scholarship) => {
    state.params.payment_control.pending_scholarship = pending_scholarship;
  },
  setFilterPaymentControlValueMutation: (state, value) => {
    state.params.payment_control.value = value;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setInscriptionsTotalPerPage: (state, inscriptionsTotalPerPage) => {
    state.inscriptionsTotalPerPage = inscriptionsTotalPerPage;
  },
  setInscriptionsOffset: (state, inscriptionsOffset) => {
    state.inscriptionsOffset = inscriptionsOffset;
  },
  setInscriptionsTotal: (state, inscriptionsTotal) => {
    state.inscriptionsTotal = inscriptionsTotal;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetInscriptionsResponseMutation: (state, hasSpreadsheetInscriptionsResponse) => {
    state.hasSpreadsheetInscriptionsResponse = hasSpreadsheetInscriptionsResponse;
  },

  setInscriptionsPageToExportMutation: (state, payload) => {
    state.json_data_inscription = null;
    const arrayData = [];

    payload.processedInscriptions.forEach((element, index, array) => {
      let city = array[index].student.city ? array[index].student.city.name : "-";
      let area = array[index].student?.student_area_exceptions_attributes?.area
        ? array[index].student?.student_area_exceptions_attributes?.area.name
        : "-";
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let team = array[index].team ? array[index].team : "-";
      let name = array[index].student.name ? array[index].student.name : "-";
      let social_name = array[index].student.social_name ? array[index].student.social_name : "-";
      let status = array[index].status ? array[index].status.name : "-";
      let value = array[index]?.beneficiary?.value ? array[index]?.beneficiary?.value : "-";

      const arrayItem = {
        city: city,
        area: area,
        equipment: equipment,
        team: team,
        social_name: social_name,
        name: name,
        status: status,
        value: value,
      };

      if (payload.payment_sheet_type === 0) {
        arrayItem["installment"] = array[index]?.beneficiary?.payment_sheet?.installment
          ? array[index]?.beneficiary?.payment_sheet?.installment.name
          : "-";
        arrayItem["remittance"] = array[index]?.beneficiary?.payment_sheet?.remittance
          ? array[index]?.beneficiary?.payment_sheet?.remittance.name
          : "-";
      } else {
        arrayItem["reference"] = array[index]?.beneficiary?.payment_sheet?.reference
          ? array[index]?.beneficiary?.payment_sheet?.reference
          : "-";
      }

      arrayData.push(arrayItem);
    });

    state.json_data_inscription = arrayData;
    state.hasSpreadsheetInscriptionsResponse = true;
    state.modalToggleExportInscriptionsSpreadsheet = true;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setScholarshipTabAdvantageViewMutation: (state, type) => {
    state.tabScholarshipAdvantageView = type;
  },
  setTransportationVoucherTabAdvantageViewMutation: (state, type) => {
    state.tabTransportationVoucherAdvantageView = type;
  },
  setMenuAdvantageViewMutation: (state, menu) => {
    state.menuAdvantage = menu;
  },
  setInscriptionsSpreadsheetMutation: (state, inscriptions_spreadsheet) => {
    state.inscriptions_spreadsheet = inscriptions_spreadsheet;
  },

  clearTeams: (state) => {
    state.teams = [];
  },
  clearInscriptions: (state) => {
    state.params.payment_control.city = null;
    state.params.payment_control.area = null;
    state.params.payment_control.equipment = null;
    state.params.payment_control.team = null;
    state.params.payment_control.inscription = null;
    state.params.payment_control.installment = null;
    state.params.payment_control.remittance = null;
    state.params.payment_control.beneficiary_payment_status = null;
    state.params.payment_control.reference = null;
    state.params.payment_control.pending_scholarship = null;
    state.params.payment_control.value = null;
    state.inscriptions = [];
  },
};

function getFilterParamsForLoadTeams(rootState) {
  return {
    payment_sheet_id: rootState.PaymentSheets.payment_sheet?.id,
    team_students_ids: rootState.Beneficiaries.all_teams_student_ids,
    code: state.params.team.code,
    city: state.params.team.city ? state.params.team.city.id : null,
    area: state.params.team.area ? state.params.team.area.id : null,
    season_id: state.season ? state.season.id : null,
    equipment: state.params.team.equipment ? state.params.team.equipment.id : null,
    in_progress: true,
  };
}

function getFilterParamsForLoadInscriptions(rootState) {
  return {
    payment_sheet_type: rootState.PaymentSheets.payment_sheet?.payment_sheet_type,
    season_id: state.season ? state.season.id : null,
    name: util_function.formatNameForFilter(
      state.params.payment_control.inscription ? state.params.payment_control.inscription.name : null
    ),
    reference:
      state.params.payment_control.reference !== null && state.params.payment_control.reference !== ""
        ? state.params.payment_control.reference
        : null,
    value:
      state.params.payment_control.value !== null && state.params.payment_control.value !== ""
        ? state.params.payment_control.value
        : null,
    city_id: state.params.payment_control.city ? state.params.payment_control.city.id : null,
    area_id: state.params.payment_control.area ? state.params.payment_control.area.id : null,
    equipment_id: state.params.payment_control.equipment ? state.params.payment_control.equipment.id : null,
    team_id: state.params.payment_control.team ? state.params.payment_control.team.id : null,
    beneficiary_payment_status: state.params.payment_control.beneficiary_payment_status
      ? state.params.payment_control.beneficiary_payment_status.id
      : null,
    installment: state.params.payment_control.installment
      ? state.params.payment_control.installment.id
      : null,
    remittance: state.params.payment_control.remittance ? state.params.payment_control.remittance.id : null,
    pending_scholarship: state.params.payment_control.pending_scholarship
      ? state.params.payment_control.pending_scholarship.id
      : null,
    perPage: state.inscriptionsTotalPerPage,
    offset: state.inscriptionsOffset,
  };
}

function getUserAndEquipments() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }
  if (state.user_equipments_filtered) {
    user_equipments_ids = state.user_equipments_filtered.id;
  }
  return { user_areas_ids, user_equipments_ids };
}

function processInscriptions(inscriptions, total) {
  const processedInscriptions = [];
  let newTotal = total - (inscriptions?.length || 0);

  inscriptions.forEach((inscription) => {
    const baseData = {
      id: inscription.id,
      neighborhood: inscription.neighborhood,
      equipment: inscription.equipment,
      student: inscription.student,
    };

    if (inscription.beneficiaries && inscription.beneficiaries.length > 0) {
      inscription.beneficiaries.forEach((beneficiary) => {
        processedInscriptions.push({
          ...baseData,
          beneficiary: beneficiary,
          team: beneficiary?.team,
          status: beneficiary.status,
        });
      });
    } else {
      const status = inscription.student.account ? "Não Solicitado" : "Conta Pendente";
      processedInscriptions.push({
        ...baseData,
        beneficiary: null,
        team: inscription.team,
        status: status,
      });
    }
  });
  newTotal = newTotal + processedInscriptions?.length;

  return { processedInscriptions, newTotal };
}

const actions = {
  // --> DATA COMING FROM API
  loadSeasons: ({ commit }) => {
    getSeasonsForMenus()
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadTeams: ({ commit, rootState }) => {
    commit("toggleLoadingVariablesLoadTeams");
    const params = getFilterParamsForLoadTeams(rootState);
    getTeamsForAdvantages(params)
      .then((response) => {
        commit("loadTeams", response.data.teams);
        commit("toggleLoadingVariablesLoadTeams");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        commit("toggleLoadingVariablesLoadTeams");
      });
  },
  loadInscriptionsForGeneralMenus: ({ commit }, value) => {
    let { user_areas_ids } = getUserAndEquipments();
    const params = {
      participant_name: value,
      area_id: user_areas_ids,
      season_id: state.season ? state.season.id : null,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForGeneralMenus(params)
      .then((response) => {
        commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInscriptionsForPaymentControl: ({ commit, rootState }) => {
    commit("toggleLoadingVariablesLoadInscriptions");
    const params = getFilterParamsForLoadInscriptions(rootState);
    getInscriptionsForAdvantages(params)
      .then((response) => {
        const { processedInscriptions, newTotal } = processInscriptions(
          response.data.inscriptions,
          response.data.inscriptionsTotal
        );
        commit("setInscriptionsTotal", newTotal);
        commit("loadInscriptions", processedInscriptions);
        commit("toggleLoadingVariablesLoadInscriptions");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        commit("toggleLoadingVariablesLoadInscriptions");
      });
  },
  loadInscriptionsForExportPaymentControl: ({ commit, rootState }) => {
    commit("hasSpreadsheetInscriptionsResponseMutation", false);
    const params = getFilterParamsForLoadInscriptions(rootState);
    getInscriptionsForExportAdvantages(params)
      .then(async (response) => {
        const { processedInscriptions } = processInscriptions(response.data.inscriptions);
        commit("setInscriptionsSpreadsheetMutation", processedInscriptions);
        if (processedInscriptions.length > 0) {
          commit("setInscriptionsPageToExportMutation", {
            processedInscriptions: processedInscriptions,
            payment_sheet_type: rootState.PaymentSheets.payment_sheet.payment_sheet_type,
          });
        } else {
          commit("hasSpreadsheetInscriptionsResponseMutation", true);
          commit("toggleModalToggleExportInscriptionsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log(e);
        commit("hasSpreadsheetInscriptionsResponseMutation", true);
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      area_id: state.params.payment_control?.area?.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        commit("setEquipmentsMutation", response.data.equipment);
        commit("setFilterPaymentControlEquipmentMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },

  // --> ACTIONS - SETTING TABS <--
  setScholarshipTabAdvantageView: ({ commit }, payload) => {
    commit("setScholarshipTabAdvantageViewMutation", payload);
    commit("loadInscriptions", []);
    switch (payload) {
      case "scholarship_payment_sheet":
        commit("toggleScholarshipPaymentSheets", true);
        commit("toggleScholarshipPaymentControls", false);
        break;
      case "scholarship_payment_control":
        commit("toggleScholarshipPaymentSheets", false);
        commit("toggleScholarshipPaymentControls", true);
        break;
    }
  },
  setTransportationVoucherTabAdvantageView: ({ commit }, payload) => {
    commit("setTransportationVoucherTabAdvantageViewMutation", payload);
    commit("loadInscriptions", []);
    switch (payload) {
      case "transportation_voucher_payment_sheet":
        commit("toggleTransportationVoucherPaymentSheets", true);
        commit("toggleTransportationVoucherPaymentControls", false);
        break;
      case "transportation_voucher_payment_control":
        commit("toggleTransportationVoucherPaymentSheets", false);
        commit("toggleTransportationVoucherPaymentControls", true);
        break;
    }
  },

  // --> ACTIONS - OTHER ACTIONS <--
  setSeason: ({ commit, dispatch }, payload) => {
    commit("setSeasonMutation", payload);
    dispatch("PaymentSheets/setSeason", payload, { root: true });
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },

  // --> ACTIONS - FILTER TEAMS <--
  setFilterTeamCode: ({ commit }, payload) => {
    commit("setFilterTeamCodeMutation", payload.value);
  },
  setFilterTeamCity: ({ commit }, payload) => {
    commit("setFilterTeamCityMutation", payload);
  },
  setFilterTeamArea: ({ commit }, payload) => {
    commit("setFilterTeamAreaMutation", payload);
  },
  setFilterTeamEquipment: ({ commit }, payload) => {
    commit("setFilterTeamEquipmentMutation", payload);
  },

  // --> ACTIONS - FILTER PAYMENT CONTROL <--
  setFilterPaymentControlCity: ({ commit }, payload) => {
    commit("setFilterPaymentControlCityMutation", payload);
  },
  setFilterPaymentControlArea: ({ commit }, payload) => {
    commit("setFilterPaymentControlAreaMutation", payload);
  },
  setFilterPaymentControlEquipment: ({ commit }, payload) => {
    commit("setFilterPaymentControlEquipmentMutation", payload);
  },
  setFilterPaymentControlTeam: ({ commit }, payload) => {
    commit("setFilterPaymentControlTeamMutation", payload);
  },
  setFilterPaymentControlInscription: ({ commit }, payload) => {
    commit("setFilterPaymentControlInscriptionMutation", payload);
  },
  setFilterPaymentControlInstallment: ({ commit }, payload) => {
    commit("setFilterPaymentControlInstallmentMutation", payload);
  },
  setFilterPaymentControlRemittance: ({ commit }, payload) => {
    commit("setFilterPaymentControlRemittanceMutation", payload);
  },
  setFilterPaymentControlStatus: ({ commit }, payload) => {
    commit("setFilterPaymentControlStatusMutation", payload);
  },
  setFilterPaymentControlReference: ({ commit }, payload) => {
    commit("setFilterPaymentControlReferenceMutation", payload.value);
  },
  setFilterPaymentControlValue: ({ commit }, payload) => {
    commit("setFilterPaymentControlValueMutation", payload.value);
  },
  setFilterPaymentControlPendingScholarship: ({ commit }, payload) => {
    commit("setFilterPaymentControlPendingScholarshipMutation", payload);
  },

  // --> ACTIONS - PAGINATIONS [INSCRIPTIONS] <--
  setInscriptionsTotalPerPage: ({ commit }, payload) => {
    commit("setInscriptionsTotalPerPage", payload.totalPerPage);
  },
  setInscriptionsOffset: ({ commit }, payload) => {
    commit("setInscriptionsOffset", payload.offset);
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportInscriptionsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportInscriptionsSpreadsheet");
  },
  closeExportInscriptionsSpreadsheetModal: ({ commit, dispatch }) => {
    commit("toggleModalToggleExportInscriptionsSpreadsheet");
    dispatch("SelectFieldsToExport/clearFields", null, { root: true });
  },

  clearTeams: ({ commit }) => {
    commit("clearTeams");
  },
  clearInscriptions: ({ commit }) => {
    commit("clearInscriptions");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
