<template>
  <div class="loader-container" :style="{ backgroundColor: bg, opacity: opacity / 100 }">
    <loader
      :object="object"
      :color1="color1"
      :color2="color2"
      :size="size"
      :speed="speed"
      :objectbg="objectbg"
      :name="name"
    ></loader>
    <p v-if="text" class="loader-text" :style="{ color: textColor }">{{ animatedText }}</p>
  </div>
</template>

<script>
export default {
  props: {
    object: {
      type: String,
      default: "#ff9633",
    },
    color1: {
      type: String,
      default: "#ffffff",
    },
    color2: {
      type: String,
      default: "#17fd3d",
    },
    size: {
      type: String,
      default: "5",
    },
    speed: {
      type: String,
      default: "2",
    },
    bg: {
      type: String,
      default: "#343a40",
    },
    objectbg: {
      type: String,
      default: "#999793",
    },
    opacity: {
      type: String,
      default: "80",
    },
    name: {
      type: String,
      default: "circular",
    },
    text: {
      type: String,
      default: "Aguarde, carregando",
    },
    textColor: {
      type: String,
      default: "#ffffff",
    },
  },
  data() {
    return {
      animatedText: this.text,
      dotCount: 0,
    };
  },
  mounted() {
    this.startTextAnimation();
  },
  beforeDestroy() {
    clearInterval(this.animationInterval);
  },
  methods: {
    startTextAnimation() {
      this.animationInterval = setInterval(() => {
        this.dotCount = (this.dotCount + 1) % 4;
        this.animatedText = this.text + ".".repeat(this.dotCount);
      }, 500);
    },
  },
};
</script>

<style scoped>
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s ease;
}

.loader-text {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  z-index: 99991;
  position: relative;
}
</style>
