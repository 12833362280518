<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="offset + index + 1"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            beneficiary?.student?.social_name
              ? `${beneficiary?.student?.social_name} - ${beneficiary?.student?.name}`
              : beneficiary?.student?.name
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="beneficiary.student?.cpf | VMask('###.###.###-##')"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="beneficiary.value"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          v-if="!isEditingStatus"
          :text="beneficiary.status ? beneficiary.status.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
        <Multiselect
          v-if="isEditingStatus"
          :options="beneficiaries_status_options"
          :close-on-select="true"
          :clear-on-select="true"
          :multiple-option="false"
          :value="beneficiary.status"
          label="name"
          placeholder="Selecione o Status"
          :dispatch="updateBeneficiaryStatus"
        />
        <AppButtonIcon
          v-if="!isEditingStatus"
          iconName="edit"
          iconSize="16"
          @click.native="toggleEditStatus"
          title="Editar Status"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import Multiselect from "@/components/forms/Multiselect.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
    Multiselect,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
      isEditingStatus: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    beneficiary: {
      type: Object,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      offset: (state) => state.Beneficiaries.beneficiariesOffset,
      beneficiaries_status_options: (state) => state.UtilFunctions.beneficiaries_status_options,
    }),
  },
  created() {
    this.beneficiaryAccess();
    this.beneficiaryDelete();
    this.beneficiaryUpdate();
    this.beneficiaryShow();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    beneficiaryAccess() {
      this.canAcces("beneficiary_index").then((response) => {
        this.permit = response;
      });
    },
    beneficiaryDelete() {
      this.canAcces("beneficiary_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    beneficiaryShow() {
      this.canAcces("beneficiary_show").then((response) => {
        this.permitShow = response;
      });
    },
    beneficiaryUpdate() {
      this.canAcces("beneficiary_update").then((response) => {
        this.permitUpdate = response;
      });
    },
    toggleEditStatus() {
      this.isEditingStatus = !this.isEditingStatus;
    },
    updateBeneficiaryStatus(selectedStatus) {
      const payload = {
        id: this.beneficiary.id,
        status: selectedStatus,
      };
      this.$store.dispatch("Beneficiaries/updateBeneficiaryStatus", payload);
      this.isEditingStatus = false;
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 11% 22% 16% 14% 29% 7%;
}
</style>
