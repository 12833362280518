<template>
  <div class="grid auto-grid-auto p-10">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-6 my-6">
      <div class="text-ashes-hover font-medium text-2xl col-span-2">
        <Label
          text="Perfis"
          :icon="null"
          :iconType="null"
          type="text-3xl"
          weight="semibold"
          normal
          mode="normal-case"
          :class="title"
        />
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          text="Adicionar Perfil"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="AccessControl/modalProfileOpen"
          v-if="permit_create"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-6 mb-2">
      <FilterComponent>
        <div class="col-span-4">
          <BaseInput
            active
            input-name="name"
            input-type="text"
            icon-name="search"
            showIcon
            show-label
            :value="description"
            label-text="Filtrar por Descrição do Perfil"
            input-placeholder="Digite a descrição do perfil"
            dispatch="AccessControl/setFilterDescription"
          />
        </div>
      </FilterComponent>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-6 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit_list"
          id="btn-consult-profiles"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="AccessControl/getProfiles"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="profiles.length > 0">
        <a href="javascript:void(0)" @click="clearQuery()">
          <AppButtonColorful
            id="btn-consult-profiles"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-users">
      <div class="mx-6"><ProfilesCards :data="profiles" /></div>
      <ModalFormProfileVue :open="modalProfileOpen" />
      <PermissionsViewVue :open="showModalPermissions" />
      <ModalConfirmDeleteProfileViewVue :open="modalConfirmDeleteProfile" />
    </div>
    <div v-if="loading_get_permits || loading_send_permits">
      <PreLoading />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ModalConfirmDeleteProfileViewVue from "../views/ModalConfirmDeleteProfileView.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ProfilesCards from "./list/ProfilesCards.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import PermissionsViewVue from "../views/PermissionsView.vue";
import ModalFormProfileVue from "./ModalFormProfile.vue";
import FilterComponent from "@/components/FilterComponent.vue";
import Label from "@/components/Label.vue";
import PreLoading from "@/components/PreLoading.vue";

export default {
  name: "perfis",
  components: {
    FilterComponent,
    Label,
    AppButtonColorful,
    ProfilesCards,
    BaseInput,
    PermissionsViewVue,
    ModalFormProfileVue,
    ModalConfirmDeleteProfileViewVue,
    PreLoading,
  },
  data() {
    return {
      permit_create: false,
      permit_list: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      getProfiles: "AccessControl/getProfiles",
      getProfPermits: "AccessControl/getProfilePermits",
    }),
    permitCreate() {
      this.canAcces("ability_profile_create").then((resp) => {
        this.permit_create = resp;
      });
    },
    permitList() {
      this.canAcces("ability_profile_index").then((resp) => {
        this.permit_list = resp;
      });
    },
    clearQuery() {
      this.$store.dispatch("AccessControl/clearQuery");
    },
  },
  computed: {
    ...mapState({
      profiles: (state) => state.AccessControl.profiles,
      permissions: (state) => state.AccessControl.arrPermProf,
      permits: (state) => state.AccessControl.arrPermits,
      permiss: (state) => state.AccessControl.arrPermTmp,
      perm: (state) => state.AccessControl.perm,
      showModalPermissions: (state) => state.AccessControl.modalPerrmissionsOpen,
      modalProfileOpen: (state) => state.AccessControl.modalProfileOpen,
      modalConfirmDeleteProfile: (state) => state.AccessControl.modalConfirmDeleteProfile,
      description: (state) => state.AccessControl.params.description,
      loading_get_permits: (state) => state.AccessControl.loading.get_permits,
      loading_send_permits: (state) => state.AccessControl.loading.send_permits,
    }),
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
  },
  created() {
    this.$store.dispatch("AccessControl/getProfiles");
    this.permitCreate();
    this.permitList();
  },
  mounted() {
    //this.printPerm();
  },
};
</script>
<style lang=""></style>
