<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Enturmar Alunos"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Allocation/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-flow-row auto-rows-max">
        <div class="grid grid-flow-col auto-cols-max gap-10">
          <div class="self-center text-ashes-dark mt-4">
            <BaseText text="Filtrar:" typeText="custom" weight="base" :italic="false" size="sm" />
          </div>
          <div class="w-72 mt-4">
            <Multiselect
              id="filterArea"
              selectPlaceholder="Selecionar"
              label-text="Área"
              closeOnSelect
              show-label
              :multipleOption="false"
              :value="area"
              :options="user_areas"
              :dispatch="setFilterArea"
            />
          </div>
          <div class="w-96 mt-4">
            <Multiselect
              :close-on-select="true"
              show-label
              clear-on-select
              select-placeholder="Equipamento"
              :multiple-option="false"
              :value="equipment"
              :options="equipments_by_area"
              :label-text="getLabelEquipmentFilter()"
              :dispatch="setFilterEquipment"
            />
          </div>
          <div class="w-80 mt-4">
            <BaseInput
              active
              inputName=""
              inputType="text"
              iconName="search"
              showIcon
              show-label
              label-text="Nome"
              dispatch="Allocation/setFilterName"
            />
          </div>
        </div>
        <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
          <div class="mx-2">
            <AppButtonColorful
              id="btn-consult-students"
              showIcon
              iconName="search"
              text="Consultar dados"
              dispatch="Allocation/loadStudentsForAllocation"
              payload="load"
              typeButton="primary"
            />
          </div>
          <div class="mx-2 items-center justify-self-end" v-if="phaseOneStudents.length > 0">
            <a href="javascript:void(0)" @click="clearFields()">
              <AppButtonColorful
                id="btn-clean-data"
                showIcon
                iconName="remove"
                text="Limpar consulta"
                typeButton="primary"
              />
            </a>
          </div>
        </div>
        <div class="grid grid-flow-row auto-rows-max">
          <div
            :key="index"
            v-for="(student, index) in phaseOneStudents"
            class="mt-3 mr-5 app-checkbox grid grid-cols-5 border-b pb-2"
          >
            <div class="grid grid-flow-col auto-cols-max gap-6 place-items-center col-span-2">
              <div>
                <input
                  :id="checkboxId(student.name, index)"
                  type="checkbox"
                  name="students"
                  :value="student.id"
                  @click="onCheck(student, $event)"
                  :checked="student.checked"
                />
              </div>
              <div>
                <img src="../assets/images/profile-vj.png" alt="Maria Antônia Soares" class="w-16 h-16" />
              </div>
              <div>
                <BaseText
                  class="flex flex-wrap w-96"
                  :text="student.social_name ? `${student.social_name} - ${student.name}` : student.name"
                  typeText="custom"
                  weight="semibold"
                  :italic="false"
                  mode="capitalize"
                  size="lg"
                  :target="checkboxId(student.name, index)"
                />
              </div>
            </div>
            <div class="place-self-center">
              <BaseLabel
                :text="typeof student.equipment != 'undefined' ? student.equipment.name : 'SEM EQUIPAMENTO'"
              />
            </div>
            <div class="place-self-center">
              <BaseLabel :text="getShift(student)" />
            </div>
            <div class="place-self-center">
              <BaseLabel :text="student.cellphone_number" />
            </div>
          </div>
        </div>
        <div id="partners-pagination" class="mx-12 my-6 pb-3" v-if="phaseOneStudents?.length > 0">
          <BasePagination
            :total="totalPhaseOneStudents"
            :total-per-page="totalPerPage"
            dispatch="Allocation/setPhaseOneStudentsOffset"
          />
        </div>
      </div>
      <div v-if="loading_modal_allocation_students">
        <PreLoading />
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Allocation/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Enturmar"
            typeButton="success"
            dispatch="Allocation/allocatePhaseOneStudents"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseLabel from "../components/BaseLabel.vue";
import BaseText from "../components/BaseText.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions } from "vuex";
import BasePagination from "@/components/BasePagination.vue";
import PreLoading from "@/components/PreLoading.vue";
import AppButtonColorful from "@/components/AppButtonColorful.vue";

export default {
  components: {
    PreLoading,
    AppButtonColorful,
    BasePagination,
    BaseModal,
    BaseLabel,
    BaseText,
    BaseInput,
    Multiselect,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: Boolean,
  },
  computed: {
    ...mapState({
      phaseOneStudents: (state) => state.Allocation.phaseOneStudents,
      area: (state) => state.Allocation.filters.area,
      equipment: (state) => state.Allocation.filters.equipment,
      name: (state) => state.Allocation.filters.name,
      loading_modal_allocation_students: (state) => state.Allocation.loading.modalAllocationStudents,
      user_areas: (state) => state.UtilFunctions.user_areas,
      equipments_by_area: (state) => state.UtilFunctions.equipments_by_area,
      offset: (state) => state.Allocation.phaseOneStudentsOffset,
      totalPerPage: (state) => state.Allocation.phaseOneStudentsTotalPerPage,
      totalPhaseOneStudents: (state) => state.Allocation.phaseOneStudentsTotal,
      phase: (state) => state.Season.phase,
    }),
  },
  created() {
    this.$store.dispatch("Allocation/loadStudentsForAllocation");
  },
  beforeUpdate: function () {
    this.$store.dispatch("Allocation/cleanStudentsAttributesMutation");
  },
  methods: {
    ...mapActions({
      setFilterArea: "Allocation/setFilterArea",
      setFilterEquipment: "Allocation/setFilterEquipment",
    }),
    clearFields() {
      this.$store.dispatch("Allocation/clearQuery");
    },
    checkboxId(name, index) {
      return `${name}-${index}`;
    },
    onCheck(student, event) {
      const checked = event.target.checked;
      this.$store.dispatch("Allocation/toggleStudentChecked", { studentId: student.id, checked });

      if (checked) {
        this.$store.dispatch("Allocation/addStudent", student);
      } else {
        this.$store.dispatch("Allocation/removeStudent", student);
      }
    },
    getShift(student) {
      if (student.afternon_shift) {
        return "Tarde";
      }
      if (student.night_shift) {
        return "Noite";
      }
      return "Manhã";
    },
    getPicture(picture_profile) {
      return "../assets/images/" + picture_profile;
    },
    getDispatchPagination() {
      if (this.phase?.id === 1) {
        return "Alocation/loadPhaseOneStudents";
      } else if (this.phase?.id === 2) {
        return "Alocation/loadPhaseTwoStudents";
      } else if (this.phase?.id === 3) {
        return "Alocation/loadPhaseThreeStudents";
      }
    },
    getLabelEquipmentFilter() {
      return this.area ? "Filtrar por Equipamento" : "Selecione a área para listar os equipamentos";
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Allocation/loadStudentsForAllocation");
      this.backToTop();
    },
    area: function () {
      this.$store.dispatch("UtilFunctions/loadEquipments", this.area);
    },
  },
};
</script>
