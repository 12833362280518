var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',[_c('div',{staticClass:"grid grid-cols-custom items-center"},[_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.student?.city ? _vm.inscription?.student?.city?.name : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"bold","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.student?.student_area_exceptions_attributes?.area
            ? _vm.inscription?.student?.student_area_exceptions_attributes?.area?.name
            : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.equipment ? _vm.inscription?.equipment?.name : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.team ? _vm.inscription?.team : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.student?.social_name
            ? `${_vm.inscription?.student?.social_name} - ${_vm.inscription?.student?.name}`
            : _vm.inscription?.student?.name,"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.beneficiary?.value ? _vm.inscription?.beneficiary?.value : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.beneficiary?.payment_sheet?.remittance
            ? _vm.inscription?.beneficiary?.payment_sheet?.remittance?.name
            : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.inscription?.status?.name ? _vm.inscription?.status?.name : _vm.inscription?.status,"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }