<template>
  <div v-if="open && season">
    <div id="tabs-button" class="mt-3 ml-2">
      <FilterComponent>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Status"
            show-label
            clear-on-select
            select-placeholder="Status"
            :multiple-option="false"
            :value="status_student_filtered"
            :options="status_student"
            :dispatch="setFilterStatusNotReinsertedMainPage"
          />
        </div>
        <div class="col-span-3">
          <Multiselect
            :close-on-select="true"
            label-text="Filtrar por Nome Civil ou Social do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptionsForGeneralMenus"
            :dispatch="setFilterInscriptionNotReinsertedMainPage"
            load-dispatch="SchoolReinsertions/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCityNotReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Área"
            show-label
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_school_reinsertions"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipmentNotReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Modalidade de Ensino"
            show-label
            clear-on-select
            select-placeholder="Modalidade de Ensino"
            :multiple-option="false"
            :value="teaching_modality_filtered"
            :options="teaching_modalities"
            :dispatch="setFilterTeachingModalityReinsertedMainPage"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Nome da Escola"
            show-label
            clear-on-select
            select-placeholder="Nome da Escola"
            :multiple-option="false"
            :value="facility_filtered"
            :options="facilities"
            :dispatch="setFilterFacilityReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Série/Ano"
            show-label
            clear-on-select
            select-placeholder="Série/Ano"
            :multiple-option="false"
            :value="serie_filtered"
            :options="series"
            :dispatch="setFilterSerieReinsertedMainPage"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Encaminhamentos Institucionais"
            show-label
            clear-on-select
            select-placeholder="Encaminhamentos Institucionais"
            :multiple-option="false"
            :value="institutional_referral_filtered"
            :options="institutional_referrals"
            :dispatch="setFilterInstitutionalReferralNotReinsertedMainPage"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Comprovante de Matrícula"
            show-label
            clear-on-select
            select-placeholder="Comprovante de Matrícula"
            :multiple-option="false"
            :value="enrollment_proof_filtered"
            :options="enrollment_proof_options"
            :dispatch="setFilterEnrollmentProofReinsertedMainPage"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="initial_date_enrollment"
            show-label
            label-text="Data de Matrícula [Início]"
            dispatch="SchoolReinsertions/setFilterInitialDateEnrollmentReinsertedMainPage"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="final_date_enrollment"
            show-label
            label-text="Data de Matrícula [Final]"
            dispatch="SchoolReinsertions/setFilterFinalDateEnrollmentReinsertedMainPage"
          />
        </div>
      </FilterComponent>
      <div class="col-span-2 mt-3 mb-2">
        <a
          href="javascript:void(0)"
          v-if="permitExportInscriptionSpreadsheet"
          @click="exportToExcel('inscriptions')"
        >
          <img
            class="inline-block"
            src="../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha de Reinseridos"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
        <BaseModal v-if="modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet">
          <template v-slot:header>
            <div class="grid grid-cols-3">
              <div class="justify-self-start col-span-2">
                <Label
                  text="Exportar Planilha de Reinseridos"
                  :icon="null"
                  :icon-type="null"
                  type="text-2xl"
                  weight="bold"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="justify-self-end">
                <AppButtonIcon
                  dispatch="SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal"
                  forClose
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="grid grid-rows-2">
              <div
                class="grid justify-center items-center"
                v-if="school_reinsertions_inscriptions_spreadsheet.length > 0"
              >
                <Label
                  text="Planilha gerada com sucesso!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid justify-center items-center" v-else>
                <Label
                  text="Nenhum registro encontrado!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid grid-cols-2" v-if="school_reinsertions_inscriptions_spreadsheet.length > 0">
                <div class="justify-self-start">
                  <AppButtonEmpty
                    text="descartar"
                    typeButton="danger"
                    dispatch="SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal"
                  />
                </div>

                <div class="justify-self-end">
                  <download-excel
                    type="xls"
                    :meta="json_meta"
                    :fields="json_fields_inscriptions"
                    :data="json_data_school_reinsertion_inscriptions"
                    :before-finish="closeExportSchoolReinsertionsInscriptionSpreadsheetModal"
                    worksheet="Inscritos"
                    name="inscritos_reinseridos.xls"
                    :escapeCsv="false"
                  >
                    <AppButtonEmpty text="Download da planilha" typeButton="success" />
                  </download-excel>
                </div>
              </div>
            </div>
          </template>
        </BaseModal>
        <div v-if="!hasSpreadsheetSchoolReinsertionsInscriptionsResponse">
          <PreLoading />
        </div>
        <a
          href="javascript:void(0)"
          v-if="permitExportSchoolReinsertionSpreadsheet"
          @click="exportToExcel('school_reinsertions')"
        >
          <img
            class="inline-block"
            src="../../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar Planilha de Atividades"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
        <BaseModal v-if="modalToggleExportSchoolReinsertionsEvolutionSpreadsheet">
          <template v-slot:header>
            <div class="grid grid-cols-3">
              <div class="justify-self-start col-span-2">
                <Label
                  text="Exportar Planilha de Atividades"
                  :icon="null"
                  :icon-type="null"
                  type="text-2xl"
                  weight="bold"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="justify-self-end">
                <AppButtonIcon
                  dispatch="SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                  forClose
                />
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div class="grid grid-rows-2">
              <div
                class="grid justify-center items-center"
                v-if="school_reinsertions_evolution_spreadsheet.length > 0"
              >
                <Label
                  text="Planilha gerada com sucesso!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid justify-center items-center" v-else>
                <Label
                  text="Nenhum registro encontrado!"
                  :icon="null"
                  :icon-type="null"
                  type="text-2x1"
                  weight="normal"
                  normal
                  mode="normal-case"
                  class="text-ashes-dark"
                />
              </div>
              <div class="grid grid-cols-2" v-if="school_reinsertions_evolution_spreadsheet.length > 0">
                <div class="justify-self-start">
                  <AppButtonEmpty
                    text="descartar"
                    typeButton="danger"
                    dispatch="SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                  />
                </div>

                <div class="justify-self-end">
                  <download-excel
                    type="xls"
                    :meta="json_meta"
                    :fields="json_fields"
                    :data="json_data_school_reinsertion_evolution"
                    :before-finish="closeExportSchoolReinsertionsEvolutionSpreadsheetModal"
                    worksheet="Atividades"
                    name="atividades_por_inscricao.xls"
                    :escapeCsv="false"
                  >
                    <AppButtonEmpty text="Download da planilha" typeButton="success" />
                  </download-excel>
                </div>
              </div>
            </div>
          </template>
        </BaseModal>
        <div v-if="!hasSpreadsheetSchoolReinsertionsEvolutionResponse">
          <PreLoading />
        </div>
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-inscriptions"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="SchoolReinsertions/loadInscriptions"
          :payload="payload_load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocials">
      <div class="mx-12">
        <SchoolReinsertionReinsertedsCards :data="inscriptions" />
      </div>
      <div id="school-reinsertion-pagination" class="mx-12 my-6 pb-3" v-if="inscriptions.length > 0">
        <BasePagination
          :total="inscriptionsTotal"
          :total-per-page="totalPerPage"
          dispatch="SchoolReinsertions/setOffsetReinserteds"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SchoolReinsertionReinsertedsCards from "../../list/SchoolReinsertionReinsertedsCards.vue";
import BasePagination from "../../../components/BasePagination.vue";
import AppButtonColorful from "../../../components/AppButtonColorful.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Multiselect from "../../../components/forms/Multiselect.vue";
import BaseText from "../../../components/BaseText.vue";
import BaseModal from "../../../components/BaseModal.vue";
import Label from "../../../components/Label.vue";
import PreLoading from "../../../components/PreLoading.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    AppButtonColorful,
    SchoolReinsertionReinsertedsCards,
    Multiselect,
    BaseInput,
    BaseText,
    BaseModal,
    Label,
    PreLoading,
    AppButtonEmpty,
    AppButtonIcon,
  },
  data() {
    return {
      currentPage: 1,
      status_student: [
        {
          id: 0,
          name: "Desligado(a)",
        },
        {
          id: 3,
          name: "Ativo(a)",
        },
        {
          id: 5,
          name: "Concludente",
        },
      ],
      payload_load: {
        type: "reinserteds",
        data: "load_data",
      },
      permitExportInscriptionSpreadsheet: false,
      permitExportSchoolReinsertionSpreadsheet: false,
      json_fields: {
        "Nome Social": "student_social_name",
        Nome: "student_name",
        "Tipo da Atividade": "activity_type",
        "Tipo do Resultado": "result_type",
        "Data da Atividade": "reinsertion_date",
        "Data de Entrega da Carta": "letter_delivery_date",
        "Articulador(a) Responsável": "articulator",
        "Razão para Não Reinserção": "reasons_for_non_reinsertion",
        Encaminhamentos: "forwardings",
        Observações: "observations",
        Escola: "facility",
        Turno: "shift",
        "Ano/Série": "serie",
        "Dependência Administrativa": "administrative_dependency",
        "Modalidade de Ensino": "teaching_modality",
        "Data da Matrícula": "enrollment_date",
      },
      json_fields_inscriptions: {
        Status: "status",
        Área: "area",
        Equipamento: "equipment",
        "Nome Social": "social_name",
        Nome: "name",
        "Última série concluída": "completed_series",
        "Última escola em que estudou": "last_school_studied",
        "Ano da última matrícula": "year_of_last_enrollment",
        "Data da Matrícula": "enrollment_date",
        "Nome da Escola": "facility",
        "Modalidade de Ensino": "teaching_modality",
        "Ano/Série": "serie",
        "Dependência Administrativa": "administrative_dependency",
        "Comprovante de Matrícula": "declaration_document_id",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setUserAreasFiltered: "SchoolReinsertions/setUserAreasFiltered",
      setFilterStatusNotReinsertedMainPage: "SchoolReinsertions/setFilterStatusNotReinsertedMainPage",
      setFilterStateNotReinsertedMainPage: "SchoolReinsertions/setFilterStateNotReinsertedMainPage",
      setFilterCityNotReinsertedMainPage: "SchoolReinsertions/setFilterCityNotReinsertedMainPage",
      setFilterInscriptionNotReinsertedMainPage:
        "SchoolReinsertions/setFilterInscriptionNotReinsertedMainPage",
      setFilterEquipmentNotReinsertedMainPage: "SchoolReinsertions/setFilterEquipmentNotReinsertedMainPage",
      setFilterTeachingModalityReinsertedMainPage:
        "SchoolReinsertions/setFilterTeachingModalityReinsertedMainPage",
      setFilterEnrollmentProofReinsertedMainPage:
        "SchoolReinsertions/setFilterEnrollmentProofReinsertedMainPage",
      setFilterFacilityReinsertedMainPage: "SchoolReinsertions/setFilterFacilityReinsertedMainPage",
      setFilterSerieReinsertedMainPage: "SchoolReinsertions/setFilterSerieReinsertedMainPage",
      setFilterArticulatorNotReinsertedMainPage:
        "SchoolReinsertions/setFilterArticulatorNotReinsertedMainPage",
      setFilterInstitutionalReferralNotReinsertedMainPage:
        "SchoolReinsertions/setFilterInstitutionalReferralNotReinsertedMainPage",
      closeExportSchoolReinsertionsEvolutionSpreadsheetModal:
        "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
      closeExportSchoolReinsertionsInscriptionSpreadsheetModal:
        "SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal",
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered
        ? "Filtrar por Equipamento"
        : "Selecione a área para listar os equipamentos";
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    permitExportInscriptionSpreadsheetFunc() {
      this.canAcces("export_inscriptions_school_reinsertions_spreadsheet").then((response) => {
        this.permitExportInscriptionSpreadsheet = response;
      });
    },
    permitExportSchoolReinsertionSpreadsheetFunc() {
      this.canAcces("school_reinsertion_filter_by_inscription_spreadsheet").then((response) => {
        this.permitExportSchoolReinsertionSpreadsheet = response;
      });
    },
    async clearFields() {
      await this.$store.dispatch("SchoolReinsertions/clearQueryNotReinserteds");
    },
    async exportToExcel(type) {
      if (type === "school_reinsertions") {
        await this.$store.dispatch(
          "SchoolReinsertions/loadSchoolReinsertionsByInscription",
          "loadAllDataSpreadsheet"
        );
      } else if (type === "inscriptions") {
        this.$store.dispatch("SchoolReinsertions/loadInscriptions", {
          type: "reinserteds",
          data: "spreadsheet",
        });
      }
    },
  },
  async created() {
    this.permitExportInscriptionSpreadsheetFunc();
    this.permitExportSchoolReinsertionSpreadsheetFunc();
    await this.$store.dispatch("SchoolReinsertions/loadArticulators");
    await this.$store.dispatch("SchoolReinsertions/clearQueryNotReinserteds");
  },

  computed: {
    ...mapState({
      season: (state) => state.SchoolReinsertions.season,
      articulators: (state) => state.SchoolReinsertions.articulators,
      institutional_referrals: (state) => state.SchoolReinsertions.institutional_referrals,
      institutional_referral_filtered: (state) =>
        state.SchoolReinsertions.params.not_reinserted_main_page.institutional_referral,
      teaching_modalities: (state) => state.SchoolReinsertions.teaching_modalities,
      teaching_modality_filtered: (state) =>
        state.SchoolReinsertions.params.reinserted_main_page.teaching_modality,
      enrollment_proof_options: (state) => state.SchoolReinsertions.enrollment_proof_options,
      enrollment_proof_filtered: (state) =>
        state.SchoolReinsertions.params.reinserted_main_page.enrollment_proof,
      facilities: (state) => state.SchoolReinsertions.facilities,
      facility_filtered: (state) => state.SchoolReinsertions.params.reinserted_main_page.facility,
      series: (state) => state.SchoolReinsertions.series,
      serie_filtered: (state) => state.SchoolReinsertions.params.reinserted_main_page.serie,
      initial_date_enrollment: (state) =>
        state.SchoolReinsertions.params.reinserted_main_page.initial_date_enrollment,
      final_date_enrollment: (state) =>
        state.SchoolReinsertions.params.reinserted_main_page.final_date_enrollment,
      inscriptions: (state) => state.SchoolReinsertions.inscriptions,
      user_areas: (state) => state.SchoolReinsertions.user_areas,
      user_areas_filtered: (state) => state.SchoolReinsertions.user_areas_filtered,
      status_student_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.status,
      user_equipments_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.equipment,
      equipments_school_reinsertions: (state) => state.SchoolReinsertions.equipments_school_reinsertions,
      articulator_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.articulator,
      offset: (state) => state.SchoolReinsertions.offset,
      offsetReinserteds: (state) => state.SchoolReinsertions.offsetReinserteds,
      totalPerPage: (state) => state.SchoolReinsertions.totalPerPage,
      inscriptionsTotal: (state) => state.SchoolReinsertions.inscriptionsTotal,
      school_reinsertions: (state) => state.SchoolReinsertions.school_reinsertions,
      school_reinsertions_evolution_spreadsheet: (state) =>
        state.SchoolReinsertions.school_reinsertions_evolution_spreadsheet,
      hasSpreadsheetSchoolReinsertionsEvolutionResponse: (state) =>
        state.SchoolReinsertions.hasSpreadsheetSchoolReinsertionsEvolutionResponse,
      modalToggleExportSchoolReinsertionsEvolutionSpreadsheet: (state) =>
        state.SchoolReinsertions.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet,
      json_data_school_reinsertion_evolution: (state) =>
        state.SchoolReinsertions.json_data_school_reinsertion_evolution,
      inscriptionsForGeneralMenus: (state) => state.SchoolReinsertions.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.inscription,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
      city: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.city,
      state: (state) => state.SchoolReinsertions.params.not_reinserted_main_page.state,

      school_reinsertions_inscriptions_spreadsheet: (state) =>
        state.SchoolReinsertions.school_reinsertions_inscriptions_spreadsheet,
      hasSpreadsheetSchoolReinsertionsInscriptionsResponse: (state) =>
        state.SchoolReinsertions.hasSpreadsheetSchoolReinsertionsInscriptionsResponse,
      modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet: (state) =>
        state.SchoolReinsertions.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet,
      json_data_school_reinsertion_inscriptions: (state) =>
        state.SchoolReinsertions.json_data_school_reinsertion_inscriptions,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("SchoolReinsertions/loadEquipments");
    },
    offsetReinserteds: function () {
      this.$store.dispatch("SchoolReinsertions/loadInscriptions", {
        type: "reinserteds",
        data: null,
      });
      this.backToTop();
    },
  },
};
</script>
