<template>
  <div class="p-6 bg-gray-50 rounded-lg shadow-md">
    <div v-if="hasTargetTeams" class="grid grid-cols-2 items-center mb-4">
      <div class="ml-20">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div>
        <div class="grid grid-cols-2">
          <div class="justify-self-end">
            <BaseText
              text="Nº de Estudantes"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="quantum"
            />
          </div>
          <div class="justify-self-center">
            <BaseText
              text="Período"
              typeText="custom"
              weight="medium"
              :italic="false"
              mode="uppercase"
              size="xs"
              color="quantum"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!hasTargetTeams" class="bg-yellow-100 p-4 rounded-md mb-4 text-yellow-800">
      <AppTextField
        text="Não há turmas de destino disponíveis / elegíveis para transferência nesta fase para esse aluno (a)."
        type-text="topic-details"
      />
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" v-if="hasTargetTeams">
      <li :key="item.id" :data-index="index" v-for="(item, index) in data" class="mb-4">
        <BaseCard :class="liClass" v-if="checkTeamCode2(item)">
          <div class="grid grid-cols-2 items-center pt-2">
            <div>
              <div class="flex justify-start items-center">
                <div class="mr-10">
                  <input
                    :id="item.code"
                    type="radio"
                    name="transferStudentTargetTeam"
                    :value="item.id"
                    @click="onCheck(item)"
                    :disabled="!isValidClosingTeam(item) || !isTeamInProgress(item)"
                    class="cursor-pointer"
                  />
                </div>
                <div :class="getCSS(item)">
                  <AppMaterialIcon
                    iconName="book"
                    iconType="outlined"
                    class="place-self-center text-white"
                    iconSize="36"
                    :color="getColorFields(item)"
                  />
                </div>
                <BaseText
                  :text="item.code"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="base"
                  class="mr-1"
                  :color="getColorFields(item)"
                />
                <BaseText
                  text="|"
                  typeText="custom"
                  weight="normal"
                  size="2xl"
                  class="mr-1"
                  :color="getColorFields(item)"
                />
                <BaseText
                  :text="item.discipline.name + ` ${isTeamFinishedMessage(item)}`"
                  typeText="custom"
                  mode="underline"
                  weight="bold"
                  size="base"
                  class="mr-1"
                  :color="getColorFields(item)"
                />
              </div>
            </div>
            <div class="justify-self-end">
              <div class="grid grid-cols-2">
                <div>
                  <BaseText
                    :text="`${item.qtd_students}`"
                    typeText="custom"
                    size="sm"
                    :color="getColorItemClosing(item)"
                  />
                </div>
                <div>
                  <BaseText
                    :text="`${item.start} - ${item.closing}`"
                    typeText="custom"
                    size="sm"
                    :color="getColorItemClosing(item)"
                  />
                </div>
              </div>
            </div>
          </div>
        </BaseCard>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import BaseCard from "../../components/BaseCard.vue";
import { mapState } from "vuex";
import AppTextField from "@/components/AppTextField.vue";

export default {
  components: {
    AppTextField,
    BaseText,
    BaseStaggeredFade,
    AppMaterialIcon,
    BaseCard,
  },
  computed: {
    ...mapState({
      loading: (state) => state.ModalExportTeamPresence.loading,
      inscription: (state) => state.Inscription,
      inscriptions: (state) => state.Season.inscriptions,
      teams: (state) => state.Teams.filteredAll,
      inscriptionFiltered: (state) => state.Season.inscriptionFiltered,
      phase_to_transfer_remove_between_teams: (state) => state.Season.phase_to_transfer_remove_between_teams,
      teamsAttributes: (state) => state.Student.teams_attributes,
      hasTargetTeams: (state) => state.Season.hasTargetTeams,
      isLoadedTeam: (state) => state.Season.isLoadedTeam,
    }),
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
      };
    },
  },
  props: {
    data: {
      type: Array,
    },
  },
  methods: {
    getCSS(value) {
      let css = [];
      let bgColor = "";
      if (value.discipline.id === 1) {
        bgColor = "bg-yellow-300";
      }
      if (value.discipline.id === 2) {
        bgColor = "bg-burnedYellow-classic";
      }
      if (value.discipline.id === 3) {
        bgColor = "bg-green-primary";
      }
      if (value.discipline.id === 4) {
        bgColor = "bg-blue-primary";
      }
      if (value.discipline.id === 5) {
        bgColor = "bg-rose-primary";
      }

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
    checkTeamCode(value) {
      return this.teamsAttributes.every((item) => {
        if (item.status === "active") {
          return (
            value.code !== item.code &&
            value.discipline.phase.code === this.phase_to_transfer_remove_between_teams.code
          );
        } else {
          return value.discipline.phase.code === this.phase_to_transfer_remove_between_teams.code;
        }
      });
    },
    checkTeamCode2(value) {
      return this.teamsAttributes.every((item) => {
        if (item.status === "active") {
          return (
            value.code !== item.code &&
            value.discipline.phase.code === this.phase_to_transfer_remove_between_teams.code
          );
        } else {
          return value.discipline.phase.code === this.phase_to_transfer_remove_between_teams.code;
        }
      });
    },
    calculateTargetTeams() {
      if (!this.data || this.data.length === 0) {
        this.$store.dispatch("Season/hasTargetTeams", false);
        return;
      }
      const targetTeams = this.data.some((item) => this.checkTeamCode(item));
      this.$store.dispatch("Season/hasTargetTeams", targetTeams);
    },

    onCheck(team) {
      this.$store.dispatch("Season/filterTargetTeam", team);
    },
    getColorItemClosing(item) {
      return this.isValidClosingTeam(item) ? "ashes-dark" : "carmesim";
    },
    getColorFields(item) {
      return !this.isValidClosingTeam(item) || !this.isTeamInProgress(item) ? "ashes" : "ashes-dark";
    },
    isTeamFinishedMessage(item) {
      return this.isTeamInProgress(item) ? "" : "(Turma Encerrada)";
    },
    isValidClosingTeam(item) {
      let arrDataExclusao = item.closing.split("/");
      let stringFormatada = arrDataExclusao[1] + "-" + arrDataExclusao[0] + "-" + arrDataExclusao[2];
      return new Date(stringFormatada) > Date.now();
    },
    isTeamInProgress(item) {
      return item.in_progress;
    },
  },
  watch: {
    phase_to_transfer_remove_between_teams: {
      async handler() {
        if (this.isLoadedTeam) {
          this.calculateTargetTeams();
        }
      },
      immediate: true,
    },
    isLoadedTeam(newValue) {
      if (newValue) {
        this.calculateTargetTeams();
      }
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 56% 18% 26% 0%;
}
</style>
