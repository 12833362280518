<template>
  <div class="font-system" v-if="permit && open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">Inserções Espontâneas</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-spontaneous_productive_insertion"
          showIcon
          iconName="add"
          text="Cadastrar Inserção"
          dispatch="SpontaneousProductiveInsertions/openModalAddSpontaneousProductiveInsertion"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status da Matrícula"
            select-placeholder="Escolha a opção para buscar"
            :multiple-option="false"
            :value="inscription_status_filtered"
            :options="incriptions_status"
            :dispatch="setFilterInscriptionStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Filtrar por Nome Civil ou Social do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptions"
            :dispatch="setFilterInscription"
            load-dispatch="SpontaneousProductiveInsertions/loadInscriptions"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            label-text="Selecionar Área"
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_spontaneous_productive_insertions"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Tipo da Contratação"
            select-placeholder="Selecione o tipo da ocupação"
            :multiple-option="false"
            :value="contracting_type_filtered"
            :options="contracting_types"
            :dispatch="setFilterContractingType"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Tipo"
            select-placeholder="Selecione o tipo"
            :multiple-option="false"
            :value="insertion_type_filtered"
            :options="insertion_types"
            :dispatch="setFilterInsertionType"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Usuário(a) responsável pelo cadastro"
            select-placeholder="Digite o nome do usuário para buscar"
            :multiple-option="false"
            :value="user_filtered"
            :options="users"
            :dispatch="setFilterUser"
            load-dispatch="SpontaneousProductiveInsertions/loadUsers"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_initial_date"
            input-type="date"
            :value="initial_date_filtered"
            show-label
            label-text="Data da Inserção - Data Inicial"
            dispatch="SpontaneousProductiveInsertions/setFilterInitialDate"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_final_date"
            input-type="date"
            :value="final_date_filtered"
            show-label
            label-text="Data da Inserção - Data Final"
            dispatch="SpontaneousProductiveInsertions/setFilterFinalDate"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="spontaneous_productive_insertions.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="
          spontaneous_productive_insertions_spreadsheet.length > 0 &&
          modalToggleExportSpontaneousProductiveInsertionsSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Inserções Espontâneas"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="SpontaneousProductiveInsertions/closeExportSpontaneousProductiveInsertionsSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="SpontaneousProductiveInsertions/closeExportSpontaneousProductiveInsertionsSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_spontaneous_productive_insertion"
                  :before-finish="closeExportSpontaneousProductiveInsertionsSpreadsheetModal"
                  worksheet="Inserções_Produtivas"
                  name="insercoes_produtivas.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetSpontaneousProductiveInsertionsResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-spontaneous_productive_insertions"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="SpontaneousProductiveInsertions/loadSpontaneousProductiveInsertions"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="spontaneous_productive_insertions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-spontaneous_productive_insertions"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-spontaneous_productive_insertions">
      <div class="mx-12">
        <SpontaneousProductiveInsertionsCards :data="spontaneous_productive_insertions" />
      </div>
      <div
        id="spontaneous_productive_insertions-pagination"
        class="mx-12 my-6 pb-3"
        v-if="spontaneous_productive_insertions.length > 0"
      >
        <BasePagination
          :total="totalSpontaneousProductiveInsertions"
          :total-per-page="totalPerPage"
          dispatch="SpontaneousProductiveInsertions/setSpontaneousProductiveInsertionsOffset"
        />
      </div>
      <ModalSpontaneousProductiveInsertion :open="modalToggleAddSpontaneousProductiveInsertion" />
      <ModalViewSpontaneousProductiveInsertionData :open="modalToggleViewSpontaneousProductiveInsertion" />
      <DeleteSpontaneousProductiveInsertionConfirmation
        :open="modalToggleDeleteSpontaneousProductiveInsertion"
      />
    </div>
  </div>
</template>

<script>
import SpontaneousProductiveInsertionsCards from "../list/SpontaneousProductiveInsertionsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalSpontaneousProductiveInsertion from "./modals/ModalSpontaneousProductiveInsertion.vue";
import ModalViewSpontaneousProductiveInsertionData from "../datas/ModalViewSpontaneousProductiveInsertionData.vue";
import DeleteSpontaneousProductiveInsertionConfirmation from "../alerts/DeleteSpontaneousProductiveInsertionConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "../../components/forms/Multiselect.vue";
import PreLoading from "@/components/PreLoading.vue";
import Label from "@/components/Label.vue";
import BaseText from "@/components/BaseText.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import BaseModal from "@/components/BaseModal.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    AppButtonIcon,
    AppButtonEmpty,
    BaseText,
    Label,
    BaseModal,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    SpontaneousProductiveInsertionsCards,
    DeleteSpontaneousProductiveInsertionConfirmation,
    BaseInput,
    ModalSpontaneousProductiveInsertion,
    ModalViewSpontaneousProductiveInsertionData,
    PreLoading,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        "Data da Inserção": "insertion_date",
        "Nome do Participante": "participant_name",
        "Nome Social": "participant_social_name",
        Status: "participant_status",
        Área: "area",
        Equipamento: "equipment",
        "Tipo da INserção": "insertion_type",
        Empresa: "company",
        "Tipo da Contratação": "contracting_type",
        "Atividade de Empreendedorismo": "entrepreneurship_activity",
        "Teve acesso a crédito para financiamento?": "had_access_credit_for_financing",
        Segmento: "segment",
        Ocupação: "occupation",
        "Tem estabelecimento?": "has_establishment",
        "Nome do estabelecimento?": "establishment_name",
        "Endereço do estabelecimento": "establishment_address",
        "Tem CNPJ?": "has_cnpj",
        "Possui relação com o segmento do curso?": "is_related_to_the_course_segment",
        Observações: "observations",
        Usuário: "user",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterInscriptionStatus: "SpontaneousProductiveInsertions/setFilterInscriptionStatus",
      setFilterOccupation: "SpontaneousProductiveInsertions/setFilterOccupation",
      setFilterInscription: "SpontaneousProductiveInsertions/setFilterInscription",
      setFilterContractingType: "SpontaneousProductiveInsertions/setFilterContractingType",
      setFilterInsertionType: "SpontaneousProductiveInsertions/setFilterInsertionType",
      setUserAreasFiltered: "SpontaneousProductiveInsertions/setUserAreasFiltered",
      setFilterEquipment: "SpontaneousProductiveInsertions/setFilterEquipment",
      setFilterUser: "SpontaneousProductiveInsertions/setFilterUser",
      setFilterCity: "SpontaneousProductiveInsertions/setFilterCity",
      setFilterState: "SpontaneousProductiveInsertions/setFilterState",
      closeExportSpontaneousProductiveInsertionsSpreadsheetModal:
        "SpontaneousProductiveInsertions/closeExportSpontaneousProductiveInsertionsSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("SpontaneousProductiveInsertions/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered ? "" : "Selecione a área para listar os equipamentos";
    },
    async exportToExcel() {
      await this.$store.dispatch(
        "SpontaneousProductiveInsertions/loadSpontaneousProductiveInsertionsForExport"
      );
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
  },

  created() {
    this.canAcces("spontaneous_productive_insertion_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("spontaneous_productive_insertion_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("spontaneous_productive_insertion_export_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    this.$store.dispatch("SpontaneousProductiveInsertions/loadContractingTypes");

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("SpontaneousProductiveInsertions/setUser", user);
    this.$store.dispatch("SpontaneousProductiveInsertions/setUserAreas", user.areas);

    const season = JSON.parse(localStorage.season);
    this.$store.dispatch("SpontaneousProductiveInsertions/setSeason", season);

    if (user.equipments.length > 0) {
      this.$store.dispatch("SpontaneousProductiveInsertions/setUserEquipments", user.equipments);
    }

    this.$store.dispatch("SpontaneousProductiveInsertions/loadInsertionTypes");
    this.$store.dispatch("SpontaneousProductiveInsertions/loadUsers");
    this.$store.dispatch("SpontaneousProductiveInsertions/loadSegments");
    this.$store.dispatch("SpontaneousProductiveInsertions/loadOccupations");
    this.$store.dispatch("SpontaneousProductiveInsertions/loadCompanies");
    this.$store.dispatch("SpontaneousProductiveInsertions/loadInscriptions");
  },

  computed: {
    ...mapState({
      spontaneous_productive_insertions: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertions,
      modalToggleAddSpontaneousProductiveInsertion: (state) =>
        state.SpontaneousProductiveInsertions.modalToggleAddSpontaneousProductiveInsertion,
      modalToggleDeleteSpontaneousProductiveInsertion: (state) =>
        state.SpontaneousProductiveInsertions.modalToggleDeleteSpontaneousProductiveInsertion,
      modalToggleViewSpontaneousProductiveInsertion: (state) =>
        state.SpontaneousProductiveInsertions.modalToggleViewSpontaneousProductiveInsertion,
      offset: (state) => state.SpontaneousProductiveInsertions.spontaneous_productive_insertionsOffset,
      totalPerPage: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertionsTotalPerPage,
      totalSpontaneousProductiveInsertions: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertionsTotal,
      spontaneous_productive_insertion: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertion,
      companies: (state) => state.SpontaneousProductiveInsertions.companies,
      company_filtered: (state) => state.SpontaneousProductiveInsertions.params.company,
      incriptions_status: (state) => state.SpontaneousProductiveInsertions.incriptions_status,
      inscription_status_filtered: (state) => state.SpontaneousProductiveInsertions.params.inscription_status,
      segments: (state) => state.SpontaneousProductiveInsertions.segments,
      segment_filtered: (state) => state.SpontaneousProductiveInsertions.params.segment,
      occupations: (state) => state.SpontaneousProductiveInsertions.occupations,
      occupation_filtered: (state) => state.SpontaneousProductiveInsertions.params.occupation,
      contracting_types: (state) => state.SpontaneousProductiveInsertions.contracting_types,
      contracting_type_filtered: (state) => state.SpontaneousProductiveInsertions.params.contracting_type,
      insertion_types: (state) => state.SpontaneousProductiveInsertions.insertion_types,
      insertion_type_filtered: (state) => state.SpontaneousProductiveInsertions.params.insertion_type,
      initial_date_filtered: (state) => state.SpontaneousProductiveInsertions.params.initial_date,
      final_date_filtered: (state) => state.SpontaneousProductiveInsertions.params.final_date,
      participant_name_filtered: (state) => state.SpontaneousProductiveInsertions.params.participant_name,
      user_areas: (state) => state.SpontaneousProductiveInsertions.user_areas,
      user_areas_filtered: (state) => state.SpontaneousProductiveInsertions.user_areas_filtered,
      equipments_spontaneous_productive_insertions: (state) =>
        state.SpontaneousProductiveInsertions.equipments_spontaneous_productive_insertions,
      user_equipments_filtered: (state) => state.SpontaneousProductiveInsertions.user_equipments_filtered,
      users: (state) => state.SpontaneousProductiveInsertions.users,
      user_filtered: (state) => state.SpontaneousProductiveInsertions.params.user,
      spontaneous_productive_insertions_spreadsheet: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertions_spreadsheet,
      hasSpreadsheetSpontaneousProductiveInsertionsResponse: (state) =>
        state.SpontaneousProductiveInsertions.hasSpreadsheetSpontaneousProductiveInsertionsResponse,
      json_data_spontaneous_productive_insertion: (state) =>
        state.SpontaneousProductiveInsertions.json_data_spontaneous_productive_insertion,
      modalToggleExportSpontaneousProductiveInsertionsSpreadsheet: (state) =>
        state.SpontaneousProductiveInsertions.modalToggleExportSpontaneousProductiveInsertionsSpreadsheet,
      inscriptions: (state) => state.SpontaneousProductiveInsertions.inscriptions,
      inscription_filtered: (state) => state.SpontaneousProductiveInsertions.params.inscription,
      state: (state) => state.SpontaneousProductiveInsertions.params.state,
      city: (state) => state.SpontaneousProductiveInsertions.params.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("SpontaneousProductiveInsertions/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("SpontaneousProductiveInsertions/loadSpontaneousProductiveInsertions");
      this.backToTop();
    },
  },
};
</script>
