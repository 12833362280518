<template>
  <div id="app" class="font-system">
    <router-view />
    <!--    <ScrollToBottom />-->
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style></style>
