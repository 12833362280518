<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="getArea()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="profile_search.student.equipment ? profile_search.student.equipment.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="profile_search.student.city ? profile_search.student.city.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="profile_search.neighborhood ? profile_search.neighborhood.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            profile_search?.student?.social_name
              ? `${profile_search?.student?.social_name} - ${profile_search?.student?.name}`
              : profile_search?.student?.name
          "
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="profile_search.student.age"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="profile_search.student.completed_series ? profile_search.student.completed_series.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="profile_search.student.course.name ? profile_search.student.course.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getForwardingQuantityStatus()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          v-if="!hasResume()"
          text="Não"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
        <AppButtonIcon
          v-else
          iconName="download"
          :payload="profile_search.resume_document_id"
          dispatch="ModalInscription/downloadResume"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
// import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";
import AppButtonIcon from "@/components/AppButtonIcon.vue";

export default {
  components: {
    AppButtonIcon,
    BaseCard,
    // AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    profile_search: {
      type: Object,
    },
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getArea() {
      if (this.profile_search.neighborhood && this.profile_search.neighborhood.area) {
        return this.profile_search.neighborhood.area.name;
      }

      return "Não informado";
    },
    getForwardingQuantityStatus() {
      if (this.profile_search.forwardings_quantity === 0) {
        return "Não";
      }

      return this.profile_search.forwardings_quantity;
    },
    hasResume() {
      return this.profile_search.resume_document_id !== null;
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 6% 13% 9% 13% 14% 5% 12% 10% 6% 4% 10%;
}
</style>
