import {
  createCompany,
  destroyCompany,
  getCompanies,
  getCompaniesForExport,
  getCompanyById,
  getCompanyModalities,
  updateCompany,
} from "@/service/CompaniesApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";

Vue.component("downloadExcel", JsonExcel);

const state = {
  company: {
    id: null,
    name: null,
    address: null,
    city_id: null,
    modality: null,
  },

  companies: [],
  companies_spreadsheet: [],
  modalities: [],

  errorMessages: [],
  companyId: null,
  city: null,
  state: null,
  total_opportunities_offered: null,
  total_vacancies_offered: null,
  total_registered_referrals: null,
  total_registered_referrals_made: null,
  modality: null,

  modalToggleAddCompany: false,
  modalToggleDeleteCompany: false,
  modalToggleViewCompany: false,
  modalToggleExportCompaniesSpreadsheet: false,

  hasSpreadsheetCompaniesResponse: true,
  json_data_company: null,

  companiesTotal: 0,
  companiesTotalPerPage: 10,
  companiesOffset: 0,

  params: {
    name: null,
    address: null,
    modality: null,
    city: null,
    state: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadCompanies: (state, companies) => {
    state.companies = companies;
  },
  loadModalities: (state, modalities) => {
    state.modalities = modalities;
  },

  // MUTATIONS - FIELDS TO/FROM API
  setIdMutation: (state, id) => {
    state.company.id = id;
  },
  setNameMutation: (state, name) => {
    state.company.name = name;
  },
  setAddressMutation: (state, address) => {
    state.company.address = address;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.company.city_id = city ? city.id : null;
  },
  setModalityMutation: (state, modality) => {
    state.modality = modality;
    state.company.modality = modality ? modality.id : null;
  },
  setTotalOpportunitiesOfferedMutation: (state, total_opportunities_offered) => {
    state.total_opportunities_offered = total_opportunities_offered;
  },
  setTotalVacanciesOfferedMutation: (state, total_vacancies_offered) => {
    state.total_vacancies_offered = total_vacancies_offered;
  },
  setTotalRegisteredReferralsMutation: (state, total_registered_referrals) => {
    state.total_registered_referrals = total_registered_referrals;
  },
  setTotalRegisteredReferralsMadeMutation: (state, total_registered_referrals_made) => {
    state.total_registered_referrals_made = total_registered_referrals_made;
  },

  // --> MUTATIONS - DELETION <--
  companyForDeletion: (state, payload) => {
    if (payload) {
      state.companyId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterAddressMutation: (state, address) => {
    state.params.address = address;
  },
  setFilterModalityMutation: (state, modality) => {
    state.params.modality = modality;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddCompany: (state) => {
    state.modalToggleAddCompany = !state.modalToggleAddCompany;
  },
  toggleModalDeleteCompany: (state) => {
    state.modalToggleDeleteCompany = !state.modalToggleDeleteCompany;
  },
  toggleModalViewCompany: (state) => {
    state.modalToggleViewCompany = !state.modalToggleViewCompany;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportCompaniesSpreadsheet: (state) => {
    state.modalToggleExportCompaniesSpreadsheet = !state.modalToggleExportCompaniesSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetCompaniesResponseMutation: (state, hasSpreadsheetCompaniesResponse) => {
    state.hasSpreadsheetCompaniesResponse = hasSpreadsheetCompaniesResponse;
  },

  setCompaniesPageToExportMutation: (state, company) => {
    state.json_data_company = null;

    const arrayData = [];
    company.forEach((element, index, array) => {
      let name = array[index].name ? array[index].name : "-";
      let address = array[index].address ? array[index].address : "-";
      let modality = array[index].modality ? array[index].modality.name : "-";
      let total_opportunities_offered =
        array[index].total_opportunities_offered !== null ? array[index].total_opportunities_offered : "-";
      let total_vacancies_offered =
        array[index].total_vacancies_offered !== null ? array[index].total_vacancies_offered : "-";
      let total_registered_referrals =
        array[index].total_registered_referrals !== null ? array[index].total_registered_referrals : "-";
      let total_registered_referrals_made =
        array[index].total_registered_referrals_made !== null
          ? array[index].total_registered_referrals_made
          : "-";

      const arrayItem = [];
      arrayItem["name"] = name;
      arrayItem["address"] = address;
      arrayItem["modality"] = modality;
      arrayItem["total_opportunities_offered"] = total_opportunities_offered;
      arrayItem["total_vacancies_offered"] = total_vacancies_offered;
      arrayItem["total_registered_referrals"] = total_registered_referrals;
      arrayItem["total_registered_referrals_made"] = total_registered_referrals_made;
      arrayData.push(arrayItem);
    });
    state.json_data_company = arrayData;
    state.hasSpreadsheetCompaniesResponse = true;
    state.modalToggleExportCompaniesSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setCompaniesTotalPerPage: (state, companiesTotalPerPage) => {
    state.companiesTotalPerPage = companiesTotalPerPage;
  },
  setCompaniesOffset: (state, companiesOffset) => {
    state.companiesOffset = companiesOffset;
  },
  setCompaniesTotal: (state, companiesTotal) => {
    state.companiesTotal = companiesTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.company) {
      state.company[prop] = null;
    }
    state.city = null;
    state.state = null;
    state.modality = null;
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.params.address = null;
    state.params.modality = null;
    state.params.city = null;
    state.params.state = null;
    state.companies = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setCompaniesSpreadsheetMutation: (state, companies_spreadsheet) => {
    state.companies_spreadsheet = companies_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams(rootGetters) {
  return {
    name: state.params.name,
    address: state.params.address,
    modality: state.params.modality ? state.params.modality.id : null,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    per_page: state.companiesTotalPerPage,
    offset: state.companiesOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadCompanies: ({ commit, rootGetters }, from) => {
    const params = getFilterParams(rootGetters);
    getCompanies(params)
      .then((response) => {
        commit("loadCompanies", response.data.companies);
        commit("setCompaniesTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCompaniesForExport: ({ commit, rootGetters }) => {
    commit("hasSpreadsheetCompaniesResponseMutation", false);
    const params = getFilterParams(rootGetters);
    getCompaniesForExport(params)
      .then(async (response) => {
        commit("setCompaniesSpreadsheetMutation", response.data.companies);
        if (state.companies_spreadsheet.length > 0) {
          await commit("setCompaniesPageToExportMutation", response.data.companies);
        } else {
          commit("hasSpreadsheetCompaniesResponseMutation", true);
          commit("toggleModalToggleExportCompaniesSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadModalities: ({ commit }) => {
    getCompanyModalities()
      .then((response) => {
        commit("loadModalities", response.data.modality);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getCompanyById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.companies.id);
        commit("setNameMutation", response.data.companies.name);
        commit("setAddressMutation", response.data.companies.address);
        commit("setCityMutation", response.data.companies.city);
        commit("setModalityMutation", response.data.companies.modality);
        commit("setTotalOpportunitiesOfferedMutation", response.data.companies.total_opportunities_offered);
        commit("setTotalVacanciesOfferedMutation", response.data.companies.total_vacancies_offered);
        commit("setTotalRegisteredReferralsMutation", response.data.companies.total_registered_referrals);
        commit(
          "setTotalRegisteredReferralsMadeMutation",
          response.data.companies.total_registered_referrals_made
        );
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setAddress: ({ commit }, payload) => {
    commit("setAddressMutation", payload.value);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setModality: ({ commit }, payload) => {
    commit("setModalityMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddCompany: async ({ commit }) => {
    commit("toggleModalAddCompany");
  },
  closeModalAddCompany: ({ commit }) => {
    commit("toggleModalAddCompany");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddCompany: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddCompany");
  },
  closeEditModalAddCompany: ({ commit }) => {
    commit("toggleModalAddCompany");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewCompany");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewCompany");
    commit("clearFields");
  },
  toggleModalDeleteCompany: ({ commit }, payload) => {
    commit("toggleModalDeleteCompany");
    if (state.modalToggleDeleteCompany && payload) {
      commit("companyForDeletion", payload);
    } else {
      commit("companyForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportCompaniesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportCompaniesSpreadsheet");
  },
  closeExportCompaniesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportCompaniesSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterAddress: ({ commit }, payload) => {
    commit("setFilterAddressMutation", payload.value);
  },
  setFilterModality: ({ commit }, payload) => {
    commit("setFilterModalityMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },

  // --> ACTIONS - PAGINATIONS <--
  setCompaniesTotalPerPage: ({ commit }, payload) => {
    commit("setCompaniesTotalPerPage", payload.totalPerPage);
  },
  setCompaniesOffset: ({ commit }, payload) => {
    commit("setCompaniesOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createCompany(state.company)
      .then(() => {
        Vue.$toast.success("Empresa cadastrada com sucesso!");
        dispatch("closeModalAddCompany");
        dispatch("loadCompanies");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a empresa! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateCompany(state.company.id, state.company)
      .then(() => {
        dispatch("loadCompanies");
        dispatch("closeEditModalAddCompany");
        Vue.$toast.success("Empresa atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a empresa! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyCompany(state.companyId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Empresa deletada com sucesso!");
        commit("toggleModalDeleteCompany");
        dispatch("loadCompanies", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
