<template>
  <BaseModal class="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg" v-if="open">
    <template v-slot:header>
      <div class="flex justify-between items-center mb-4">
        <BaseText
          :text="profile.description + ' | Perfis de Acesso'"
          typeText="custom"
          weight="semibold"
          :italic="false"
          size="3xl"
          color="text-gray-800"
        />
        <AppButtonIcon dispatch="AccessControl/openModal" forClose />
      </div>
    </template>
    <template v-slot:body>
      <div class="space-y-6">
        <div
          v-for="(itens, index) in profiles"
          :key="itens.description"
          class="bg-gray-50 p-4 rounded-lg shadow-md"
        >
          <div class="flex justify-between items-center cursor-pointer" @click="toggleExpand(index)">
            <div>
              <Label
                :text="'Recurso: ' + itens.description"
                :icon="null"
                :iconType="null"
                type="text-sm"
                weight="semibold"
                normal
                mode="uppercase"
                class="text-gray-900"
              />
              <div class="text-sm text-gray-600 mt-1">
                {{ countCheckedPermissions(itens.permissions) }} de {{ itens.permissions.length }} permissões
                liberadas
              </div>
            </div>
            <span :class="expanded[index] ? 'transform rotate-180' : 'transform rotate-0'">▼</span>
          </div>
          <div v-if="expanded[index]" class="flex flex-wrap mt-2">
            <label
              v-for="permission in itens.permissions"
              :key="permission.action_code"
              class="flex items-center mr-4 mb-2 cursor-pointer"
            >
              <input
                type="checkbox"
                :checked="permission.checked"
                @change="updatePermit(itens, permission.action_code, $event.target.checked)"
                class="mr-2"
              />
              <span class="ml-2 whitespace-nowrap text-gray-700">{{ permission.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex justify-between mt-6">
        <AppButtonEmpty text="Fechar" typeButton="danger" dispatch="AccessControl/openModal" />
        <AppButtonEmpty
          text="Editar"
          typeButton="success"
          dispatch="AccessControl/sendPerf"
          :payload="profile"
          class="mr-2"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import { mapActions, mapState } from "vuex";
import BaseText from "../components/BaseText.vue";
import Label from "../components/Label.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";

export default {
  name: "permissoes",
  components: {
    BaseText,
    Label,
    AppButtonEmpty,
    BaseModal,
    AppButtonIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    expanded: [],
  }),
  watch: {
    open(newValue) {
      if (newValue) {
        this.resetExpanded();
      }
    },
  },
  methods: {
    ...mapActions({
      getProfPermit: "AccessControl/getProfilePermits",
      getPermit: "AccessControl/getPermits",
      updateProfiles: "AccessControl/updateProfiles",
      setPermits: "AccessControl/setPermits",
    }),
    updatePermit(item, actionCode, isChecked) {
      this.setPermits({ actionCode, isChecked });
      const permission = item.permissions.find((perm) => perm.action_code === actionCode);
      if (permission) {
        permission.checked = isChecked;
      }
    },
    toggleExpand(index) {
      this.$set(this.expanded, index, !this.expanded[index]);
    },
    countCheckedPermissions(permissions) {
      return permissions.filter((permission) => permission.checked).length;
    },
    resetExpanded() {
      this.expanded = this.profiles.map(() => false);
    },
  },
  computed: {
    ...mapState({
      profiles: (state) => state.AccessControl.arrPermits,
      profile: (state) => state.AccessControl.profile,
    }),
  },
  created() {
    this.$store.dispatch("AccessControl/getProfilePermits");
    this.$store.dispatch("AccessControl/getPermits");
  },
};
</script>

<style>
.size {
  width: 100%;
  overflow: auto;
}
input[type="checkbox"] {
  accent-color: #f97316;
}
.bg-gray-50 {
  background-color: #f9fafb;
}
.text-gray-800 {
  color: #2d3748;
}
.text-gray-900 {
  color: #1a202c;
}
.text-gray-700 {
  color: #4a5568;
}
.text-gray-600 {
  color: #718096;
}
.shadow-md {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}
.rounded-lg {
  border-radius: 0.5rem;
}
</style>
