<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="team.id"
            text="Editar turma"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar turma"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Team/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-rols-7 gap-5">
        <div class="grid grid-rols-2">
          <div>
            <BaseText text="informações gerais" typeText="title-forms" />
          </div>
          <div class="border border-ashes-hover w-full"></div>
        </div>
        <div class="grid grid-flow-col gap-5">
          <div>
            <BaseText :text="season.name" typeText="custom" weight="medium" mode="capitalize" size="lg" />
          </div>
          <div>
            <BaseText :text="phase.name" typeText="custom" weight="medium" mode="capitalize" size="lg" />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div v-if="false">
            <Multiselect
              id="select-season"
              show-label
              close-on-select
              select-placeholder="Selecione as opções"
              label-text="Edições"
              required
              :clear-on-select="false"
              :multiple-option="false"
              :value="season"
              :options="seasons"
              :dispatch="setSeason"
              :error-message="getErrorMessage('season')"
            />
          </div>
          <div v-if="false">
            <Multiselect
              id="select-phase"
              show-label
              close-on-select
              select-placeholder="Selecione as opções"
              label-text="Fases"
              required
              :clear-on-select="false"
              :multiple-option="false"
              :value="team.discipline"
              :options="phases"
              :dispatch="setDiscipline"
              :error-message="getErrorMessage('discipline')"
            />
          </div>
          <div>
            <Multiselect
              id="select-module"
              show-label
              close-on-select
              select-placeholder="Selecione as opções"
              label-text="Módulos"
              required
              :clear-on-select="false"
              :multiple-option="false"
              :value="team.discipline"
              :options="getOnlyDisciplineEnableForAllocate()"
              :dispatch="setDiscipline"
              :error-message="getErrorMessage('discipline')"
            />
          </div>
          <div v-if="phase.id == 2">
            <Multiselect
              id="select-module"
              show-label
              close-on-select
              select-placeholder="Selecione as opções"
              label-text="Curso"
              required
              :clear-on-select="false"
              :multiple-option="false"
              :value="team.course"
              :options="courses"
              :dispatch="setCourse"
              :error-message="getErrorMessage('course')"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <BaseInput
              id="start"
              active
              input-name="start"
              inputType="date"
              :value="formatDate(team.start)"
              required
              label-text="Início"
              show-label
              :error-message="getErrorMessage('start')"
              dispatch="Team/setStart"
            />
          </div>
          <div>
            <BaseInput
              id="closing"
              active
              input-name="closing"
              inputType="date"
              required
              :value="formatDate(team.closing)"
              label-text="Encerramento"
              show-label
              dispatch="Team/setClosing"
              :error-message="getErrorMessage('closing')"
            />
          </div>
        </div>
        <div>
          <AppCheckBox
            :values="[
              {
                name: 'semana',
                value: 'monday',
                description: 'Segunda',
                checked: team.monday,
              },
              {
                name: 'semana',
                value: 'tuesday',
                description: 'Terça',
                checked: team.tuesday,
              },
              {
                name: 'semana',
                value: 'wednesday',
                description: 'Quarta',
                checked: team.wednesday,
              },
              {
                name: 'semana',
                value: 'thursday',
                description: 'Quinta',
                checked: team.thursday,
              },
              {
                name: 'semana',
                value: 'friday',
                description: 'Sexta',
                checked: team.friday,
              },
            ]"
            required
            :error-message="getErrorMessage('weekly_meetings')"
            label-text="Encontros na semana"
            show-label
            dispatch="Team/setDayWeek"
          />
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <Multiselect
              close-on-select
              show-label
              select-placeholder="Selecione a opção"
              label-text="Turno"
              :multiple-option="false"
              :value="team.shift"
              :options="shifts"
              :error-message="getErrorMessage('shift')"
              required
              :dispatch="setShift"
            />
          </div>
          <div>
            <Multiselect
              close-on-select
              show-label
              select-placeholder="Selecione a opção"
              label-text="Equipamento"
              :multiple-option="false"
              :value="team.equipment"
              :options="equipmentAll"
              :error-message="getErrorMessage('equipment')"
              required
              :dispatch="setEquipment"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <Multiselect
              close-on-select
              show-label
              select-placeholder="Selecione a opção"
              label-text="Instrutor"
              :multiple-option="false"
              :value="team.teacher"
              :options="teachers"
              :error-message="getErrorMessage('teacher')"
              required
              :dispatch="setTeacher"
            />
          </div>
          <div>
            <BaseInput
              active
              input-name="Vagas"
              required
              label-text="Vagas"
              show-label
              :value="String(team.vacancy)"
              :error-message="getErrorMessage('vacancy')"
              input-type="number"
              dispatch="Team/setVacancy"
              inputMin="1"
              inputMax="1000000"
            />
          </div>
          <!-- <div>
            <BaseInput
              active
              input-name="Quantidade de Encontros"
              required
              label-text="Quantidade de Encontros"
              show-label
              :value="String(team.vacancy)"
              :error-message="getErrorMessage('vacancy')"
              input-type="number"
              dispatch="Team/setVacancy"
              inputMin="1"
            />
          </div>
          <div>
            <BaseInput
              active
              input-name="Quantidade de Horas por Encontro"
              label-text="Quantidade de Horas por Encontro"
              show-label
              :value="String(team.vacancy)"
              :error-message="getErrorMessage('vacancy')"
              input-type="number"
              dispatch="Team/setVacancy"
              inputMin="1"
            />
          </div> -->
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Team/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty v-if="team.id" text="Editar" typeButton="success" dispatch="Team/updateTeam" />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Team/saveTeam" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import BaseText from "../components/BaseText.vue";
import AppCheckBox from "../components/AppCheckBox.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Multiselect,
    Label,
    BaseText,
    AppCheckBox,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
    },
  },

  created() {
    this.$store.dispatch("Team/loadEquipment", this.season.id);
    this.$store.dispatch("Team/loadShifts");
    this.$store.dispatch("Team/loadTeachers");
    this.$store.dispatch("Seasons/loadSeasons");

    this.$store.dispatch("Courses/loadCourses");
  },

  computed: {
    ...mapState({
      equipmentAll: (state) => state.Team.equipmentAll,
      shifts: (state) => state.Team.shifts,
      teachers: (state) => state.Team.teachers,
      modalTeamOpen: (state) => state.Team.modalTeamOpen,
      season: (state) => state.Season.season,
      seasons: (state) => state.Seasons.seasons,
      phase: (state) => state.Season.phase,
      phases: (state) => state.Season.phases,
      discipline: (state) => state.Team.discipline,
      team: (state) => state.Team,
      filteredSeason: (state) => state.Teams.filter.season,
      courses: (state) => state.Courses.filteredCourses,
    }),

    ...mapGetters({
      getErrorMessage: "Team/getErrorMessage",
    }),
  },

  methods: {
    ...mapActions({
      setDiscipline: "Team/setDiscipline",
      setEquipment: "Team/setEquipment",
      setTeacher: "Team/setTeacher",
      setShift: "Team/setShift",
      setCourse: "Team/setCourse",
      setSeason: "Season/setSeason",
    }),

    getOnlyDisciplineEnableForAllocate() {
      if (this.phase.id == 1)
        return this.phase.disciplines.filter((discipline) => {
          return discipline.code === "F1M1";
        });
      else {
        return this.phase.disciplines;
      }
    },

    formatDate(date) {
      return date?.split("/").reverse().join("-");
    },
  },
};
</script>
