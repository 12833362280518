<template>
  <div>
    <div class="grid grid-cols-1">
      <div
        class="justify-self-end"
        v-if="discipline.phase.id === 1 && permit && season_date_manual_execution_end"
      >
        <AppButtonColorful
          v-if="discipline.id === 1"
          text="Enturmar Alunos"
          typeButton="success"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Allocation/openModal"
        />
      </div>
      <div class="justify-self-end" v-else-if="permit && season_date_manual_execution_end">
        <AppButtonColorful
          text="Enturmar Alunos"
          typeButton="success"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Allocation/openModal"
        />
      </div>
    </div>
    <div class="grid grid-cols-6">
      <div class="col-span-3 w-100 ml-10">
        <BaseText
          text="alunos"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="w-60">
        <BaseText
          text="contato"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
      <div class="col-span-2">
        <BaseText
          text="% de Frequência"
          typeText="custom"
          weight="medium"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="quantum"
        />
      </div>
    </div>
    <AllocationsCards :data="students" />
    <ModalAllocations :open="modalOpen" />
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import AllocationsCards from "../list/AllocationsCards.vue";
import ModalAllocations from "../ModalAllocations.vue";
import { mapActions } from "vuex";

import { mapState } from "vuex";

export default {
  components: {
    AppButtonColorful,
    BaseText,
    AllocationsCards,
    ModalAllocations,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
      season_date_manual_execution_end: true,
    };
  },
  created() {
    let season = this.$store.state.Team.season;
    this.permitAllocationStudents();
    this.$store.dispatch("Season/loadSeason", season);
    this.$store.dispatch("Team/showTeam", this.$store.state.Team.id);
    this.getSeasonDateManualExecutionEnd();
  },
  computed: {
    ...mapState({
      modalOpen: (state) => state.Allocation.toggleModal,
      discipline: (state) => state.Team.discipline,
      seasonAreas: (state) => state.Season.season.areas_attributes,
      students: (state) => state.Allocation.students,
    }),
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitAllocationStudents() {
      this.canAcces("inscription_update").then((resp) => {
        this.permit = resp;
      });
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null" ? true : false;
    },
  },
};
</script>
