var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseCard',[_c('div',{staticClass:"grid grid-cols-custom items-center"},[_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.spontaneous_productive_insertion.participant_status,"mode":"normal-case","color":"quantum","typeText":"custom","weight":"bold","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.spontaneous_productive_insertion.city.name,"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.getArea(),"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.spontaneous_productive_insertion.participant_social_name
            ? `${_vm.spontaneous_productive_insertion.participant_social_name} - ${_vm.spontaneous_productive_insertion.participant_name}`
            : _vm.spontaneous_productive_insertion.participant_name,"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.formattedDate(_vm.spontaneous_productive_insertion.insertion_date),"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.spontaneous_productive_insertion.insertion_type
            ? _vm.spontaneous_productive_insertion.insertion_type.name
            : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4"},[_c('BaseText',{attrs:{"text":_vm.spontaneous_productive_insertion.contracting_type
            ? _vm.spontaneous_productive_insertion.contracting_type.name
            : '-',"mode":"normal-case","color":"quantum","typeText":"custom","weight":"normal","size":"base"}})],1),_c('div',{staticClass:"mt-4 flex"},[(_vm.permitUpdate)?_c('AppButtonIcon',{attrs:{"iconName":"edit","payload":_vm.spontaneous_productive_insertion,"dispatch":"SpontaneousProductiveInsertions/openEditModalAddSpontaneousProductiveInsertion","title":"Atualizar Inserção"}}):_vm._e(),(_vm.permitShow)?_c('AppButtonIcon',{attrs:{"iconName":"visibility","dispatch":"SpontaneousProductiveInsertions/openViewModal","payload":_vm.spontaneous_productive_insertion,"title":"Visualizar Inserção"}}):_vm._e(),(_vm.permitDelete)?_c('AppButtonIcon',{attrs:{"iconName":"delete","payload":_vm.spontaneous_productive_insertion,"dispatch":"SpontaneousProductiveInsertions/toggleModalDeleteSpontaneousProductiveInsertion","title":"Deletar Inserção"}}):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }