<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Enturmar Alunos por Turma"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="ModalAllocationTeamBenefit/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-flow-row auto-rows-max">
        <div class="grid grid-flow-col auto-cols-max gap-10">
          <div class="self-center text-ashes-dark">
            <BaseText text="Filtrar:" typeText="custom" weight="base" :italic="false" size="sm" />
          </div>
          <div>
            <Multiselect
              id="orderByArea"
              selectPlaceholder="Area"
              class="mr-5"
              closeOnSelect
              :multipleOption="false"
              :value="filters.area"
              :options="areas"
              :dispatch="filterArea"
            />
          </div>
          <div>
            <Multiselect
              id="orderByArea"
              selectPlaceholder="Bairro"
              class="mr-5"
              closeOnSelect
              :multipleOption="false"
              :value="filters.neighborhood"
              :options="neighborhoods"
              :dispatch="filterNeighborhood"
            />
          </div>
          <div>
            <Multiselect
              id="orderByArea"
              selectPlaceholder="Equipamento"
              class="mr-5"
              closeOnSelect
              :multipleOption="false"
              :value="filters.equipment"
              :options="equipment"
              :dispatch="filterEquipment"
            />
          </div>
        </div>
        <div class="grid grid-flow-row auto-rows-max">
          <BenefitsTeamsCards :data="teams" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="ModalAllocationTeamBenefit/closeModal"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Alocar"
            typeButton="success"
            dispatch="ModalAllocationTeamBenefit/addStudentForPayment"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BenefitsTeamsCards from "./list/BenefitsTeamsCards";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BenefitsTeamsCards,
    BaseModal,
    BaseText,
    Multiselect,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: Boolean,
  },
  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");
  },
  computed: {
    ...mapState({
      areas: (state) => state.Areas.areas,
      neighborhoods: (state) => state.Neighborhoods.neighborhoods,
      equipment: (state) => state.EquipmentAll.equipmentAll,
      filters: (state) => state.ModalAllocationTeamBenefit.filters,
      teams: (state) => state.ModalAllocationTeamBenefit.filteredTeams,
    }),
  },
  methods: {
    ...mapActions({
      filterArea: "ModalAllocationTeamBenefit/filterArea",
      filterNeighborhood: "ModalAllocationTeamBenefit/filterNeighborhood",
      filterEquipment: "ModalAllocationTeamBenefit/filterEquipment",
    }),
  },
};
</script>
