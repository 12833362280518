import {
  createForwardingProductiveInsertion,
  destroyForwardingProductiveInsertion,
  getForwardingProductiveInsertionById,
  getForwardingProductiveInsertionFeedbackTypes,
  getForwardingProductiveInsertions,
  getForwardingProductiveInsertionsForExport,
  updateForwardingProductiveInsertion,
} from "@/service/ForwardingProductiveInsertionsApi";
import { getCompaniesForMenus } from "@/service/CompaniesApi";
import { getPartnersForMenus } from "@/service/PartnersApi";
import { getSegmentsForMenus } from "@/service/SegmentsApi";
import { getOccupationsForMenus } from "@/service/OccupationsApi";
import { getOpportunitiesForMenus, getOpportunityContractingTypes } from "@/service/OpportunitiesApi";
import { getInscriptionForProductiveInsertion } from "@/service/InscriptionApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";
import { getUsersForProductiveInsertion } from "@/service/UsersApi";
import { getQualificationStatus } from "@/service/StudentApi";
import { util_function } from "@/util_functions";

Vue.component("downloadExcel", JsonExcel);

const state = {
  forwarding_productive_insertion: {
    id: null,
    forwarding_date: null,
    city_id: null,
    opportunity_id: null,
    inscription_id: null,
    user_id: localStorage.user_id,
    season_id: null,
    feedback_type: null,
  },
  incriptions_status: [
    {
      id: 0,
      name: "Desligado(a)",
    },
    {
      id: 3,
      name: "Ativo(a)",
    },
    {
      id: 5,
      name: "Concludente",
    },
  ],

  forwarding_productive_insertions: [],
  opportunities: [],
  inscriptions: [],
  users: [],
  feedback_types: [],
  companies: [],
  partners: [],
  segments: [],
  occupations: [],
  contracting_types: [],
  equipments_forwarding_productive_insertions: [],
  user_areas: [],
  user_equipments: [],
  qualification_statuses: [],
  forwarding_productive_insertions_spreadsheet: [],

  errorMessages: [],
  forwarding_productive_insertionId: null,
  city: null,
  state: null,
  opportunity: null,
  inscription: null,
  user: null,
  feedback_type: null,
  season: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,
  qualification_status: null,

  modalToggleAddForwardingProductiveInsertion: false,
  modalToggleDeleteForwardingProductiveInsertion: false,
  modalToggleViewForwardingProductiveInsertion: false,
  modalToggleExportForwardingProductiveInsertionsSpreadsheet: false,

  hasSpreadsheetForwardingProductiveInsertionsResponse: true,
  json_data_forwarding_productive_insertion: null,

  forwarding_productive_insertionsTotal: 0,
  forwarding_productive_insertionsTotalPerPage: 10,
  forwarding_productive_insertionsOffset: 0,

  params: {
    inscription_status: null,
    qualification_status: null,
    area: null,
    user: null,
    equipment: null,
    inscription: null,
    opportunity: null,
    partner: null,
    city: null,
    state: null,
    company: null,
    segment: null,
    occupation: null,
    contracting_type: null,
    feedback_type: null,
    initial_date: null,
    final_date: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadForwardingProductiveInsertions: (state, forwarding_productive_insertions) => {
    state.forwarding_productive_insertions = forwarding_productive_insertions;
  },
  loadCompanies: (state, companies) => {
    state.companies = companies;
  },
  loadPartners: (state, partners) => {
    state.partners = partners;
  },
  loadSegments: (state, segments) => {
    state.segments = segments;
  },
  loadOccupations: (state, occupations) => {
    state.occupations = occupations;
  },
  loadContractingTypes: (state, contracting_types) => {
    state.contracting_types = contracting_types;
  },
  loadOpportunities: (state, opportunities) => {
    state.opportunities = opportunities;
  },
  loadFeedbackTypes: (state, feedback_types) => {
    state.feedback_types = feedback_types;
  },
  loadUsers: (state, users) => {
    state.users = users;
  },
  loadInscriptions: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  loadQualificationStatuses: (state, qualification_statuses) => {
    state.qualification_statuses = qualification_statuses;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsMutation: (state, equipments_forwarding_productive_insertions) => {
    state.equipments_forwarding_productive_insertions = equipments_forwarding_productive_insertions;
  },

  // MUTATIONS - FIELDS TO/FROM API
  setIdMutation: (state, id) => {
    state.forwarding_productive_insertion.id = id;
  },
  setForwardingDateMutation: (state, forwarding_date) => {
    state.forwarding_productive_insertion.forwarding_date = forwarding_date;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.forwarding_productive_insertion.city_id = city ? city.id : null;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setOpportunityMutation: (state, opportunity) => {
    state.opportunity = opportunity;
    state.forwarding_productive_insertion.opportunity_id = opportunity ? opportunity.id : null;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
    state.forwarding_productive_insertion.inscription_id = inscription ? inscription.id : null;
  },
  setFeedbackTypeMutation: (state, feedback_type) => {
    state.feedback_type = feedback_type;
    state.forwarding_productive_insertion.feedback_type = feedback_type ? feedback_type.id : null;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    state.forwarding_productive_insertion.season_id = season ? season.id : null;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.forwarding_productive_insertion.user_id = user.id;
      } else {
        state.forwarding_productive_insertion.user_id = user.userId;
      }
    }
  },

  // --> MUTATIONS - DELETION <--
  forwarding_productive_insertionForDeletion: (state, payload) => {
    if (payload) {
      state.forwarding_productive_insertionId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterInscriptionStatusMutation: (state, inscription_status) => {
    state.params.inscription_status = inscription_status;
  },
  setFilterQualificationStatusMutation: (state, qualification_status) => {
    state.params.qualification_status = qualification_status;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  setFilterInscriptionMutation: (state, inscription) => {
    state.params.inscription = inscription;
  },
  setFilterOpportunityMutation: (state, opportunity) => {
    state.params.opportunity = opportunity;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterPartnerMutation: (state, partner) => {
    state.params.partner = partner;
  },
  setFilterUserMutation: (state, user) => {
    state.params.user = user;
  },
  setFilterCompanyMutation: (state, company) => {
    state.params.company = company;
  },
  setFilterSegmentMutation: (state, segment) => {
    state.params.segment = segment;
  },
  setFilterOccupationMutation: (state, occupation) => {
    state.params.occupation = occupation;
  },
  setFilterContractingTypeMutation: (state, contracting_type) => {
    state.params.contracting_type = contracting_type;
  },
  setFilterFeedbackTypeMutation: (state, feedback_type) => {
    state.params.feedback_type = feedback_type;
  },
  setFilterInitialDateMutation: (state, initial_date) => {
    state.params.initial_date = initial_date;
  },
  setFilterFinalDateMutation: (state, final_date) => {
    state.params.final_date = final_date;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },
  setFilterEquipmentMutation: (state, user_equipments_filtered) => {
    state.user_equipments_filtered = user_equipments_filtered;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddForwardingProductiveInsertion: (state) => {
    state.modalToggleAddForwardingProductiveInsertion = !state.modalToggleAddForwardingProductiveInsertion;
  },
  toggleModalDeleteForwardingProductiveInsertion: (state) => {
    state.modalToggleDeleteForwardingProductiveInsertion =
      !state.modalToggleDeleteForwardingProductiveInsertion;
  },
  toggleModalViewForwardingProductiveInsertion: (state) => {
    state.modalToggleViewForwardingProductiveInsertion = !state.modalToggleViewForwardingProductiveInsertion;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportForwardingProductiveInsertionsSpreadsheet: (state) => {
    state.modalToggleExportForwardingProductiveInsertionsSpreadsheet =
      !state.modalToggleExportForwardingProductiveInsertionsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetForwardingProductiveInsertionsResponseMutation: (
    state,
    hasSpreadsheetForwardingProductiveInsertionsResponse
  ) => {
    state.hasSpreadsheetForwardingProductiveInsertionsResponse =
      hasSpreadsheetForwardingProductiveInsertionsResponse;
  },

  setForwardingProductiveInsertionsPageToExportMutation: (state, forwarding_productive_insertion) => {
    state.json_data_forwarding_productive_insertion = null;
    const arrayData = [];
    forwarding_productive_insertion.forEach((element, index, array) => {
      let company = array[index].company ? array[index].company : "-";
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let feedback_type = array[index].feedback_type ? array[index].feedback_type.name : "-";
      let contracting_type = array[index].contracting_type ? array[index].contracting_type.name : "-";
      let area =
        array[index].neighborhood && array[index].neighborhood.area
          ? array[index].neighborhood.area.name
          : "-";
      let occupation = array[index].occupation ? array[index].occupation : null;
      let segment = array[index].segment ? array[index].segment : null;
      let opportunity = array[index].opportunity ? array[index].opportunity : null;
      let participant_name = array[index].participant_name ? array[index].participant_name : null;
      let participant_social_name = array[index].participant_social_name
        ? array[index].participant_social_name
        : null;
      let participant_status = array[index].participant_status ? array[index].participant_status : null;
      let partner = array[index].partner ? array[index].partner : null;
      let user = array[index].user ? array[index].user : null;
      let forwarding_date = array[index].forwarding_date
        ? moment(array[index].forwarding_date).format("DD/MM/YYYY")
        : "-";
      let forwarding_position =
        array[index].forwarding_position !== null ? array[index].forwarding_position + "ª vez" : null;

      const arrayItem = [];
      arrayItem["participant_status"] = participant_status;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["opportunity"] = opportunity;
      arrayItem["participant_social_name"] = participant_social_name;
      arrayItem["participant_name"] = participant_name;
      arrayItem["partner"] = partner;
      arrayItem["company"] = company;
      arrayItem["segment"] = segment;
      arrayItem["occupation"] = occupation;
      arrayItem["contracting_type"] = contracting_type;
      arrayItem["user"] = user;
      arrayItem["feedback_type"] = feedback_type;
      arrayItem["forwarding_date"] = forwarding_date;
      arrayItem["forwarding_position"] = forwarding_position;
      arrayData.push(arrayItem);
    });
    state.json_data_forwarding_productive_insertion = arrayData;
    state.hasSpreadsheetForwardingProductiveInsertionsResponse = true;
    state.modalToggleExportForwardingProductiveInsertionsSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setForwardingProductiveInsertionsTotalPerPage: (state, forwarding_productive_insertionsTotalPerPage) => {
    state.forwarding_productive_insertionsTotalPerPage = forwarding_productive_insertionsTotalPerPage;
  },
  setForwardingProductiveInsertionsOffset: (state, forwarding_productive_insertionsOffset) => {
    state.forwarding_productive_insertionsOffset = forwarding_productive_insertionsOffset;
  },
  setForwardingProductiveInsertionsTotal: (state, forwarding_productive_insertionsTotal) => {
    state.forwarding_productive_insertionsTotal = forwarding_productive_insertionsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.forwarding_productive_insertion) {
      state.forwarding_productive_insertion[prop] = null;
    }
    state.forwarding_productive_insertion.city_id = null;
    state.forwarding_productive_insertion.season_id = JSON.parse(localStorage.season).id;
    state.forwarding_productive_insertion.user_id = localStorage.user_id;
    state.opportunity = null;
    state.inscription = null;
    state.city = null;
    state.state = null;
    state.feedback_type = null;
  },
  clearQuery: (state) => {
    state.params.inscription_status = null;
    state.params.area = null;
    state.params.equipment = null;
    state.params.inscription = null;
    state.params.opportunity = null;
    state.params.partner = null;
    state.params.company = null;
    state.params.segment = null;
    state.params.occupation = null;
    state.params.user = null;
    state.params.city = null;
    state.params.state = null;
    state.params.contracting_type = null;
    state.params.feedback_type = null;
    state.params.qualification_status = null;
    state.params.initial_date = null;
    state.params.final_date = null;
    state.user_areas_filtered = null;
    state.user_equipments_filtered = null;
    state.forwarding_productive_insertions = [];
  },
  clearLoads: (state) => {
    state.opportunities = [];
    state.inscriptions = [];
    state.users = [];
    state.partners = [];
    state.partners = [];
    state.segments = [];
    state.occupations = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setForwardingProductiveInsertionsSpreadsheetMutation: (
    state,
    forwarding_productive_insertions_spreadsheet
  ) => {
    state.forwarding_productive_insertions_spreadsheet = forwarding_productive_insertions_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

// --> HELPER METHODS <--
function getUserAndEquipments() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }
  if (state.user_equipments_filtered) {
    user_equipments_ids = state.user_equipments_filtered.id;
  }
  return { user_areas_ids, user_equipments_ids };
}

function getFilterParams(user_areas_ids, user_equipments_ids, rootGetters, rootState) {
  return {
    season_id: rootState.ProductiveInsertions?.season?.id,
    inscription_status: state.params.inscription_status ? state.params.inscription_status.id : null,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    area_id: user_areas_ids,
    user_id: state.params.user ? state.params.user.id : null,
    qualification_status_id: state.params.qualification_status ? state.params.qualification_status.id : null,
    equipment_id: user_equipments_ids,
    company_id: state.params.company ? state.params.company.id : null,
    partner_id: state.params.partner ? state.params.partner.id : null,
    segment_id: state.params.segment ? state.params.segment.id : null,
    participant_name: util_function.formatNameForFilter(
      state.params.inscription ? state.params.inscription.name : null
    ),
    opportunity_id: state.params.opportunity ? state.params.opportunity.id : null,
    occupation_id: state.params.occupation ? state.params.occupation.id : null,
    contracting_type: state.params.contracting_type ? state.params.contracting_type.id : null,
    feedback_type: state.params.feedback_type ? state.params.feedback_type.id : null,
    initial_date: state.params.initial_date,
    final_date: state.params.final_date,
    per_page: state.forwarding_productive_insertionsTotalPerPage,
    offset: state.forwarding_productive_insertionsOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadForwardingProductiveInsertions: ({ commit, rootGetters, rootState }, from) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids, rootGetters, rootState);
    getForwardingProductiveInsertions(params)
      .then((response) => {
        commit("loadForwardingProductiveInsertions", response.data.forwarding_productive_insertions);
        commit("setForwardingProductiveInsertionsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadForwardingProductiveInsertionsForExport: ({ commit, rootGetters, rootState }) => {
    commit("hasSpreadsheetForwardingProductiveInsertionsResponseMutation", false);
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids, rootGetters, rootState);
    getForwardingProductiveInsertionsForExport(params)
      .then(async (response) => {
        commit(
          "setForwardingProductiveInsertionsSpreadsheetMutation",
          response.data.forwarding_productive_insertions
        );
        if (state.forwarding_productive_insertions_spreadsheet.length > 0) {
          await commit(
            "setForwardingProductiveInsertionsPageToExportMutation",
            response.data.forwarding_productive_insertions
          );
        } else {
          commit("hasSpreadsheetForwardingProductiveInsertionsResponseMutation", true);
          commit("toggleModalToggleExportForwardingProductiveInsertionsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCompanies: ({ commit, rootGetters }, value) => {
    const params = {
      name: value,
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      perPage: 10,
    };
    getCompaniesForMenus(params)
      .then((response) => {
        commit("loadCompanies", response.data.companies);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPartners: ({ commit, rootGetters }, value) => {
    const params = {
      name: value,
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      per_page: 10,
    };
    getPartnersForMenus(params)
      .then((response) => {
        commit("loadPartners", response.data.partners);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSegments: ({ commit }, value) => {
    const params = {
      name: value,
      per_page: 10,
    };
    getSegmentsForMenus(params)
      .then((response) => {
        commit("loadSegments", response.data.segments);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadOccupations: ({ commit }, value) => {
    const params = {
      name: value,
      per_page: 10,
    };
    getOccupationsForMenus(params)
      .then((response) => {
        commit("loadOccupations", response.data.occupations);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadOpportunities: ({ commit, rootGetters }, value) => {
    const params = {
      name: value,
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      per_page: 10,
      with_vacancies_remaining: false,
    };
    getOpportunitiesForMenus(params)
      .then((response) => {
        commit("loadOpportunities", response.data.opportunities);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadUsers: ({ commit }, value) => {
    const ID_PERFIL_SUPERVISAO_TECNICA = 2;
    const ID_PERFIL_GERENTE_DE_AREA = 5;
    const ID_PERFIL_ADM_DE_AREA = 6;

    const params = {
      name: value,
      ability_profile_id: [ID_PERFIL_SUPERVISAO_TECNICA, ID_PERFIL_GERENTE_DE_AREA, ID_PERFIL_ADM_DE_AREA],
      per_page: 10,
    };
    getUsersForProductiveInsertion(params)
      .then((response) => {
        commit("loadUsers", response.data.user);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInscriptions: ({ commit }, value) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = {
      participant_name: value,
      area_id: user_areas_ids,
      equipment_id: user_equipments_ids,
      season_id: state.forwarding_productive_insertion.season_id,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForProductiveInsertion(params)
      .then((response) => {
        const formattedInscriptions = response.data.inscriptions.map((inscription) => {
          return {
            ...inscription,
            name: inscription?.social_name
              ? `${inscription?.social_name} - ${inscription?.name}`
              : inscription?.name,
          };
        });
        commit("loadInscriptions", formattedInscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadContractingTypes: ({ commit }) => {
    getOpportunityContractingTypes()
      .then((response) => {
        commit("loadContractingTypes", response.data.contracting_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadFeedbackTypes: ({ commit }) => {
    getForwardingProductiveInsertionFeedbackTypes()
      .then((response) => {
        commit("loadFeedbackTypes", response.data.feedback_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadQualificationStatuses: ({ commit }) => {
    getQualificationStatus()
      .then((response) => {
        commit("loadQualificationStatuses", response.data.qualification_status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipments: ({ commit, rootGetters }) => {
    const params = {
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      area_id: state.user_areas_filtered.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsMutation", equipments);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getForwardingProductiveInsertionById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.forwarding_productive_insertions.id);
        commit("setInscriptionMutation", response.data.forwarding_productive_insertions.inscription);
        commit("setForwardingDateMutation", response.data.forwarding_productive_insertions.forwarding_date);
        commit("setCityMutation", response.data.forwarding_productive_insertions.city);
        commit("setOpportunityMutation", response.data.forwarding_productive_insertions.opportunity);
        commit("setFeedbackTypeMutation", response.data.forwarding_productive_insertions.feedback_type);
        commit("setSeasonMutation", response.data.forwarding_productive_insertions.season);
        commit("setUserMutation", response.data.forwarding_productive_insertions.user);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setForwardingDate: ({ commit }, payload) => {
    commit("setForwardingDateMutation", payload.value);
  },
  setOpportunity: ({ commit }, payload) => {
    commit("setOpportunityMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setInscription: ({ commit }, payload) => {
    commit("setInscriptionMutation", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setFeedbackType: ({ commit }, payload) => {
    commit("setFeedbackTypeMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddForwardingProductiveInsertion: async ({ commit }) => {
    commit("toggleModalAddForwardingProductiveInsertion");
  },
  closeModalAddForwardingProductiveInsertion: ({ commit }) => {
    commit("toggleModalAddForwardingProductiveInsertion");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddForwardingProductiveInsertion: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddForwardingProductiveInsertion");
  },
  closeEditModalAddForwardingProductiveInsertion: ({ commit }) => {
    commit("toggleModalAddForwardingProductiveInsertion");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewForwardingProductiveInsertion");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewForwardingProductiveInsertion");
    commit("clearFields");
  },
  toggleModalDeleteForwardingProductiveInsertion: ({ commit }, payload) => {
    commit("toggleModalDeleteForwardingProductiveInsertion");
    if (state.modalToggleDeleteForwardingProductiveInsertion && payload) {
      commit("forwarding_productive_insertionForDeletion", payload);
    } else {
      commit("forwarding_productive_insertionForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportForwardingProductiveInsertionsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportForwardingProductiveInsertionsSpreadsheet");
  },
  closeExportForwardingProductiveInsertionsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportForwardingProductiveInsertionsSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterInscription: ({ commit }, payload) => {
    commit("setFilterInscriptionMutation", payload);
  },
  setFilterInscriptionStatus: ({ commit }, payload) => {
    commit("setFilterInscriptionStatusMutation", payload);
  },
  setFilterQualificationStatus: ({ commit }, payload) => {
    commit("setFilterQualificationStatusMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  setFilterOpportunity: ({ commit }, payload) => {
    commit("setFilterOpportunityMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterCompany: ({ commit }, payload) => {
    commit("setFilterCompanyMutation", payload);
  },
  setFilterPartner: ({ commit }, payload) => {
    commit("setFilterPartnerMutation", payload);
  },
  setFilterSegment: ({ commit }, payload) => {
    commit("setFilterSegmentMutation", payload);
  },
  setFilterOccupation: ({ commit }, payload) => {
    commit("setFilterOccupationMutation", payload);
  },
  setFilterContractingType: ({ commit }, payload) => {
    commit("setFilterContractingTypeMutation", payload);
  },
  setFilterFeedbackType: ({ commit }, payload) => {
    commit("setFilterFeedbackTypeMutation", payload);
  },
  setFilterUser: ({ commit }, payload) => {
    commit("setFilterUserMutation", payload);
  },
  setFilterInitialDate: ({ commit }, payload) => {
    commit("setFilterInitialDateMutation", payload.value);
  },
  setFilterFinalDate: ({ commit }, payload) => {
    commit("setFilterFinalDateMutation", payload.value);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsMutation", []);
      commit("setFilterEquipmentMutation", null);
    }
  },

  // --> ACTIONS - PAGINATIONS <--
  setForwardingProductiveInsertionsTotalPerPage: ({ commit }, payload) => {
    commit("setForwardingProductiveInsertionsTotalPerPage", payload.totalPerPage);
  },
  setForwardingProductiveInsertionsOffset: ({ commit }, payload) => {
    commit("setForwardingProductiveInsertionsOffset", payload.offset);
  },

  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createForwardingProductiveInsertion(state.forwarding_productive_insertion)
      .then(() => {
        Vue.$toast.success("Seleção cadastrada com sucesso!");
        dispatch("closeModalAddForwardingProductiveInsertion");
        dispatch("loadForwardingProductiveInsertions");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a seleção! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateForwardingProductiveInsertion(
      state.forwarding_productive_insertion.id,
      state.forwarding_productive_insertion
    )
      .then(() => {
        dispatch("loadForwardingProductiveInsertions");
        dispatch("closeEditModalAddForwardingProductiveInsertion");
        Vue.$toast.success("Seleção atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a seleção! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyForwardingProductiveInsertion(state.forwarding_productive_insertionId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Seleção deletada com sucesso!");
        commit("toggleModalDeleteForwardingProductiveInsertion");
        dispatch("loadForwardingProductiveInsertions", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
