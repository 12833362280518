import {
  createSpontaneousProductiveInsertion,
  destroySpontaneousProductiveInsertion,
  getSpontaneousProductiveInsertionById,
  getSpontaneousProductiveInsertionContractingTypes,
  getSpontaneousProductiveInsertionInsertionTypes,
  getSpontaneousProductiveInsertions,
  getSpontaneousProductiveInsertionsForExport,
  updateSpontaneousProductiveInsertion,
} from "@/service/SpontaneousProductiveInsertionsApi";
import { getCompaniesForMenus } from "@/service/CompaniesApi";
import { getSegmentsForMenus } from "@/service/SegmentsApi";
import { getOccupationsForMenus } from "@/service/OccupationsApi";
import { getInscriptionForProductiveInsertion } from "@/service/InscriptionApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";
import { getUsersForProductiveInsertion } from "@/service/UsersApi";
import { util_function } from "@/util_functions";

Vue.component("downloadExcel", JsonExcel);

const state = {
  spontaneous_productive_insertion: {
    id: null,
    insertion_date: null,
    insertion_type: null,
    contracting_type: null,
    entrepreneurship_activity: null,
    had_access_credit_for_financing: null,
    has_establishment: null,
    establishment_name: null,
    establishment_address: null,
    has_cnpj: null,
    is_related_to_the_course_segment: null,
    observations: null,
    user_id: localStorage.user_id,
    city_id: null,
    inscription_id: null,
    company_id: null,
    segment_id: null,
    occupation_id: null,
    season_id: null,
  },
  photos: [],
  previews: [],
  saved_previews: [],
  photos_urls: [],
  incriptions_status: [
    {
      id: 0,
      name: "Desligado(a)",
    },
    {
      id: 3,
      name: "Ativo(a)",
    },
    {
      id: 5,
      name: "Concludente",
    },
  ],

  spontaneous_productive_insertions: [],
  inscriptions: [],
  users: [],
  insertion_types: [],
  companies: [],
  segments: [],
  occupations: [],
  contracting_types: [],
  equipments_spontaneous_productive_insertions: [],
  user_areas: [],
  user_equipments: [],
  spontaneous_productive_insertions_spreadsheet: [],

  errorMessages: [],
  spontaneous_productive_insertionId: null,
  city: null,
  state: null,
  inscription: null,
  user: null,
  company: null,
  segment: null,
  occupation: null,
  insertion_type: null,
  contracting_type: null,
  season: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,

  modalToggleAddSpontaneousProductiveInsertion: false,
  modalToggleDeleteSpontaneousProductiveInsertion: false,
  modalToggleViewSpontaneousProductiveInsertion: false,
  modalToggleExportSpontaneousProductiveInsertionsSpreadsheet: false,

  hasSpreadsheetSpontaneousProductiveInsertionsResponse: true,
  json_data_spontaneous_productive_insertion: null,

  spontaneous_productive_insertionsTotal: 0,
  spontaneous_productive_insertionsTotalPerPage: 10,
  spontaneous_productive_insertionsOffset: 0,

  params: {
    inscription_status: null,
    area: null,
    user: null,
    city: null,
    state: null,
    equipment: null,
    inscription: null,
    contracting_type: null,
    insertion_type: null,
    initial_date: null,
    final_date: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadSpontaneousProductiveInsertions: (state, spontaneous_productive_insertions) => {
    state.spontaneous_productive_insertions = spontaneous_productive_insertions;
  },
  loadCompanies: (state, companies) => {
    state.companies = companies;
  },
  loadSegments: (state, segments) => {
    state.segments = segments;
  },
  loadOccupations: (state, occupations) => {
    state.occupations = occupations;
  },
  loadContractingTypes: (state, contracting_types) => {
    state.contracting_types = contracting_types;
  },
  loadInsertionTypes: (state, insertion_types) => {
    state.insertion_types = insertion_types;
  },
  loadUsers: (state, users) => {
    state.users = users;
  },
  loadInscriptions: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsMutation: (state, equipments_spontaneous_productive_insertions) => {
    state.equipments_spontaneous_productive_insertions = equipments_spontaneous_productive_insertions;
  },

  // MUTATIONS - FIELDS TO/FROM API
  setIdMutation: (state, id) => {
    state.spontaneous_productive_insertion.id = id;
  },
  setInsertionDateMutation: (state, insertion_date) => {
    state.spontaneous_productive_insertion.insertion_date = insertion_date;
  },
  setEntrepreneurshipActivityMutation: (state, entrepreneurship_activity) => {
    state.spontaneous_productive_insertion.entrepreneurship_activity = entrepreneurship_activity;
  },
  setHadAccessCreditForFinancingMutation: (state, had_access_credit_for_financing) => {
    state.spontaneous_productive_insertion.had_access_credit_for_financing = had_access_credit_for_financing;
  },
  setHasEstablishmentMutation: (state, has_establishment) => {
    state.spontaneous_productive_insertion.has_establishment = has_establishment;
  },
  setEstablishmentNameMutation: (state, establishment_name) => {
    state.spontaneous_productive_insertion.establishment_name = establishment_name;
  },
  setEstablishmentAddressMutation: (state, establishment_address) => {
    state.spontaneous_productive_insertion.establishment_address = establishment_address;
  },
  setHasCnpjMutation: (state, has_cnpj) => {
    state.spontaneous_productive_insertion.has_cnpj = has_cnpj;
  },
  setIsRelatedToTheCourseSegmentMutation: (state, is_related_to_the_course_segment) => {
    state.spontaneous_productive_insertion.is_related_to_the_course_segment =
      is_related_to_the_course_segment;
  },
  setObservationsMutation: (state, observations) => {
    state.spontaneous_productive_insertion.observations = observations;
  },
  // setPhotosMutation: (state, photos) => {
  //   state.photos = photos;
  // },
  setPhotosMutation: (state, photos) => {
    if (state.photos.length > 0) {
      if (photos && photos.length > 0) {
        photos.forEach((photo) => {
          state.photos.push(photo);
        });
      } else {
        state.photos.push(photos);
      }
    } else {
      state.photos = photos;
    }
  },
  setPhotosUrlsMutation: (state, photos_urls) => {
    state.photos_urls = photos_urls;
  },
  setPreviewsMutation: (state, previews) => {
    state.previews = state.saved_previews;
    if (state.previews.length > 0) {
      if (previews && previews.length > 0) {
        previews.forEach((preview) => {
          state.previews.push(preview);
        });
      } else {
        state.previews.push(previews);
      }
    } else {
      state.previews = previews;
    }
  },
  setSavedPreviewsMutation: (state, saved_previews) => {
    state.saved_previews = saved_previews;
    state.previews = state.saved_previews;
  },
  setStateMutation: (state, value) => {
    state.state = value;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.spontaneous_productive_insertion.city_id = city ? city.id : null;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
    state.spontaneous_productive_insertion.inscription_id = inscription ? inscription.id : null;
  },
  setCompanyMutation: (state, company) => {
    state.company = company;
    state.spontaneous_productive_insertion.company_id = company ? company.id : null;
  },
  setSegmentMutation: (state, segment) => {
    state.segment = segment;
    state.spontaneous_productive_insertion.segment_id = segment ? segment.id : null;
  },
  setOccupationMutation: (state, occupation) => {
    state.occupation = occupation;
    state.spontaneous_productive_insertion.occupation_id = occupation ? occupation.id : null;
  },
  setInsertionTypeMutation: (state, insertion_type) => {
    state.insertion_type = insertion_type;
    state.spontaneous_productive_insertion.insertion_type = insertion_type ? insertion_type.id : null;
  },
  setContractingTypeMutation: (state, contracting_type) => {
    state.contracting_type = contracting_type;
    state.spontaneous_productive_insertion.contracting_type = contracting_type ? contracting_type.id : null;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    state.spontaneous_productive_insertion.season_id = season ? season.id : null;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.spontaneous_productive_insertion.user_id = user.id;
      } else {
        state.spontaneous_productive_insertion.user_id = user.userId;
      }
    }
  },

  // --> MUTATIONS - DELETION <--
  spontaneous_productive_insertionForDeletion: (state, payload) => {
    if (payload) {
      state.spontaneous_productive_insertionId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterInscriptionStatusMutation: (state, inscription_status) => {
    state.params.inscription_status = inscription_status;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  setFilterInscriptionMutation: (state, inscription) => {
    state.params.inscription = inscription;
  },
  setFilterUserMutation: (state, user) => {
    state.params.user = user;
  },
  setFilterStateMutation: (state, value) => {
    state.params.state = value;
  },
  setFilterCityMutation: (state, city) => {
    state.params.city = city;
  },
  setFilterContractingTypeMutation: (state, contracting_type) => {
    state.params.contracting_type = contracting_type;
  },
  setFilterInsertionTypeMutation: (state, insertion_type) => {
    state.params.insertion_type = insertion_type;
  },
  setFilterInitialDateMutation: (state, initial_date) => {
    state.params.initial_date = initial_date;
  },
  setFilterFinalDateMutation: (state, final_date) => {
    state.params.final_date = final_date;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },
  setFilterEquipmentMutation: (state, user_equipments_filtered) => {
    state.user_equipments_filtered = user_equipments_filtered;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddSpontaneousProductiveInsertion: (state) => {
    state.modalToggleAddSpontaneousProductiveInsertion = !state.modalToggleAddSpontaneousProductiveInsertion;
  },
  toggleModalDeleteSpontaneousProductiveInsertion: (state) => {
    state.modalToggleDeleteSpontaneousProductiveInsertion =
      !state.modalToggleDeleteSpontaneousProductiveInsertion;
  },
  toggleModalViewSpontaneousProductiveInsertion: (state) => {
    state.modalToggleViewSpontaneousProductiveInsertion =
      !state.modalToggleViewSpontaneousProductiveInsertion;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportSpontaneousProductiveInsertionsSpreadsheet: (state) => {
    state.modalToggleExportSpontaneousProductiveInsertionsSpreadsheet =
      !state.modalToggleExportSpontaneousProductiveInsertionsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetSpontaneousProductiveInsertionsResponseMutation: (
    state,
    hasSpreadsheetSpontaneousProductiveInsertionsResponse
  ) => {
    state.hasSpreadsheetSpontaneousProductiveInsertionsResponse =
      hasSpreadsheetSpontaneousProductiveInsertionsResponse;
  },

  setSpontaneousProductiveInsertionsPageToExportMutation: (state, spontaneous_productive_insertion) => {
    state.json_data_spontaneous_productive_insertion = null;
    const arrayData = [];
    spontaneous_productive_insertion.forEach((element, index, array) => {
      let company = array[index].company ? array[index].company.name : "-";
      let contracting_type = array[index].contracting_type ? array[index].contracting_type.name : "-";
      let entrepreneurship_activity = array[index].entrepreneurship_activity
        ? array[index].entrepreneurship_activity
        : "-";
      let establishment_address = array[index].establishment_address
        ? array[index].establishment_address
        : "-";
      let establishment_name = array[index].establishment_name ? array[index].establishment_name : "-";
      let had_access_credit_for_financing = array[index].had_access_credit_for_financing
        ? array[index].had_access_credit_for_financing === true
          ? "Sim"
          : "Não"
        : "-";
      let has_cnpj = array[index].has_cnpj ? (array[index].has_cnpj === true ? "Sim" : "Não") : "-";
      let has_establishment = array[index].has_establishment
        ? array[index].has_establishment === true
          ? "Sim"
          : "Não"
        : "-";
      let is_related_to_the_course_segment = array[index].is_related_to_the_course_segment
        ? array[index].is_related_to_the_course_segment === true
          ? "Sim"
          : "Não"
        : "-";
      let observations = array[index].observations ? array[index].observations : "-";
      let segment = array[index].segment ? array[index].segment.name : "-";
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let insertion_type = array[index].insertion_type ? array[index].insertion_type.name : "-";
      let area =
        array[index].inscription.neighborhood && array[index].inscription.neighborhood.area
          ? array[index].inscription.neighborhood.area.name
          : "-";
      let occupation = array[index].occupation ? array[index].occupation.name : null;
      let participant_name = array[index].participant_name ? array[index].participant_name : null;
      let participant_social_name = array[index].participant_social_name
        ? array[index].participant_social_name
        : null;
      let participant_status = array[index].participant_status ? array[index].participant_status : null;
      let user = array[index].user ? array[index].user.name : null;
      let insertion_date = array[index].insertion_date
        ? moment(array[index].insertion_date).format("DD/MM/YYYY")
        : "-";

      const arrayItem = [];

      arrayItem["insertion_date"] = insertion_date;
      arrayItem["participant_social_name"] = participant_social_name;
      arrayItem["participant_name"] = participant_name;
      arrayItem["participant_status"] = participant_status;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["insertion_type"] = insertion_type;
      arrayItem["company"] = company;
      arrayItem["contracting_type"] = contracting_type;
      arrayItem["entrepreneurship_activity"] = entrepreneurship_activity;
      arrayItem["had_access_credit_for_financing"] = had_access_credit_for_financing;
      arrayItem["segment"] = segment;
      arrayItem["occupation"] = occupation;
      arrayItem["has_establishment"] = has_establishment;
      arrayItem["establishment_name"] = establishment_name;
      arrayItem["establishment_address"] = establishment_address;
      arrayItem["has_cnpj"] = has_cnpj;
      arrayItem["is_related_to_the_course_segment"] = is_related_to_the_course_segment;
      arrayItem["observations"] = observations;
      arrayItem["user"] = user;
      arrayData.push(arrayItem);
    });
    state.json_data_spontaneous_productive_insertion = arrayData;
    state.hasSpreadsheetSpontaneousProductiveInsertionsResponse = true;
    state.modalToggleExportSpontaneousProductiveInsertionsSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setSpontaneousProductiveInsertionsTotalPerPage: (state, spontaneous_productive_insertionsTotalPerPage) => {
    state.spontaneous_productive_insertionsTotalPerPage = spontaneous_productive_insertionsTotalPerPage;
  },
  setSpontaneousProductiveInsertionsOffset: (state, spontaneous_productive_insertionsOffset) => {
    state.spontaneous_productive_insertionsOffset = spontaneous_productive_insertionsOffset;
  },
  setSpontaneousProductiveInsertionsTotal: (state, spontaneous_productive_insertionsTotal) => {
    state.spontaneous_productive_insertionsTotal = spontaneous_productive_insertionsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.spontaneous_productive_insertion) {
      state.spontaneous_productive_insertion[prop] = null;
    }
    state.spontaneous_productive_insertion.city_id = null;
    state.spontaneous_productive_insertion.season_id = JSON.parse(localStorage.season).id;
    state.spontaneous_productive_insertion.user_id = localStorage.user_id;
    state.inscription = null;
    state.insertion_type = null;
    state.contracting_type = null;
    state.city = null;
    state.state = null;
    state.segment = null;
    state.company = null;
    state.occupation = null;
    state.previews = [];
    state.photos_urls = [];
  },
  clearQuery: (state) => {
    state.params.inscription_status = null;
    state.params.area = null;
    state.params.user = null;
    state.params.city = null;
    state.params.state = null;
    state.params.equipment = null;
    state.params.inscription = null;
    state.params.contracting_type = null;
    state.params.insertion_type = null;
    state.params.initial_date = null;
    state.params.final_date = null;
    state.user_areas_filtered = null;
    state.user_equipments_filtered = null;
    state.spontaneous_productive_insertions = [];
  },
  clearLoads: (state) => {
    state.inscriptions = [];
    state.users = [];
    state.segments = [];
    state.occupations = [];
    state.companies = [];
    state.spontaneous_productive_insertion.photos = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setSpontaneousProductiveInsertionsSpreadsheetMutation: (
    state,
    spontaneous_productive_insertions_spreadsheet
  ) => {
    state.spontaneous_productive_insertions_spreadsheet = spontaneous_productive_insertions_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getUserAndEquipments() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }
  if (state.user_equipments_filtered) {
    user_equipments_ids = state.user_equipments_filtered.id;
  }
  return { user_areas_ids, user_equipments_ids };
}

function getFilterParams(user_areas_ids, user_equipments_ids, rootGetters, rootState) {
  return {
    season_id: rootState.ProductiveInsertions?.season?.id,
    inscription_status: state.params.inscription_status ? state.params.inscription_status.id : null,
    city_id:
      state.params.city && state.params.city.id
        ? state.params.city.id
        : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
        ? rootGetters["UtilFunctions/cityIds"]
        : null,
    area_id: user_areas_ids,
    user_id: state.params.user ? state.params.user.id : null,
    equipment_id: user_equipments_ids,
    participant_name: util_function.formatNameForFilter(
      state.params.inscription ? state.params.inscription.name : null
    ),
    contracting_type: state.params.contracting_type ? state.params.contracting_type.id : null,
    insertion_type: state.params.insertion_type ? state.params.insertion_type.id : null,
    initial_date: state.params.initial_date,
    final_date: state.params.final_date,
    per_page: state.spontaneous_productive_insertionsTotalPerPage,
    offset: state.spontaneous_productive_insertionsOffset,
  };
}

function formatObjectToFormData(object) {
  const formData = new FormData();
  for (const key in object) {
    if (object[key] !== null) {
      formData.append(`spontaneous_productive_insertion[${key}]`, object[key]);
    }
  }

  return formData;
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadSpontaneousProductiveInsertions: ({ commit, rootGetters, rootState }, from) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids, rootGetters, rootState);
    getSpontaneousProductiveInsertions(params)
      .then((response) => {
        commit("loadSpontaneousProductiveInsertions", response.data.spontaneous_productive_insertions);
        commit("setSpontaneousProductiveInsertionsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSpontaneousProductiveInsertionsForExport: ({ commit, rootGetters, rootState }) => {
    commit("hasSpreadsheetSpontaneousProductiveInsertionsResponseMutation", false);
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments(rootGetters);
    const params = getFilterParams(user_areas_ids, user_equipments_ids, rootGetters, rootState);
    getSpontaneousProductiveInsertionsForExport(params)
      .then(async (response) => {
        commit(
          "setSpontaneousProductiveInsertionsSpreadsheetMutation",
          response.data.spontaneous_productive_insertions
        );
        if (state.spontaneous_productive_insertions_spreadsheet.length > 0) {
          await commit(
            "setSpontaneousProductiveInsertionsPageToExportMutation",
            response.data.spontaneous_productive_insertions
          );
        } else {
          commit("hasSpreadsheetSpontaneousProductiveInsertionsResponseMutation", true);
          commit("toggleModalToggleExportSpontaneousProductiveInsertionsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCompanies: ({ commit, rootGetters }, value) => {
    const params = {
      name: value,
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      perPage: 10,
    };
    getCompaniesForMenus(params)
      .then((response) => {
        commit("loadCompanies", response.data.companies);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSegments: ({ commit }, value) => {
    const params = {
      name: value,
      per_page: 10,
    };
    getSegmentsForMenus(params)
      .then((response) => {
        commit("loadSegments", response.data.segments);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadOccupations: ({ commit }, value) => {
    const params = {
      name: value,
      per_page: 10,
    };
    getOccupationsForMenus(params)
      .then((response) => {
        commit("loadOccupations", response.data.occupations);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadUsers: ({ commit }, value) => {
    const ID_PERFIL_SUPERVISAO_TECNICA = 2;
    const ID_PERFIL_GERENTE_DE_AREA = 5;
    const ID_PERFIL_ADM_DE_AREA = 6;

    const params = {
      name: value,
      ability_profile_id: [ID_PERFIL_SUPERVISAO_TECNICA, ID_PERFIL_GERENTE_DE_AREA, ID_PERFIL_ADM_DE_AREA],
      per_page: 10,
    };
    getUsersForProductiveInsertion(params)
      .then((response) => {
        commit("loadUsers", response.data.user);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInscriptions: ({ commit }, value) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = {
      participant_name: value,
      area_id: user_areas_ids,
      equipment_id: user_equipments_ids,
      season_id: state.spontaneous_productive_insertion.season_id,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForProductiveInsertion(params)
      .then((response) => {
        const formattedInscriptions = response.data.inscriptions.map((inscription) => {
          return {
            ...inscription,
            name: inscription?.social_name
              ? `${inscription?.social_name} - ${inscription?.name}`
              : inscription?.name,
          };
        });
        commit("loadInscriptions", formattedInscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadContractingTypes: ({ commit }) => {
    getSpontaneousProductiveInsertionContractingTypes()
      .then((response) => {
        commit("loadContractingTypes", response.data.contracting_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInsertionTypes: ({ commit }) => {
    getSpontaneousProductiveInsertionInsertionTypes()
      .then((response) => {
        commit("loadInsertionTypes", response.data.insertion_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadEquipments: ({ commit, rootGetters }) => {
    const params = {
      city_id:
        state.params.city && state.params.city.id
          ? state.params.city.id
          : rootGetters["UtilFunctions/cityIds"] && rootGetters["UtilFunctions/cityIds"].length > 0
          ? rootGetters["UtilFunctions/cityIds"]
          : null,
      area_id: state.user_areas_filtered.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsMutation", equipments);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getSpontaneousProductiveInsertionById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.spontaneous_productive_insertions.id);
        commit("setInscriptionMutation", response.data.spontaneous_productive_insertions.inscription);
        commit("setInsertionDateMutation", response.data.spontaneous_productive_insertions.insertion_date);
        commit(
          "setEntrepreneurshipActivityMutation",
          response.data.spontaneous_productive_insertions.entrepreneurship_activity
        );
        commit(
          "setHadAccessCreditForFinancingMutation",
          response.data.spontaneous_productive_insertions.had_access_credit_for_financing
        );
        commit(
          "setHasEstablishmentMutation",
          response.data.spontaneous_productive_insertions.has_establishment
        );
        commit(
          "setEstablishmentNameMutation",
          response.data.spontaneous_productive_insertions.establishment_name
        );
        commit(
          "setEstablishmentAddressMutation",
          response.data.spontaneous_productive_insertions.establishment_address
        );
        commit("setHasCnpjMutation", response.data.spontaneous_productive_insertions.has_cnpj);
        commit(
          "setIsRelatedToTheCourseSegmentMutation",
          response.data.spontaneous_productive_insertions.is_related_to_the_course_segment
        );
        commit("setObservationsMutation", response.data.spontaneous_productive_insertions.observations);
        commit("setSeasonMutation", response.data.spontaneous_productive_insertions.season);
        commit("setCityMutation", response.data.spontaneous_productive_insertions.city);
        commit("setUserMutation", response.data.spontaneous_productive_insertions.user);
        commit("setInsertionTypeMutation", response.data.spontaneous_productive_insertions.insertion_type);
        commit(
          "setContractingTypeMutation",
          response.data.spontaneous_productive_insertions.contracting_type
        );
        commit("setCompanyMutation", response.data.spontaneous_productive_insertions.company);
        commit("setSegmentMutation", response.data.spontaneous_productive_insertions.segment);
        commit("setOccupationMutation", response.data.spontaneous_productive_insertions.occupation);
        commit("setPhotosUrlsMutation", response.data.spontaneous_productive_insertions.photos_urls);

        const photos = response.data.spontaneous_productive_insertions.photos_urls.map((photo) => photo.url);
        commit("setSavedPreviewsMutation", photos);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setInsertionDate: ({ commit }, payload) => {
    commit("setInsertionDateMutation", payload.value);
  },
  setEntrepreneurshipActivity: ({ commit }, payload) => {
    commit("setEntrepreneurshipActivityMutation", payload.value);
  },
  setHadAccessCreditForFinancing: ({ commit }, payload) => {
    commit("setHadAccessCreditForFinancingMutation", payload.value);
  },
  setHasEstablishment: ({ commit }, payload) => {
    commit("setHasEstablishmentMutation", payload.value);
  },
  setEstablishmentName: ({ commit }, payload) => {
    commit("setEstablishmentNameMutation", payload.value);
  },
  setEstablishmentAddress: ({ commit }, payload) => {
    commit("setEstablishmentAddressMutation", payload.value);
  },
  setHasCnpj: ({ commit }, payload) => {
    commit("setHasCnpjMutation", payload.value);
  },
  setIsRelatedToTheCourseSegment: ({ commit }, payload) => {
    commit("setIsRelatedToTheCourseSegmentMutation", payload.value);
  },
  setObservations: ({ commit }, payload) => {
    commit("setObservationsMutation", payload.value);
  },
  setInscription: ({ commit }, payload) => {
    commit("setInscriptionMutation", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setCity: ({ commit }, payload) => {
    commit("setCityMutation", payload);
  },
  setState: ({ commit }, payload) => {
    commit("setStateMutation", payload);
  },
  setInsertionType: ({ commit }, payload) => {
    commit("setInsertionTypeMutation", payload);
  },
  setContractingType: ({ commit }, payload) => {
    commit("setContractingTypeMutation", payload);
  },
  setCompany: ({ commit }, payload) => {
    commit("setCompanyMutation", payload);
  },
  setSegment: ({ commit }, payload) => {
    commit("setSegmentMutation", payload);
  },
  setOccupation: ({ commit }, payload) => {
    commit("setOccupationMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setPhotos: ({ commit }, payload) => {
    commit("setPhotosMutation", payload);
  },
  setPreviews: ({ commit }, payload) => {
    commit("setPreviewsMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddSpontaneousProductiveInsertion: async ({ commit }) => {
    commit("toggleModalAddSpontaneousProductiveInsertion");
  },
  closeModalAddSpontaneousProductiveInsertion: ({ commit }) => {
    commit("toggleModalAddSpontaneousProductiveInsertion");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddSpontaneousProductiveInsertion: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddSpontaneousProductiveInsertion");
  },
  closeEditModalAddSpontaneousProductiveInsertion: ({ commit }) => {
    commit("toggleModalAddSpontaneousProductiveInsertion");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewSpontaneousProductiveInsertion");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewSpontaneousProductiveInsertion");
    commit("clearFields");
  },
  toggleModalDeleteSpontaneousProductiveInsertion: ({ commit }, payload) => {
    commit("toggleModalDeleteSpontaneousProductiveInsertion");
    if (state.modalToggleDeleteSpontaneousProductiveInsertion && payload) {
      commit("spontaneous_productive_insertionForDeletion", payload);
    } else {
      commit("spontaneous_productive_insertionForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportSpontaneousProductiveInsertionsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportSpontaneousProductiveInsertionsSpreadsheet");
  },
  closeExportSpontaneousProductiveInsertionsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportSpontaneousProductiveInsertionsSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterInscription: ({ commit }, payload) => {
    commit("setFilterInscriptionMutation", payload);
  },
  setFilterInscriptionStatus: ({ commit }, payload) => {
    commit("setFilterInscriptionStatusMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  setFilterOccupation: ({ commit }, payload) => {
    commit("setFilterOccupationMutation", payload);
  },
  setFilterContractingType: ({ commit }, payload) => {
    commit("setFilterContractingTypeMutation", payload);
  },
  setFilterInsertionType: ({ commit }, payload) => {
    commit("setFilterInsertionTypeMutation", payload);
  },
  setFilterUser: ({ commit }, payload) => {
    commit("setFilterUserMutation", payload);
  },
  setFilterState: ({ commit }, payload) => {
    commit("setFilterStateMutation", payload);
  },
  setFilterCity: ({ commit }, payload) => {
    commit("setFilterCityMutation", payload);
  },
  setFilterInitialDate: ({ commit }, payload) => {
    commit("setFilterInitialDateMutation", payload.value);
  },
  setFilterFinalDate: ({ commit }, payload) => {
    commit("setFilterFinalDateMutation", payload.value);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsMutation", []);
      commit("setFilterEquipmentMutation", null);
    }
  },

  // --> ACTIONS - PAGINATIONS <--
  setSpontaneousProductiveInsertionsTotalPerPage: ({ commit }, payload) => {
    commit("setSpontaneousProductiveInsertionsTotalPerPage", payload.totalPerPage);
  },
  setSpontaneousProductiveInsertionsOffset: ({ commit }, payload) => {
    commit("setSpontaneousProductiveInsertionsOffset", payload.offset);
  },

  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    const formData = formatObjectToFormData(state.spontaneous_productive_insertion);
    state.photos.forEach((photo) => {
      formData.append(`spontaneous_productive_insertion[photos][]`, photo);
    });

    createSpontaneousProductiveInsertion(formData)
      .then(() => {
        Vue.$toast.success("Inserção Espontânea cadastrada com sucesso!");
        dispatch("closeModalAddSpontaneousProductiveInsertion");
        dispatch("loadSpontaneousProductiveInsertions");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a Inserção Espontânea! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    const formData = formatObjectToFormData(state.spontaneous_productive_insertion);
    state.photos.forEach((photo) => {
      formData.append(`spontaneous_productive_insertion[photos][]`, photo);
    });

    updateSpontaneousProductiveInsertion(state.spontaneous_productive_insertion.id, formData)
      .then(() => {
        dispatch("loadSpontaneousProductiveInsertions");
        dispatch("closeEditModalAddSpontaneousProductiveInsertion");
        Vue.$toast.success("Inserção Espontânea atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a Inserção Espontânea! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroySpontaneousProductiveInsertion(state.spontaneous_productive_insertionId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Inserção Espontânea deletada com sucesso!");
        commit("toggleModalDeleteSpontaneousProductiveInsertion");
        dispatch("loadSpontaneousProductiveInsertions", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
