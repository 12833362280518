<template>
  <div class="mx-12 my-6">
    <div id="menus_phase_teams" class="flex">
      <div>
        <a @click="changeTab('teams_in_progress')" href="javascript:void(0)">
          <BaseText
            text="TURMAS ATIVAS"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorTeamsInProgress"
          />
        </a>
      </div>
      <div class="border-l mx-10"></div>
      <div v-if="permit_list_teams_closed">
        <a @click="changeTab('teams_finished')" href="javascript:void(0)">
          <BaseText
            text="TURMAS ENCERRADAS"
            typeText="custom"
            weight="semibold"
            mode="uppercase"
            size="sm"
            :color="setColorTeamsFinished"
          />
        </a>
      </div>
    </div>
    <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
    <TeamPhase />
  </div>
</template>

<script>
import BaseText from "../../components/BaseText.vue";
import { mapState, mapActions } from "vuex";
import TeamPhase from "@/views/TeamPhase.vue";

export default {
  components: {
    TeamPhase,
    BaseText,
  },
  created() {
    this.canAcces("team_index_closed").then((response) => {
      this.permit_list_teams_closed = response;
    });
    this.$store.dispatch("Season/setPhase", this.phase);
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Team/loadTeachers");
    this.getSeasonDateManualExecutionEnd();
  },
  data() {
    return {
      season_date_manual_execution_end: true,
      permit_list_teams_closed: true,
    };
  },
  beforeUpdate: function () {
    this.$store.dispatch("Teams/filterTeams");
  },
  computed: {
    ...mapState({
      phase: (state) => state.Season.phase,
      teams: (state) => state.Teams.filteredAll,
      teamsToExportPresences: (state) => state.Teams.teamsToExportPresences,
      discipline: (state) => state.Teams.filter.discipline,
      modalTeamOpen: (state) => state.Team.modalTeamOpen,
      season: (state) => state.Seasons.season,
      areas: (state) => state.Areas.areas,
      areas_equipment: (state) => state.Teams.filter.areas_equipment,
      start: (state) => state.Teams.filter.start,
      closing: (state) => state.Teams.filter.closing,
      code: (state) => state.Teams.filter.code,
      equipment: (state) => state.Teams.filter.equipment,
      teachers: (state) => state.Team.teachers,
      course: (state) => state.Teams.filter.course,
      teacher: (state) => state.Teams.filter.teacher,
      showExport: (state) => state.ModalExportTeamPresence.modalOpen,
      offset: (state) => state.Teams.offset,
      totalPerPage: (state) => state.Teams.totalPerPage,
      totalTeams: (state) => state.Teams.teamsTotal,
      user_areas: (state) => (state.user_areas.areas ? state.user_areas.areas : null),
      tabTypeTeamView: (state) => state.Teams.tabTypeTeamView,
    }),
    setColorTeamsInProgress() {
      return this.tabTypeTeamView === "teams_in_progress" ? "burnedYellow" : "ashes-hover";
    },
    setColorTeamsFinished() {
      return this.tabTypeTeamView === "teams_finished" ? "burnedYellow" : "ashes-hover";
    },
  },

  methods: {
    ...mapActions({
      clearFilter: "Teams/clearFilter",
      setArea: "Teams/filterTeamsByArea",
      setTeacher: "Teams/filterByTeamTeacher",
      canAcces: "AccessControl/canAcces",
    }),
    isActive(discipline) {
      if (this.discipline) {
        return this.discipline.id === discipline.id;
      }
    },
    openModalExport() {
      this.$store.dispatch("ModalExportTeamPresence/openModal");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null";
    },
    changeTab(value) {
      this.$store.dispatch("Teams/setTabTypeTeamView", value);
    },
  },
};
</script>
