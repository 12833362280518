<template>
  <div class="font-system" v-if="permit && open">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-2xl mx-2 col-span-2">Busca por Perfil</div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Status da Matrícula"
            select-placeholder="Escolha a opção para buscar"
            :multiple-option="false"
            :value="inscription_status_filtered"
            :options="incriptions_status"
            :dispatch="setFilterInscriptionStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Filtrar por Nome Civil ou Social do Aluno(a)"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptionsForGeneralMenus"
            :dispatch="setFilterInscription"
            load-dispatch="ProfileSearchs/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div class="col-span-4">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Município"
            :multiple-option="false"
            :value="city"
            :options="cities"
            label-text="Filtrar por Município"
            :dispatch="setFilterCity"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Área"
            label-text="Pesquisar pela Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_profile_searchs"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="setFilterEquipment"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Status Qualificação"
            label-text="Pesquisar por Status Qualificação"
            :multiple-option="false"
            :value="qualification_status_filtered"
            :options="qualification_statuses"
            :dispatch="setFilterQualificationStatus"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Possui currículo?"
            label-text="Pesquisar se possui currículo"
            :multiple-option="false"
            :value="has_resume_filtered"
            :options="has_resume_options"
            :dispatch="setFilterHasResume"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            :label-text="getLabelNeighborhoodFilter()"
            select-placeholder="Selecionar bairro para busca"
            :multiple-option="false"
            :value="neighborhood_filtered"
            :options="neighborhoods"
            :dispatch="setFilterNeighborhood"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Escolaridade"
            select-placeholder="Selecionar escolaridade para busca"
            :multiple-option="false"
            :value="schooling_filtered"
            :options="schooling_options"
            :dispatch="setFilterSchooling"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Curso"
            select-placeholder="Selecionar curso para busca"
            :multiple-option="false"
            :value="course_filtered"
            :options="courses"
            :dispatch="setFilterCourse"
          />
        </div>
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            label-text="Já foi encaminhado?"
            select-placeholder="Selecionar opção para buscar"
            :multiple-option="false"
            :value="forwarded_filtered"
            :options="forwardead_options"
            :dispatch="setFilterForwarded"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_minimum_age"
            input-type="number"
            :value="minimum_age_filtered"
            show-label
            label-text="Idade Mínima"
            dispatch="ProfileSearchs/setFilterMinimumAge"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="filter_by_maximum_age"
            input-type="number"
            :value="maximum_age_filtered"
            show-label
            label-text="Idade Máxima"
            dispatch="ProfileSearchs/setFilterMaximumAge"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="inscriptions.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal v-if="profile_searchs_spreadsheet.length > 0 && modalToggleExportProfileSearchsSpreadsheet">
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Perfis"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon dispatch="ProfileSearchs/closeExportProfileSearchsSpreadsheetModal" forClose />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="ProfileSearchs/closeExportProfileSearchsSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_profile_search"
                  :before-finish="closeExportProfileSearchsSpreadsheetModal"
                  worksheet="Inserções_Produtivas"
                  name="insercoes_produtivas.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetProfileSearchsResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-profile_searchs"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="ProfileSearchs/loadInscriptions"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-profile-searchs"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-forwarding_profile-searchs">
      <div class="mx-12">
        <ProfileSearchsCards :data="inscriptions" />
      </div>
      <div id="profile_searchs-pagination" class="mx-12 my-6 pb-3" v-if="inscriptions.length > 0">
        <BasePagination
          :total="totalProfileSearchs"
          :total-per-page="totalPerPage"
          dispatch="ProfileSearchs/setProfileSearchsOffset"
        />
      </div>
      <!--      <ModalViewProfileSearchData :open="modalToggleViewProfileSearch" />-->
    </div>
  </div>
</template>

<script>
import ProfileSearchsCards from "../list/ProfileSearchsCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";
import Multiselect from "../../components/forms/Multiselect.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import BaseText from "@/components/BaseText.vue";
import Label from "@/components/Label.vue";
import BaseModal from "@/components/BaseModal.vue";
import PreLoading from "@/components/PreLoading.vue";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    AppButtonIcon,
    AppButtonEmpty,
    BaseText,
    Label,
    BaseModal,
    Multiselect,
    BasePagination,
    AppButtonColorful,
    ProfileSearchsCards,
    BaseInput,
    PreLoading,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Status: "participant_status",
        "Status Qualificação": "qualification_status",
        Área: "area",
        Equipamento: "equipment",
        Bairro: "neighborhood",
        "Nome Social": "participant_social_name",
        "Nome Civil": "participant_name",
        Idade: "age",
        Escolaridade: "completed_series",
        "Curso de Qualificação": "course",
        "Já foi encaminhado? (Quantidade de vezes)": "forwardings_quantity",
        "Possui currículo?": "resume_document",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setFilterInscriptionStatus: "ProfileSearchs/setFilterInscriptionStatus",
      setUserAreasFiltered: "ProfileSearchs/setUserAreasFiltered",
      setFilterEquipment: "ProfileSearchs/setFilterEquipment",
      setFilterNeighborhood: "ProfileSearchs/setFilterNeighborhood",
      setFilterCourse: "ProfileSearchs/setFilterCourse",
      setFilterInscription: "ProfileSearchs/setFilterInscription",
      setFilterUser: "ProfileSearchs/setFilterUser",
      setFilterForwarded: "ProfileSearchs/setFilterForwarded",
      setFilterSchooling: "ProfileSearchs/setFilterSchooling",
      setFilterQualificationStatus: "ProfileSearchs/setFilterQualificationStatus",
      setFilterHasResume: "ProfileSearchs/setFilterHasResume",
      setFilterCity: "ProfileSearchs/setFilterCity",
      setFilterState: "ProfileSearchs/setFilterState",
      closeExportProfileSearchsSpreadsheetModal: "ProfileSearchs/closeExportProfileSearchsSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("ProfileSearchs/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered ? "" : "Selecione a área para listar os equipamentos";
    },
    async exportToExcel() {
      await this.$store.dispatch("ProfileSearchs/loadProfileSearchsForExport");
    },
    getLabelCityFilter() {
      return this.state ? "Filtrar por Município" : "Selecione o estado para listar os municípios";
    },
    getLabelNeighborhoodFilter() {
      return this.city ? "Filtrar por Bairro" : "Selecione o município para listar os bairros";
    },
  },

  created() {
    this.canAcces("inscription_load_for_productive_insertion").then((response) => {
      this.permit = response;
    });
    this.canAcces("inscription_export_spreadsheet_for_productive_insertion").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    this.$store.dispatch("ProfileSearchs/loadCourses");
    this.$store.dispatch("ProfileSearchs/loadQualificationStatuses");

    const user = JSON.parse(localStorage.userData);
    this.$store.dispatch("ProfileSearchs/setUserAreas", user.areas);

    if (user.equipments.length > 0) {
      this.$store.dispatch("ProfileSearchs/setUserEquipments", user.equipments);
    }
    this.$store.dispatch("ProfileSearchs/loadInscriptionsForGeneralMenus");
  },

  computed: {
    ...mapState({
      inscriptions: (state) => state.ProfileSearchs.inscriptions,
      modalToggleAddProfileSearch: (state) => state.ProfileSearchs.modalToggleAddProfileSearch,
      modalToggleDeleteProfileSearch: (state) => state.ProfileSearchs.modalToggleDeleteProfileSearch,
      modalToggleViewProfileSearch: (state) => state.ProfileSearchs.modalToggleViewProfileSearch,
      offset: (state) => state.ProfileSearchs.profile_searchsOffset,
      totalPerPage: (state) => state.ProfileSearchs.profile_searchsTotalPerPage,
      totalProfileSearchs: (state) => state.ProfileSearchs.profile_searchsTotal,
      neighborhoods: (state) => state.ProfileSearchs.neighborhoods,
      neighborhood_filtered: (state) => state.ProfileSearchs.params.neighborhood,
      courses: (state) => state.ProfileSearchs.courses,
      course_filtered: (state) => state.ProfileSearchs.params.course,
      forwardead_options: (state) => state.ProfileSearchs.forwardead_options,
      forwarded_filtered: (state) => state.ProfileSearchs.params.forwarded,
      schooling_options: (state) => state.ProfileSearchs.schooling_options,
      schooling_filtered: (state) => state.ProfileSearchs.params.schooling,
      incriptions_status: (state) => state.ProfileSearchs.incriptions_status,
      inscription_status_filtered: (state) => state.ProfileSearchs.params.inscription_status,
      qualification_statuses: (state) => state.ProfileSearchs.qualification_statuses,
      qualification_status_filtered: (state) => state.ProfileSearchs.params.qualification_status,
      has_resume_options: (state) => state.ProfileSearchs.has_resume_options,
      has_resume_filtered: (state) => state.ProfileSearchs.params.has_resume,
      initial_date_filtered: (state) => state.ProfileSearchs.params.initial_date,
      final_date_filtered: (state) => state.ProfileSearchs.params.final_date,
      minimum_age_filtered: (state) => state.ProfileSearchs.params.minimum_age,
      maximum_age_filtered: (state) => state.ProfileSearchs.params.maximum_age,
      participant_name_filtered: (state) => state.ProfileSearchs.params.participant_name,
      user_areas: (state) => state.ProfileSearchs.user_areas,
      user_areas_filtered: (state) => state.ProfileSearchs.user_areas_filtered,
      equipments_profile_searchs: (state) => state.ProfileSearchs.equipments_profile_searchs,
      user_equipments_filtered: (state) => state.ProfileSearchs.user_equipments_filtered,
      profile_searchs_spreadsheet: (state) => state.ProfileSearchs.profile_searchs_spreadsheet,
      hasSpreadsheetProfileSearchsResponse: (state) =>
        state.ProfileSearchs.hasSpreadsheetProfileSearchsResponse,
      json_data_profile_search: (state) => state.ProfileSearchs.json_data_profile_search,
      modalToggleExportProfileSearchsSpreadsheet: (state) =>
        state.ProfileSearchs.modalToggleExportProfileSearchsSpreadsheet,
      inscriptionsForGeneralMenus: (state) => state.ProfileSearchs.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.ProfileSearchs.params.inscription,
      state: (state) => state.ProfileSearchs.params.state,
      city: (state) => state.ProfileSearchs.params.city,
      cities: (state) => state.UtilFunctions.cities,
      states: (state) => state.UtilFunctions.states,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("ProfileSearchs/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("ProfileSearchs/loadInscriptions");
      this.backToTop();
    },
    city: function () {
      this.city != null
        ? this.$store.dispatch("ProfileSearchs/loadNeighborhoods")
        : this.$store.dispatch("ProfileSearchs/setNeighborhoods", []);
    },
  },
};
</script>
