<template>
  <div>
    <div class="mt-5">
      <FilterComponent>
        <div>
          <Multiselect
            id="filterArea"
            selectPlaceholder="Selecionar"
            label-text="filtrar por área"
            class="mr-5"
            closeOnSelect
            show-label
            :multipleOption="false"
            :options="user_areas"
            :value="areas_equipment"
            :dispatch="setArea"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="code-team"
            show-label
            label-text="Filtrar pelo código"
            input-placeholder="Pesquisar pelo código"
            input-type="text"
            dispatch="Teams/filterByTeamCode"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="equipment-team"
            show-label
            label-text="Filtrar pelo equipamento"
            input-placeholder="Pesquisar pelo equipamento"
            input-type="text"
            dispatch="Teams/filterByTeamEquipment"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="course-team"
            show-label
            label-text="Filtrar pelo curso"
            input-placeholder="Pesquisar pelo curso"
            input-type="text"
            dispatch="Teams/filterByTeamCourse"
          />
        </div>
        <div v-if="this.user_logged?.abilityProfileId !== 3">
          <Multiselect
            close-on-select
            show-label
            select-placeholder="Filtrar pelo Instrutor(a)"
            label-text="Instrutor"
            :multiple-option="false"
            :value="teacher"
            :options="teachers"
            :dispatch="setTeacher"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="birth-year-relative"
            show-label
            label-text="Data de Início"
            input-type="date"
            :value="start"
            dispatch="Teams/filterTeamsByPeriodStart"
          />
        </div>
        <div>
          <BaseInput
            active
            input-name="birth-year-relative"
            show-label
            label-text="Data de Fim"
            input-type="date"
            :value="closing"
            dispatch="Teams/filterTeamsByPeriodClosing"
          />
        </div>
      </FilterComponent>
    </div>
    <div class="grid grid-rols-3 my-5">
      <div
        class="justify-self-end"
        v-if="permit_create_teams && season_date_manual_execution_end && isTeamsInProgress()"
      >
        <AppButtonColorful
          text="Adicionar turmas"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Team/openModal"
        />
      </div>
      <div class="grid grid-cols-2 mt-6">
        <div class="grid grid-flow-col items-center gap-5 auto-cols-max">
          <div v-show="phase && phase.id !== 3 && phase.id !== 2">
            <div class="grid grid-flow-col items-center gap-5 auto-cols-max">
              <div>
                <BaseText
                  text="Módulos"
                  typeText="custom"
                  weight="bold"
                  :italic="false"
                  mode="uppercase"
                  size="xs"
                  color="ashes-dark"
                />
              </div>
              <div :key="index" v-for="(discipline, index) in phase.disciplines">
                <AppButtonStatus
                  :text="discipline.name"
                  :active="isActive(discipline)"
                  :payload="discipline"
                  typeButton="primary"
                  dispatch="Teams/filterTeamsByDiscipline"
                />
              </div>
            </div>
          </div>
          <div>
            <a href="javascript:void(0)" @click="clearFilter()">
              <img
                class="inline-block"
                src="../assets/images/filter_alt_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Limpar"
                typeText="custom"
                weight="normal"
                :italic="false"
                size="xs"
                color="burnedYellow"
              />
            </a>
          </div>
          <div v-if="permit_export_teams">
            <a href="javascript:void(0)" @click="exportToExcel('teams_spreadsheet')">
              <img
                class="inline-block"
                src="../assets/images/logout_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Exportar Planilha Turmas"
                typeText="custom"
                :italic="false"
                size="xs"
                color="burnedYellow"
                style="cursor: pointer"
              />
            </a>
          </div>
          <div v-if="permit_export_teams_presences">
            <a href="javascript:void(0)" @click="openModalExport()">
              <img
                class="inline-block"
                src="../assets/images/logout_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Exportar frequências"
                typeText="custom"
                :italic="false"
                size="xs"
                color="burnedYellow"
                style="cursor: pointer"
              />
            </a>
          </div>
          <div v-if="isTeamsInProgress() && permit_export_teams_pendings">
            <a href="javascript:void(0)" @click="exportToExcel('teams_pendings_spreadsheet')">
              <img
                class="inline-block"
                src="../assets/images/logout_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Exportar Planilha de Pendências"
                typeText="custom"
                :italic="false"
                size="xs"
                color="burnedYellow"
                style="cursor: pointer"
              />
            </a>
          </div>
          <div v-if="permit_suspend_meeting && isTeamsInProgress()">
            <a href="javascript:void(0)" @click="suspendMeetings()">
              <img
                class="inline-block"
                src="../assets/images/logout_black_24dp.svg"
                alt=""
                style="cursor: pointer"
              />
              <BaseText
                text="Suspender Aulas"
                typeText="custom"
                :italic="false"
                size="xs"
                color="burnedYellow"
                style="cursor: pointer"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <TeamsCards :data="teams" />
      </div>
      <div id="team-pagination" class="mx-12 my-6 pb-3" v-if="teams.length > 0">
        <BasePagination :total="totalTeams" :total-per-page="totalPerPage" dispatch="Teams/setOffset" />
      </div>
    </div>
    <ModalTeam :open="modalTeamOpen" />
    <ModalExportTeamPresence :open="showExport" />
    <ModalSuspendMeetings :open="modalSuspendMeetingsByTeamAndDateConfirmationOpen" />
  </div>
</template>
<script>
import Multiselect from "../components/forms/Multiselect.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonStatus from "../components/AppButtonStatus";
import AppButtonColorful from "../components/AppButtonColorful";
import TeamsCards from "./list/TeamsCards.vue";
import ModalTeam from "./ModalTeam.vue";
import ModalExportTeamPresence from "./ModalExportTeamPresence.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BasePagination from "../components/BasePagination.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import ModalSuspendMeetings from "@/views/ModalSuspendMeetings.vue";

export default {
  components: {
    ModalSuspendMeetings,
    FilterComponent,
    BaseText,
    BasePagination,
    AppButtonStatus,
    AppButtonColorful,
    TeamsCards,
    ModalTeam,
    Multiselect,
    ModalExportTeamPresence,
    BaseInput,
  },
  created() {
    this.user_logged = JSON.parse(localStorage.getItem("userData"));
    this.canAcces("export_teams").then((resp) => {
      this.permit_export_teams = resp;
    });
    this.canAcces("export_teams_pendings").then((resp) => {
      this.permit_export_teams_pendings = resp;
    });
    this.canAcces("export_teams_presences").then((resp) => {
      this.permit_export_teams_presences = resp;
    });
    this.canAcces("team_create").then((resp) => {
      this.permit_create_teams = resp;
    });
    this.canAcces("meeting_suspend").then((resp) => {
      this.permit_suspend_meeting = resp;
    });
    this.$store.dispatch("Season/setPhase", this.phase);
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Team/loadTeachers");
    this.getSeasonDateManualExecutionEnd();
  },
  data() {
    return {
      season_date_manual_execution_end: true,
      json_fields_teams: {
        Área: "area",
        Município: "city",
        "Código da turma": "code",
        Equipamento: "equipment",
        Curso: "course",
        "Dias da Semana": "daysWeek",
        Turno: "shift",
        Instrutor: "teacher",
        "Nº de alunos": "qtd_students",
        Ínicio: "start",
        Término: "closing",
      },
      json_fields_teams_pendings: {
        Área: "area",
        Município: "city",
        "Código da turma": "code",
        Equipamento: "equipment",
        Instrutor: "teacher",
        Pendências: "sorted_unregistered_presence_dates",
      },
      type_export: null,
      permit_export_teams: false,
      permit_export_teams_pendings: false,
      permit_export_teams_presences: false,
      permit_create_teams: false,
      permit_suspend_meeting: false,
      user_logged: null,
    };
  },
  beforeUpdate: function () {
    this.$store.dispatch("Teams/filterTeams");
  },
  computed: {
    ...mapState({
      phase: (state) => state.Season.phase,
      teams: (state) => state.Teams.all,
      teamsToExportPresences: (state) => state.Teams.teamsToExportPresences,
      discipline: (state) => state.Teams.filter.discipline,
      modalTeamOpen: (state) => state.Team.modalTeamOpen,
      season: (state) => state.Seasons.season,
      areas: (state) => state.Areas.areas,
      areas_equipment: (state) => state.Teams.filter.areas_equipment,
      start: (state) => state.Teams.filter.start,
      closing: (state) => state.Teams.filter.closing,
      code: (state) => state.Teams.filter.code,
      equipment: (state) => state.Teams.filter.equipment,
      teachers: (state) => state.Team.teachers,
      course: (state) => state.Teams.filter.course,
      teacher: (state) => state.Teams.filter.teacher,
      showExport: (state) => state.ModalExportTeamPresence.modalOpen,
      offset: (state) => state.Teams.offset,
      totalPerPage: (state) => state.Teams.totalPerPage,
      totalTeams: (state) => state.Teams.teamsTotal,
      user_areas: (state) => state.UtilFunctions.user_areas ?? [],
      tabTypeTeamView: (state) => state.Teams.tabTypeTeamView,
      modalSuspendMeetingsByTeamAndDateConfirmationOpen: (state) =>
        state.Meeting.modalSuspendMeetingsByTeamAndDateConfirmationOpen,
      modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen: (state) =>
        state.Meeting.modalDisableSuspensionMeetingsByTeamAndDateConfirmationOpen,

      teams_spreadsheet: (state) => state.Teams.teams_spreadsheet,
      hasSpreadsheetTeamsResponse: (state) => state.Teams.hasSpreadsheetTeamsResponse,
      modalToggleExportTeamsSpreadsheet: (state) => state.Teams.modalToggleExportTeamsSpreadsheet,
      json_data_team: (state) => state.Teams.json_data_team,

      teams_pendings_spreadsheet: (state) => state.Teams.teams_pendings_spreadsheet,
      hasSpreadsheetTeamsPendingsResponse: (state) => state.Teams.hasSpreadsheetTeamsPendingsResponse,
      modalToggleExportTeamsPendingsSpreadsheet: (state) =>
        state.Teams.modalToggleExportTeamsPendingsSpreadsheet,
      json_data_team_pendings: (state) => state.Teams.json_data_team_pendings,

      dataSpreadsheet: (state) => state.SelectFieldsToExport.dataSpreadsheet,
      modalToggleExport: (state) => state.SelectFieldsToExport.modalToggleExport,
      titleModal: (state) => state.SelectFieldsToExport.titleModal,
      dispatchToCloseModal: (state) => state.SelectFieldsToExport.dispatchToCloseModal,
      json_data: (state) => state.SelectFieldsToExport.json_data,
      sheetTabName: (state) => state.SelectFieldsToExport.sheetTabName,
      fileName: (state) => state.SelectFieldsToExport.fileName,
      variablePreloading: (state) => state.SelectFieldsToExport.variablePreloading,
      json_fields: (state) => state.SelectFieldsToExport.json_fields,
      dispatchOpenModal: (state) => state.SelectFieldsToExport.dispatchOpenModal,
    }),
  },

  methods: {
    ...mapActions({
      clearFilter: "Teams/clearFilter",
      setArea: "Teams/filterTeamsByArea",
      setTeacher: "Teams/filterByTeamTeacher",
      canAcces: "AccessControl/canAcces",
    }),
    isActive(discipline) {
      if (this.discipline) {
        return this.discipline.id === discipline.id;
      }
    },
    openModalExport() {
      this.$store.dispatch("ModalExportTeamPresence/openModal");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    getSeasonDateManualExecutionEnd() {
      this.season_date_manual_execution_end =
        localStorage.getItem("season_date_manual_execution_end") === "null";
    },
    changeTab(value) {
      this.$store.dispatch("Teams/setTabTypeTeamView", value);
    },
    setColorTeamsInProgress() {
      return this.tabTypeTeamView === "teams_in_progress" ? "burnedYellow" : "ashes-hover";
    },
    setColorTeamsFinished() {
      return this.tabTypeTeamView === "teams_finished" ? "burnedYellow" : "ashes-hover";
    },
    isTeamsInProgress() {
      return this.tabTypeTeamView === "teams_in_progress";
    },
    async exportToExcel(export_type) {
      this.type_export = export_type;
      let modalOptions = {};
      switch (export_type) {
        case "teams_spreadsheet":
          modalOptions = {
            title: "Exportar Planilha de Turmas",
            closeModalDispatch: "Teams/closeExportTeamsSpreadsheetModal",
            sheetTabName: "turmas",
            fileName: "turmas.xls",
            jsonFields: this.json_fields_teams,
            openModalDispatch: "Teams/loadTeamsForExport",
          };
          break;
        case "teams_pendings_spreadsheet":
          modalOptions = {
            title: "Exportar Planilha de Pendências",
            closeModalDispatch: "Teams/closeExportTeamsPendingsSpreadsheetModal",
            sheetTabName: "turmas_pendencias",
            fileName: "turmas_pendencias.xls",
            jsonFields: this.json_fields_teams_pendings,
            openModalDispatch: "Teams/loadTeamsPendingsForExport",
          };
          break;
      }
      if (modalOptions) {
        await this.$store.dispatch("SelectFieldsToExport/setTitleModal", modalOptions.title);
        await this.$store.dispatch(
          "SelectFieldsToExport/setDispatchToCloseModal",
          modalOptions.closeModalDispatch
        );
        await this.$store.dispatch("SelectFieldsToExport/setSheetTabName", modalOptions.sheetTabName);
        await this.$store.dispatch("SelectFieldsToExport/setFileName", modalOptions.fileName);
        await this.$store.dispatch("SelectFieldsToExport/setJsonFields", modalOptions.jsonFields);
        await this.$store.dispatch(
          "SelectFieldsToExport/setDispatchOpenModal",
          modalOptions.openModalDispatch
        );
        await this.$store.dispatch("SelectFieldsToExport/openModalSelectFieldsToExport");
      }
    },
    suspendMeetings() {
      this.$store.dispatch("Meeting/openModalSuspendMeetingsByTeamAndDate");
    },
  },
  watch: {
    areas_equipment: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    code: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    equipment: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    course: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    teacher: function () {
      this.$store.dispatch("Teams/loadTeams");
    },
    offset: function () {
      this.$store.dispatch("Teams/loadTeams");
      this.backToTop();
    },
    teams_spreadsheet: {
      async handler(newValue) {
        if (this.type_export === "teams_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setDataSpreadsheet", newValue);
        }
      },
      deep: true,
    },
    teams_pendings_spreadsheet: {
      async handler(newValue) {
        if (this.type_export === "teams_pendings_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setDataSpreadsheet", newValue);
        }
      },
      deep: true,
    },
    json_data_team: {
      async handler(newValue) {
        if (this.type_export === "teams_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setJsonData", newValue);
        }
      },
      deep: true,
    },
    json_data_team_pendings: {
      async handler(newValue) {
        if (this.type_export === "teams_pendings_spreadsheet") {
          await this.$store.dispatch("SelectFieldsToExport/setJsonData", newValue);
        }
      },
      deep: true,
    },
    modalToggleExportTeamsSpreadsheet: async function () {
      if (this.type_export === "teams_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setModalToggleExport",
          this.modalToggleExportTeamsSpreadsheet
        );
      }
    },
    modalToggleExportTeamsPendingsSpreadsheet: async function () {
      if (this.type_export === "teams_pendings_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setModalToggleExport",
          this.modalToggleExportTeamsPendingsSpreadsheet
        );
      }
    },
    hasSpreadsheetTeamsResponse: async function () {
      if (this.type_export === "teams_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setVariablePreloading",
          this.hasSpreadsheetTeamsResponse
        );
      }
    },
    hasSpreadsheetTeamsPendingsResponse: async function () {
      if (this.type_export === "teams_pendings_spreadsheet") {
        await this.$store.dispatch(
          "SelectFieldsToExport/setVariablePreloading",
          this.hasSpreadsheetTeamsPendingsResponse
        );
      }
    },
  },
};
</script>
