<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="team.id"
            text="Editar turma"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar turma"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="TeamAllocate/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-rols-7 gap-5">
        <div class="grid grid-rols-2">
          <div>
            <BaseText text="informações gerais" typeText="title-forms" />
          </div>
          <div class="border border-ashes-hover w-full"></div>
        </div>
        <div class="grid grid-flow-col">
          <div>
            <BaseText :text="season.name" typeText="custom" weight="medium" mode="capitalize" size="lg" />
          </div>
          <div>
            <BaseText :text="phase.name" typeText="custom" weight="medium" mode="capitalize" size="lg" />
          </div>
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            :clear-on-select="false"
            select-placeholder="Selecione as opções"
            label-text="Módulos"
            :multiple-option="false"
            :value="setOnlyDisciplineEnableForAllocate()"
            :options="getOnlyDisciplineEnableForAllocate()"
            :error-message="getErrorMessage('discipline', teamAllocate.discipline)"
            required
            :dispatch="setDiscipline"
          />
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <BaseInput
              id="start"
              active
              input-name="start"
              inputType="date"
              :value="formatDate(teamAllocate.start)"
              required
              label-text="Início"
              show-label
              :error-message="getErrorMessage('start', teamAllocate.start)"
              dispatch="TeamAllocate/setStart"
            />
          </div>
          <div>
            <BaseInput
              id="closing"
              active
              input-name="closing"
              inputType="date"
              required
              :value="formatDate(teamAllocate.closing)"
              :error-message="getErrorMessage('closing', teamAllocate.closing)"
              label-text="Encerramento"
              show-label
              dispatch="TeamAllocate/setClosing"
            />
          </div>
        </div>
        <div>
          <AppCheckBox
            :values="[
              { name: 'semana', value: 'monday', description: 'Segunda', checked: teamAllocate.monday },
              { name: 'semana', value: 'tuesday', description: 'Terça', checked: teamAllocate.tuesday },
              { name: 'semana', value: 'wednesday', description: 'Quarta', checked: teamAllocate.wednesday },
              { name: 'semana', value: 'thursday', description: 'Quinta', checked: teamAllocate.thursday },
              { name: 'semana', value: 'friday', description: 'Sexta', checked: teamAllocate.friday },
            ]"
            required
            :error-message="getErrorMessage('weekly_meetings', teamAllocate.weekly_meetings)"
            label-text="Encontros na semana"
            show-label
            dispatch="TeamAllocate/setDayWeek"
          />
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <Multiselect
              close-on-select
              show-label
              select-placeholder="Selecione a opção"
              label-text="Turno"
              :multiple-option="false"
              :value="teamAllocate.shift"
              :options="shifts"
              :error-message="getErrorMessage('shift', teamAllocate.shift)"
              required
              :dispatch="setShift"
            />
          </div>
          <div>
            <Multiselect
              close-on-select
              show-label
              select-placeholder="Selecione a opção"
              label-text="Equipamento"
              :multiple-option="false"
              :value="teamAllocate.equipment"
              :options="equipmentAll"
              required
              :error-message="getErrorMessage('equipment', teamAllocate.equipment)"
              :dispatch="setEquipment"
            />
          </div>
        </div>
        <div class="grid grid-cols-2 gap-5">
          <div>
            <Multiselect
              close-on-select
              show-label
              select-placeholder="Selecione a opção"
              label-text="Instrutor"
              :multiple-option="false"
              :value="teamAllocate.teacher"
              :options="teachers"
              :error-message="getErrorMessage('teacher', teamAllocate.teacher)"
              required
              :dispatch="setTeacher"
            />
          </div>
          <div>
            <BaseInput
              active
              input-name="Vagas"
              required
              label-text="Vagas"
              show-label
              :value="String(teamAllocate.vacancy)"
              :error-message="getErrorMessage('vacancy', teamAllocate.vacancy)"
              input-type="number"
              dispatch="TeamAllocate/setVacancy"
              inputMin="1"
              inputMax="1000000"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="TeamAllocate/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty text="Criar" typeButton="success" dispatch="TeamAllocate/saveTeam" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import BaseText from "../components/BaseText.vue";
import AppCheckBox from "../components/AppCheckBox.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Multiselect,
    Label,
    BaseText,
    AppCheckBox,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
    },
  },

  created() {
    this.$store.dispatch("Team/loadShifts");
    this.$store.dispatch("Team/loadTeachers");
  },

  computed: {
    ...mapState({
      equipmentAll: (state) => state.Team.equipmentAll,
      shifts: (state) => state.Team.shifts,
      teachers: (state) => state.Team.teachers,
      season: (state) => state.Team.season,
      phase: (state) => state.Team.discipline.phase,
      team: (state) => state.Team,
      teamAllocate: (state) => state.TeamAllocate,
    }),

    ...mapGetters({
      getErrorMessage: "TeamAllocate/getErrorMessage",
    }),
  },

  methods: {
    ...mapActions({
      setDiscipline: "TeamAllocate/setDiscipline",
      setEquipment: "TeamAllocate/setEquipment",
      setTeacher: "TeamAllocate/setTeacher",
      setShift: "TeamAllocate/setShift",
    }),
    formatDate(date) {
      if (date) {
        return date.split("/").reverse().join("-");
      }
    },
    getOnlyDisciplineEnableForAllocate() {
      return this.team.discipline.phase.disciplines.filter((discipline) => {
        return discipline.code === "F1M2";
      });
    },
    setOnlyDisciplineEnableForAllocate() {
      const disciplines = this.team.discipline.phase.disciplines;
      const discipline = disciplines.find((discipline) => discipline.code === "F1M2");

      this.$store.dispatch("TeamAllocate/setDiscipline", discipline);
      return discipline;
    },
  },
};
</script>
